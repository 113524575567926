import React, {useEffect, useState} from 'react';
import '../CartaOrderShop.css';
import iconoGenerico from '../iconoGenerico.svg';
import { RxCross2 } from "react-icons/rx";
import { RxCheck } from "react-icons/rx";
import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";
import {
  AddAlt,
  Search,
  ShoppingCart,
  SubtractAlt,
  TrashCan
} from '@carbon/icons-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { IoIosArrowDropleftCircle , IoIosArrowDroprightCircle  } from "react-icons/io";
import {useConfig} from "../context/ConfigContext";
import { filtrarCategoriasPorProducto } from '../utils/filterUtils';
import { FiTag } from "react-icons/fi";

export default function Carta() {

  const [categories, setCategories] = useState([]);
  const [colorshop, setColorShop] = useState('#000000');
  const [nameCategorySelected, setNameCategorySelected] = useState('');
  const [productsForCategory, setProductsForCategory] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCategoriesWithProducts, setSearchCategoriesWithProducts] = useState([]);
  const [searching, setSearching] = useState(false);

  const [allProducts, setAllProducts] = useState(true);
  const allProductsText = "TODOS LOS PRODUCTOS";

  const [dataProductsPedidoModal, setDataProductsPedidoModal] = useState([]);
  const [observationsPedido, setObservationsPedido] = useState('');

  //datos de envio
  const [nameAndSurname, setNameAndSurname] = useState('');
  const [isDelivery, setIsDelivery] = useState(false);
  const [stateAddress, setStateAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [numberAddress, setNumberAddress] = useState('');
  const [floorAddress, setFloorAddress] = useState('');
  const [deptoAddress, setDeptoAddress] = useState('');
  const [observationsAddress, setObservationsAddress] = useState('');

  //client
  const [clientes, setClientes] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);

  // Estados de validaciones activas/inactivas
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [lastValidatedAddress, setLastValidatedAddress] = useState({});
  const [localidadDirection, setLocalidadDirection] = useState('');

  //Estados config
  const {
    config
  } = useConfig();

  // chequea si tiene habilitada la feature de negocio GEOLOCALIZACION
  const { features } = useConfig();
  const googleLocalizationFeature = features.find(
      (feature) => feature.name === 'GEOLOCALIZACION' && feature.status === true
  );
  const stockFeature = features.find(
      (feature) => feature.name === 'STOCK' && feature.status === true
  );
  const invoicingFeature = features.find(
      (feature) => feature.name === 'FACTURACION' && feature.status === true
  );

  const ruralSettingsFeatures = features.find(feature => feature.name === 'ZONA RURAL');
  const [shippingPrice, setShippingPrice] = useState(0.0);
  // fin chequea si tiene habilitada la feature de negocio GEOLOCALIZACION

  const [isRuralDelivery, setIsRuralDelivery] = useState(false);

  //el precio base de los delivery
  function getDeliveryPriceBase(config, isRuralDelivery) {
    // Verificar si la entrega es rural
    if (isRuralDelivery) {
      return config.priceRuralBase || 0;
    }

    // Si no es rural, revisa el tipo de entrega
    switch (config.deliveryType) {
      case 'COMMON':
        return config.deliveryCommon?.priceBase || 0;
      case 'CUSTOM':
        return shippingPrice;
      default:
        return 0;
    }
  }


  const toggleRuralDelivery = (isChecked) => {
    setIsRuralDelivery(isChecked);
    if (isChecked) {
      setShippingPrice(config.priceRuralBase);
      cleanErrorSendPedidoWithoutDeliveryReset();
    } else {
      const newPrice = getDeliveryPriceBase(config);
      setShippingPrice(newPrice);
    }
  };


  //Datos para rechargo o bonificacion
  const [isTax, setIsTax] = useState(false);
  const [typeTax, setTypeTax] = useState('');
  const [typeTaxValue, setTypeTaxValue] = useState('');
  const [valueTax, setValueTax] = useState();

  const createTax = () => {
    setIsTax(true);
  }

  const deleteTax = () => {
    setIsTax(false);
  }

  // Detecta si la dirección ha cambiado después de la validación
  const checkAddressChange = () => {
    const hasChanged =
        streetAddress !== lastValidatedAddress.streetAddress ||
        numberAddress !== lastValidatedAddress.numberAddress ||
        localidadDirection !== lastValidatedAddress.localidadDirection;

    if (hasChanged) {
      setIsAddressValid(false);
    }
  };

  const SendOrderType = {
    A_DOMICILIO: { label: "A domicilio", value: 1 },
    RETIRO_LOCAL: { label: "Retira en local", value: 2 }
  };
  const OrderType = {
    WHATSAP: { label: "WhatsApp", value: 1 },
    EN_MESA: { label: "En Mesa", value: 2 },
    DELIVERY: { label: "Delivery", value: 3 },
    A_DISTANCIA: { label: "A Distancia", value: 4 },
    MOSTRADOR: { label: "Mostrador", value: 5 }
  };

  // Función para obtener los clientes
  const fetchClients = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_CLIENTS_ROUTE, { withCredentials: true });
      setClientes(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  // Agregar estos estados
  const [priceLists, setPriceLists] = useState([]);
  const [selectedPriceList, setSelectedPriceList] = useState(null);

  // Agregar este useEffect para cargar las listas de precios
  useEffect(() => {
    const fetchPriceLists = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_PRICE_LISTS_ROUTE}/v1`,
                { 
                    withCredentials: true,
                    params: {
                        pageNumber: 0,
                        size: 100,
                        filter: ''
                    }
                }
            );
            const lists = response.data.content;
            setPriceLists(lists);
            // Seleccionar la lista default automáticamente
            const defaultList = lists.find(list => list.default);
            setSelectedPriceList(defaultList);
        } catch (error) {
            console.error('Error cargando listas de precios:', error);
            Swal.fire('Error', 'No se pudieron cargar las listas de precios', 'error');
        }
    };

    fetchPriceLists();
  }, []);

  useEffect(() => {
    document.title = 'QReate - Mostrador';
    if(!localStorage.getItem("productsPedido")){
      const listadoVacio = [];
      const listadoVacioJSON = JSON.stringify(listadoVacio);
      localStorage.setItem("productsPedido", listadoVacioJSON);
    }
    fetchClients();
    getAllCategories(null);
    validateAddress();

  }, [config]);


  const getAllCategories = (priceListId) => {
    axios.get(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE_NO_CACHED, {
      params: { priceListId: priceListId },
      withCredentials: true
    })
    .then(response => {
        setCategories(response.data);
        setNameCategorySelected(allProductsText);
      })
      .catch(error => {
        console.error(error)});
  }

  const fillProductsByIdCategorySelected = (idCategory) => {
    setProductsForCategory([]);
    document.getElementById('nameProdSearch').value = "";
    search('');
    const categoria = categories.find(categoria => categoria.id === idCategory);
    setProductsForCategory(categoria ? categoria.productDTOList : []);
  }

  const fillAllProducts = () => {
    setProductsForCategory([]);
    document.getElementById('nameProdSearch').value = "";
    search('');
  }

const search = async (searchTerm) => {
  if (searchTerm) {
    setSearching(true);
    
    if (allProducts) {
      // Búsqueda en todas las categorías
      const productosFiltrados = categories.flatMap(category => 
        (category.productDTOList || []).filter(product =>
          (product?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (product?.description || '').toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      
      // Agrupar productos por categoría
      const categoriasFiltradas = categories.map(category => ({
        ...category,
        productDTOList: (category.productDTOList || []).filter(product =>
          (product?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (product?.description || '').toLowerCase().includes(searchTerm.toLowerCase())
        )
      })).filter(category => category.productDTOList.length > 0);

      setSearchProducts(productosFiltrados);
      setSearchCategoriesWithProducts(categoriasFiltradas);
    } else {
      // Búsqueda en la categoría actual
      const productosFiltrados = (productsForCategory || []).filter(product =>
        (product?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (product?.description || '').toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchProducts(productosFiltrados);
    }
  } else {
    setSearching(false);
    setSearchProducts([]);
    setSearchCategoriesWithProducts([]);
  }
};

function formatPrice(price) {
  if (!price) return ''; // Manejar caso de valor vacío
  const parts = price.toString().split('.'); // Separar parte entera de la decimal
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
  return parts.join(','); // Unir las partes con coma como separador decimal
}

  const addProdPedido = (newProd) => {
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido")) || [];
      const notExistProduct = productsPedido.findIndex(product => product.id === newProd.id) == -1;
      if (notExistProduct) {
        let newProduct = {
          id: newProd.id,
          name: newProd.name,
          brandName: newProd.brandName,
          description: newProd.description,
          price: newProd.price,
          stock: newProd.stock,
          cant: 1,
          priceListName: newProd.priceListName
        };
        productsPedido.push(newProduct);
      } else {
          const indiceProducto = productsPedido.findIndex(product => product.id === newProd.id);
          if(productsPedido[indiceProducto].cant < newProd.stock){
            productsPedido[indiceProducto].cant += 1;
          }
      }
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
      document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value + 1;
  };

  const minusProdPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
      if(!notExistProduct){
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        if(productsPedido[indiceProducto].cant > 1){
          productsPedido[indiceProducto].cant -= 1;
        }else{
          productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
        }
        localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
        setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
        document.getElementById('cantEnPedido').value = document.getElementById('cantEnPedido').value - 1;
      }
    }
  }

  const deleteProductPedido = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      productsPedido = productsPedido.filter(producto => producto.id !== idProduct);
      localStorage.setItem("productsPedido", JSON.stringify(productsPedido));
      setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")));
    }
  }

  const contProd = (idProduct) => {
    if(localStorage.getItem("productsPedido") != ''){
      let productsPedido = JSON.parse(localStorage.getItem("productsPedido"));
      if(productsPedido != null){
        const notExistProduct = productsPedido.findIndex(product => product.id === idProduct) == -1;
        if(notExistProduct){
          return "0";
        }
        const indiceProducto = productsPedido.findIndex(product => product.id === idProduct);
        return productsPedido[indiceProducto].cant.toString();
      }
      return "0";
    }
    return "0";
  }

  const calcularTotal = (productos) => {
    let total = 0;
  
    for (let i = 0; i < productos.length; i++) {
      const producto = productos[i];
      const subtotal = producto.price * producto.cant;
      total += subtotal;
    }
  
    return total;
  }

  const cleanPedidoBorrador = () => {
    Swal.fire({
      title: '¿Estás seguro de borrar el pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      confirmButtonColor: colorshop,
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        cleanPedido();
      } else {
        const botonCarrito = document.getElementById('btnCarrito');
        botonCarrito.click();
      }
    });
  }

  const cleanPedido = () => {
    document.getElementById('cantEnPedido').value = "0";
    const listadoVacio = [];
    const listadoVacioJSON = JSON.stringify(listadoVacio);
    localStorage.setItem("productsPedido", listadoVacioJSON);
    window.location.reload();
  }

  // Función reutilizable para mostrar errores de campo
  const showFieldError = (selectorError, selectorInput) => {
    const fieldRequiredElement = document.querySelector(selectorError);
    const inputElement = document.getElementById(selectorInput);
    if (fieldRequiredElement && inputElement) {
      fieldRequiredElement.style.display = 'block';
      inputElement.style.border = '1px solid #ff1717';
    }
  };

  const sendOrderForShop = () => {
    const isNameInvalid = !nameAndSurname?.trim();

    const isDeliveryInvalid = isDelivery && !isRuralDelivery && (
        !streetAddress?.trim() ||
        !numberAddress?.trim() ||
        !localidadDirection?.trim()
    );

    if (isNameInvalid || isDeliveryInvalid) {
      if (isNameInvalid) {
        showFieldError('.errorNameAndSurname', 'nameAndSurname');
      }

      if (isDelivery) {
        if (!streetAddress?.trim()) {
          showFieldError('.errorStreetAddress', 'streetAddress');
        }
        if (!numberAddress?.trim()) {
          showFieldError('.errorNumberAddress', 'numberAddress');
        }
        if (!localidadDirection?.trim()) {
          showFieldError('.errorLocalidadDirection', 'localidadDirection');
        }
      }
      return;  // Salir si hay errores de validación
    }

    if (isDelivery && !isRuralDelivery && config.verifyAddress && !isAddressValid) {
      showAlert({
        title: 'Error de dirección',
        text: 'La dirección ha cambiado o no ha sido validada. Por favor, validá la dirección antes de continuar.',
        icon: 'error',
      });
      return;
    }

    confirmarEnvioPedido();
  };


  const confirmarEnvioPedido = () => {
    Swal.fire({
      title: '¿Enviar pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      confirmButtonColor: colorshop,
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let orderNumber = Date.now();
        sendOrder(orderNumber);

        // Cierra los modales después de enviar el pedido
        const btnCloseModalCarrito = document.getElementById('btnCloseCarrito');
        btnCloseModalCarrito?.click();

        const btnCloseModalDatosPersona = document.getElementById('btnCloseModalPersona');
        btnCloseModalDatosPersona?.click();
      }
    });
  };

  //si alguno de los delivery que tiene seteado esta activo
  function isDeliveryActive(config) {
    switch (config.deliveryType) {
      case 'COMMON':
        return config.deliveryCommon?.active || false;
      case 'CUSTOM':
        return config.deliveryCustom?.active || false;
      default:
        console.warn(`Tipo de delivery desconocido: ${config.deliveryType}`);
        return false;
    }
  }

  const sendOrder = (orderNumber) => {
    let paymentAdjustmentDTO = {}
    if(isTax){
      paymentAdjustmentDTO = {
        id: null,
        orderId: null,
        adjustmentType: typeTax === "Descuento" ? "DISCOUNT" : typeTax === "Recargo" ? "SURCHARGE" : null,
        valueType: typeTaxValue === "%" ? "PERCENTAGE" : typeTaxValue === "$" ? "FIXED_AMOUNT" : null,
        value: valueTax,
      }
    }

    let order = {
      id: null,
      orderNumber: orderNumber,
      ownerOrder: nameAndSurname,
      totalAmount: 0,
      tableId: 1, //setear numero de mesa que selecciono el usuario
      orderType: OrderType.MOSTRADOR.value,
      sendOrderType: (isDelivery && isDeliveryActive(config) ) ? SendOrderType.A_DOMICILIO.value : SendOrderType.RETIRO_LOCAL.value,
      address: streetAddress,
      numberAdress: numberAddress,
      floor: floorAddress,
      department: deptoAddress,
      provinceId: 20,
      orderDetails: [],
      orderObservation: observationsPedido,
      deliveryObservation: observationsAddress,
      paymentAdjustmentDTO: paymentAdjustmentDTO,
      shippingPrice: getDeliveryPriceBase(config),
      ruralDeliveryActive: isRuralDelivery,
      clientId : selectedClientId
    }

    let products = JSON.parse(localStorage.getItem("productsPedido"));
    products.forEach((product) => {
      order.totalAmount += (product.cant * product.price);
      order.orderDetails.push(createOrderDetail(product));
    });

    order.totalAmount = order.totalAmount.toFixed(2);
    
    axios.post(process.env.REACT_APP_API_ORDER_ROUTE, order, {
      withCredentials: true
    })
    .then(response => {
      Swal.fire({
        title: 'Pedido realizado con éxito',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then(() => {
          cleanPedido();
      });
      })
      .catch(error => {
        
        Swal.fire({
          title: 'Error con el pedido:',
          text: error.response.data.errorMsg,
          icon: 'error',
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      });
  }

  const createOrderDetail = (product) => {
    return {
      id: null,
      orderId: null,
      productId: product.id,
      brandName: product.brandName,
      quantity: product.cant,
      unitaryPrice: Number(product.price),
      subTotal: Number(product.cant * product.price),
      priceListName: product.priceListName
    };
  }

  const cleanErrorField = (inputId, errorClass) => {
    const inputElement = document.getElementById(inputId);
    const fieldRequiredElement = document.querySelector(`.${errorClass}`);

    if (inputElement?.value) {
      fieldRequiredElement.style.display = 'none';
      inputElement.style.border = '';
    }
  };

  const cleanError = () => {
    const errorElements = [
      { error: '.errorStreetAddress', input: 'streetAddress' },
      { error: '.errorNumberAddress', input: 'numberAddress' },
      { error: '.errorLocalidadDirection', input: 'localidadDirection' }
    ];

    errorElements.forEach(({ error, input }) => {
      const fieldRequiredElement = document.querySelector(error);
      const inputElement = document.getElementById(input);

      if (fieldRequiredElement) fieldRequiredElement.style.display = 'none';
      if (inputElement) inputElement.style.border = '1px solid #ced4da';
    });
  };

  const cleanErrorSendPedidoWithoutDeliveryReset = () => {
    cleanError();
    setStateAddress('');
    setStreetAddress('');
    setNumberAddress('');
    setLocalidadDirection('');
    setFloorAddress('');
    setDeptoAddress('');
    setObservationsAddress('');
  };

  const cleanErrorSendPedido = () => {
    let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
    if (fieldRequiredElement) {
        fieldRequiredElement.style.display = 'none';
    }
    
    let inputElement = document.getElementById('nameAndSurname');
    if (inputElement) {
        inputElement.style.border = '';
    }

    setNameAndSurname('');

    if (isDelivery) {
        fieldRequiredElement = document.querySelector('.errorAddressPedido');
        if (fieldRequiredElement) {
            fieldRequiredElement.style.display = 'none';
        }
        
        inputElement = document.getElementById('addressPedido');
        if (inputElement) {
            inputElement.style.border = '';
        }
    }
    setStateAddress('');
    setStreetAddress('');
    setNumberAddress('');
    setFloorAddress('');
    setDeptoAddress('');
    setObservationsAddress('')
    setIsDelivery(false);
}

  function scrollLeft() {
    document.querySelector('.categories-inner-container').scrollBy({
      left: -200,
      behavior: 'smooth'
    });
  }

  function scrollRight() {
    document.querySelector('.categories-inner-container').scrollBy({
      left: 200,
      behavior: 'smooth'
    });
  }

  // Funciones sobre taxes
  const calculateSubtotal = (typeTax, typeTaxValue, valueTax) => {
    const total = calcularTotal(dataProductsPedidoModal);
    if (typeTax === "Descuento") {
      if (typeTaxValue === "%") {
        return ((total * (1 - valueTax / 100)) - total).toFixed(2);
      } else if (typeTaxValue === "$") {
        return ((total - parseFloat(valueTax)) - total).toFixed(2);
      }
    } else if (typeTax === "Recargo") {
      if (typeTaxValue === "%") {
        return ((total * (1 + valueTax / 100)) - total).toFixed(2);
      } else if (typeTaxValue === "$") {
        return ((total + parseFloat(valueTax)) - total).toFixed(2);
      }
    }
    return total.toFixed(2);  // Valor por defecto si no coincide ninguna condición
  };

  const calculateTotalWithTax = (typeTax, typeTaxValue, valueTax) => {
    const total = calcularTotal(dataProductsPedidoModal);
    if (typeTax === "Descuento") {
      if (typeTaxValue === "%") {
        return (total * (1 - valueTax / 100)).toFixed(2);
      } else if (typeTaxValue === "$") {
        return (total - parseFloat(valueTax)).toFixed(2);
      }
    } else if (typeTax === "Recargo") {
      if (typeTaxValue === "%") {
        return (total * (1 + valueTax / 100)).toFixed(2);
      } else if (typeTaxValue === "$") {
        return (total + parseFloat(valueTax)).toFixed(2);
      }
    }
    return total.toFixed(2);  // Valor por defecto si no coincide ninguna condición
  };

  const deleteTaxAndResetInputs = () => {
    deleteTax(); // Llama a tu función para eliminar el tax.

    // Limpia los valores de los inputs
    setTypeTax("");
    setTypeTaxValue("");
    setValueTax("");
  };

  const showAlert = ({ title, text, icon, confirmButton = true }) => {
    Swal.fire({
      title,
      text,
      icon,
      showConfirmButton: confirmButton,
      confirmButtonColor: colorshop,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  };

  const validateAddress = async () => {
    const localId = 1;
    const baseUrl = process.env.REACT_APP_API_LOCALIZATION_VALIDATE_ROUTE;

    const address = `${streetAddress} ${numberAddress}`;
    const encodedAddress = encodeURIComponent(address);
    const encodedLocality = encodeURIComponent(localidadDirection);
    if (streetAddress && numberAddress && localidadDirection) {
      try {
        const response = await fetch(`${baseUrl}${localId}?address=${encodedAddress}&locality=${encodedLocality}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'X-Geolocation-Key': process.env.REACT_APP_API_KEY_GEOLOCATION,
          }
        });

        if (response.ok) {
          const data = await response.json();
          const { valid, message, shippingPrice, isOutOfRange } = data;

          if (valid === true) {
            showAlert({title: 'Dirección validada', text: `La dirección ha sido validada correctamente. 
            Precio de envío: ${shippingPrice || 'N/A'}`, icon: 'success',});
            setShippingPrice(shippingPrice);
            setIsAddressValid(true);
            setLastValidatedAddress({
              streetAddress,
              numberAddress,
              localidadDirection,
            });
          } else {
            showAlert({title: 'Error de validación:', text: message, icon: 'error',});
            setIsAddressValid(false);
            if (isOutOfRange) {
              showAlert({title: 'Error de validación:', text: message, icon: 'error',});
            }
          }
        }
      } catch (error) {
        showAlert({title: 'Error de conexión:', text: 'Hubo un problema al validar la dirección.', icon: 'error',});
        setIsAddressValid(false);
        throw error;
      }
    }
  };

  const generateColorFromText = (text = '') => {
    if (typeof text !== 'string' || text.length === 0) {
      return 'hsl(0, 0%, 80%)'; // Color gris por defecto
    }

    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    const color = `hsl(${hash % 360}, 70%, 80%)`; // Genera un color en el espectro HSL
    return color;
  };



    return (
      <div className="carta-body-mostrador">
        <div className="categories-outer-container">
       
          <button className="scroll-button left" onClick={scrollLeft}>
            <IoIosArrowDropleftCircle size={30}/>
          </button>
          <div className="categories-inner-container">
            <div className="categories-mostrador">
              <div className="category" style={{marginLeft: '2%'}}>
                <button className={`round-button ${allProducts ? 'selectedCategory' : ''}`} onClick={() => {fillAllProducts(); setAllProducts(true); setNameCategorySelected("Todos los productos");}} style={{backgroundColor: colorshop}}>
                  <img src={iconoGenerico} alt="Icono" />
                </button>
                <div className="category-name">Todos los productos</div>
              </div>
              {categories.map(category => (
                  <div className="category" key={category.id}>
                    <button className={`round-button ${category.name === nameCategorySelected ? 'selectedCategory' : ''}`} onClick={() => {fillProductsByIdCategorySelected(category.id); setNameCategorySelected(category.name); setAllProducts(false)}} style={{backgroundColor: colorshop}}>
                      <img src={iconoGenerico} alt="Icono" />
                    </button>
                    <div className="category-name">{category.name}</div>
                  </div>
              ))}
            </div>
          </div>
          <button className="scroll-button right" onClick={scrollRight}><IoIosArrowDroprightCircle size={30}/></button>
        </div>


        <div className='margin5'>
            <div className="searchProduct">
              <div className="search">
                <input type="text" id='nameProdSearch' className="searchProdCarta" placeholder="Buscar producto" style={{width : "100%"}} onChange={(e) => search(e.target.value)}/>
                <Search className="btnSearchCarta" size="35"/>
              </div>
            </div>

            <div className="products" style={{minHeight: '40vh'}}>
              {allProducts ? (
                searching ? (
                  <>
                    <h6 className='titleCategory'>{allProductsText}</h6>
                    {/* Selector de Lista de Precios */}
                    <br/>
                    {searchCategoriesWithProducts.map(category => (
                      <React.Fragment key={category.id}>
                        <h6 className='titleCategory'>{category.name}</h6>
                        {category.productDTOList.map(product => (
                          <React.Fragment key={product.id}>
                            <div className='product'>
                              {product.brandName != null && product.brandName != "" &&
                                  <div><p className='brand'>{product.brandName}</p></div>}
                              <p className='nameProd'>{product.name}</p>
                              <p className='descProd'>{product.description}</p>
                              <p className='descProd'>Lista de precio:
                                <span className="px-2 py-1 rounded-full text-xs font-bold"
                                      style={{backgroundColor: generateColorFromText(product.priceListName)}}>
                                  {product.priceListName}
                                </span>
                              </p>
                              <div className='divPriceAndBotonera'>
                                <p className='priceProd'>${formatPrice(product.price)}</p>
                                <div className='divBotonera'>
                                  <button disabled={stockFeature && product.stock <= 0}
                                          style={{borderColor: '#00000000'}} className='btn'><SubtractAlt size={25}
                                                                                                          onClick={() => minusProdPedido(product.id)}/>
                                  </button>
                                  <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                  <button disabled={stockFeature && product.stock <= 0}
                                          style={{borderColor: '#00000000'}} className='btn'><AddAlt size={25}
                                                                                                     style={{marginLeft: '5%'}}
                                                                                                     onClick={() => addProdPedido(product)}/>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {stockFeature && (
                                <div className="stockProduct">
                                  <p>Stock: {product.stock}</p>
                                </div>
                            )}
                          </React.Fragment>
                        ))}
                        <br/>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {/* Selector de Lista de Precios */}
                    <div className="row" style={{marginTop: '4%', marginBottom: '-2%'}}>
                      <div className="col-md-6">
                        <div className="price-list-selector p-3 border rounded bg-light">
                          <div className="d-flex align-items-center mb-2">
                            <FiTag className="me-2 text-primary" size={18} />
                            <label className="form-label fw-bold mb-0">Seleccionar Lista de Precios:</label>
                          </div>
                          <select
                              className="form-select"
                              value={selectedPriceList || ''}
                              onChange={(e) => {
                                const selected = priceLists.find(list => list.id === parseInt(e.target.value));
                                setSelectedPriceList(selected.id);
                                getAllCategories(selected.id);
                                //limpio el carrito
                                document.getElementById('cantEnPedido').value = "0";
                                const listadoVacio = [];
                                const listadoVacioJSON = JSON.stringify(listadoVacio);
                                localStorage.setItem("productsPedido", listadoVacioJSON);
                              }}
                          >
                            <option value="" disabled>Seleccionar lista de precio</option>
                            {priceLists.map(list => (
                                <option
                                    key={list.id}
                                    value={list.id}
                                >
                                  {list.name} {list.default ? '(Default)' : ''}
                                </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <h6 className='titleCategory'>{allProductsText}</h6>
                    {categories.map(category => (
                      <React.Fragment key={category.id}>
                        <h6 className='titleCategory'>{category.name}</h6>
                        {category.productDTOList.map(product => (
                          <React.Fragment key={product.id}>
                            <div className='product'>
                              {product.brandName != null && product.brandName != "" &&
                                  <div><p className='brand'>{product.brandName}</p></div>}
                              <p className='nameProd'>{product.name}</p>
                              <p className='descProd'>{product.description}</p>
                              <p className='descProd'>Lista de precio:
                                <span className="px-2 py-1 rounded-full text-xs font-bold"
                                      style={{backgroundColor: generateColorFromText(product.priceListName)}}>
                                  {product.priceListName}
                                </span>
                              </p>
                              <div className='divPriceAndBotonera'>
                                <p className='priceProd'>${formatPrice(product.price)}</p>
                                <div className='divBotonera'>
                                  <button disabled={stockFeature && product.stock <= 0}
                                          style={{borderColor: '#00000000'}} className='btn'><SubtractAlt size={25}
                                                                                                          onClick={() => minusProdPedido(product.id)}/>
                                  </button>
                                  <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                  <button disabled={stockFeature && product.stock <= 0}
                                          style={{borderColor: '#00000000'}} className='btn'><AddAlt size={25}
                                                                                                     style={{marginLeft: '5%'}}
                                                                                                     onClick={() => addProdPedido(product)}/>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {stockFeature && (
                                <div className="stockProduct">
                                  <p>Stock: {product.stock}</p>
                                </div>
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                )
              ) : (
                  <>
                    <h6 className='titleCategory'>{nameCategorySelected}</h6>
                    {searching ? (
                        searchProducts.map(product => (
                          <React.Fragment key={product.id}>
                            <div className='product'>
                              {product.brandName != null && product.brandName != "" &&
                                  <div><p className='brand'>{product.brandName}</p></div>}
                              <p className='nameProd'>{product.name}</p>
                              <p className='descProd'>{product.description}</p>
                              <p className='descProd'>Lista de precio:
                                <span className="px-2 py-1 rounded-full text-xs font-bold"
                                      style={{backgroundColor: generateColorFromText(product.priceListName)}}>
                                  {product.priceListName}
                                </span>
                              </p>

                              <div className='divPriceAndBotonera'>
                                <p className='priceProd'>${formatPrice(product.price)}</p>
                                <div className='divBotonera'>
                                  <button disabled={stockFeature && product.stock <= 0}
                                          style={{borderColor: '#00000000'}} className='btn'><SubtractAlt size={25}
                                                                                                          onClick={() => minusProdPedido(product.id)}/>
                                  </button>
                                  <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                  <button disabled={stockFeature && product.stock <= 0}
                                          style={{borderColor: '#00000000'}} className='btn'><AddAlt size={25}
                                                                                                     style={{marginLeft: '5%'}}
                                                                                                     onClick={() => addProdPedido(product)}/>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {stockFeature && (
                                <div className="stockProduct">
                                  <p>Stock: {product.stock}</p>
                                </div>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                          productsForCategory.map(product => (
                              <React.Fragment key={product.id}>

                                <div className='product'>
                                  {product.brandName != null && product.brandName != "" &&
                                      <div><p className='brand'>{product.brandName}</p></div>}
                                  <p className='nameProd'>{product.name}</p>
                                  <p className='descProd'>{product.description}</p>
                                  <p className='descProd'>Lista de precio:
                                    <span className="px-2 py-1 rounded-full text-xs font-bold"
                                          style={{backgroundColor: generateColorFromText(product.priceListName)}}>
                                  {product.priceListName}
                                </span>
                                  </p>

                                  <div className='divPriceAndBotonera'>
                                    <p className='priceProd'>${formatPrice(product.price)}</p>
                                    <div className='divBotonera'>
                                      <button disabled={stockFeature && product.stock <= 0}
                                              style={{borderColor: '#00000000'}} className='btn'><SubtractAlt size={25}
                                                                                                              onClick={() => minusProdPedido(product.id)}/>
                                      </button>
                                      <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                                      <button disabled={stockFeature && product.stock <= 0}
                                              style={{borderColor: '#00000000'}} className='btn'><AddAlt size={25}
                                                                                                         style={{marginLeft: '5%'}}
                                                                                                         onClick={() => addProdPedido(product)}/>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {stockFeature && (
                                    <div className="stockProduct">
                                      <p>Stock: {product.stock}</p>
                                    </div>
                                )}
                              </React.Fragment>
                          )))
                      }
                  </>
                )}
            </div>
          </div>

          {(localStorage.getItem("productsPedido") != '[]') && (
            <button id='btnCarrito' data-bs-toggle="modal" data-bs-target="#modalProductsPedido" className="round-button-pedido-mostrador"
              onClick={() => {setDataProductsPedidoModal(JSON.parse(localStorage.getItem("productsPedido")))}}>
              <ShoppingCart size={30}/>
            </button>
          )}

          <div class="modal fade modal-lg" id="modalProductsPedido" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalProductsPedidoLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header modalPedido">
                  <h1 class="modal-title fs-5" id="modalProductsPedidoLabel"
                  className="titleModalPedido" style={{marginTop: '4%', marginLeft: '4%'}}>Pedido</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btnCloseCarrito"
                          onClick={() => {setTypeTax(''); setTypeTaxValue(''); setValueTax('');}}></button>
                </div>
                <div class="modal-body modalPedido">

                  {dataProductsPedidoModal.map(product => (
                      <div className='productCarrito'>
                        {product.brandName != null && product.brandName != "" &&
                            <div><p className='brand'>{product.brandName}</p></div>}
                        <p className='nameProdPedido'>{product.name}</p>
                        <p classname=''>{product.description}</p>
                        <p className='descProd'>Lista de precio:
                          <span className="px-2 py-1 rounded-full text-xs font-bold"
                                style={{backgroundColor: generateColorFromText(product.priceListName)}}>
                                  {product.priceListName}
                                </span>
                        </p>

                        <div className='divPriceAndBotoneraPedido'>
                          <p className='priceProdPedido'>${formatPrice(product.price)}</p>
                          <div className='divBotonera'>
                            <button className='btn' style={{color: 'red'}}><TrashCan size={22}
                                                                                     onClick={() => deleteProductPedido(product.id)}/>
                            </button>
                            <button className='btn'><SubtractAlt size={22} onClick={() => minusProdPedido(product.id)}/>
                            </button>
                            <p id='cantEnPedido' style={{margin: '0'}}>{contProd(product.id)}</p>
                            <button className='btn'><AddAlt size={22} style={{marginLeft: '5%'}}
                                                            onClick={() => addProdPedido(product)}/></button>
                          </div>
                        </div>
                        <p className='priceProdPedidoSubtotal'>Subtotal:
                          ${formatPrice((product.price * product.cant).toFixed(2))}</p>
                      </div>
                  ))}
                  <br/>
                  {/* Inputs en la misma línea */}
                  <h1 className="modal-title fs-5" className="titleConfPago"
                      style={{marginTop: '1%', marginLeft: '4%'}}>Configuracion de pago:</h1>
                  <div className="d-flex align-items-center divRechargesAndBonifications">
                    <div className="inputGroup me-2 divTipo">
                    <label htmlFor="tipo" className="col-form-label label">Tipo:</label>
                      <div>
                        <div className="radioItem">
                          <input
                              type="radio"
                              id="recargo"
                              name="tipo"
                              value="Recargo"
                              checked={typeTax === "Recargo"}
                              onChange={(e) => setTypeTax(e.target.value)}
                          />
                          <label htmlFor="recargo">Recargo</label>
                        </div>

                        <div className="radioItem">
                          <input
                              type="radio"
                              id="descuento"
                              name="tipo"
                              value="Descuento"
                              checked={typeTax === "Descuento"}
                              onChange={(e) => setTypeTax(e.target.value)}
                          />
                          <label htmlFor="descuento">Descuento</label>
                        </div>
                      </div>
                    </div>

                    <div className="inputGroup me-2 divTipoValor">
                      <label htmlFor="tipoValor" className="col-form-label label">Tipo de Valor:</label>
                      <div>
                        <div className="radioItem">
                          <input
                              type="radio"
                              id="porcentaje"
                              name="tipoValor"
                              value="%"
                              checked={typeTaxValue === "%"}
                              onChange={(e) => setTypeTaxValue(e.target.value)}
                          />
                          <label htmlFor="porcentaje">Porcentaje %</label>
                        </div>

                        <div className="radioItem">
                          <input
                              type="radio"
                              id="valorFijo"
                              name="tipoValor"
                              value="$"
                              checked={typeTaxValue === "$"}
                              onChange={(e) => setTypeTaxValue(e.target.value)}
                          />
                          <label htmlFor="valorFijo">Valor Fijo $</label>
                        </div>
                      </div>
                    </div>

                    <div className="inputGroup divMonto">
                      <label htmlFor="monto" className="col-form-label label">Cantidad:</label>
                      <input
                          type="number"
                          id="monto"
                          className="form-control"
                          value={valueTax}
                          onChange={(e) => setValueTax(e.target.value)}
                      />
                    </div>

                    <div className="divCheckTax">
                      {isTax ? (
                          <Button className="btn btnCheckTaxDelete" onClick={deleteTaxAndResetInputs}>
                            <RxCross2 size={30} style={{color: "white"}}/>
                          </Button>
                      ) : (
                          <Button
                              className="btn btnCheckTaxCreate"
                              onClick={createTax}
                              disabled={!typeTax || !typeTaxValue || !valueTax} // Deshabilitar si falta algún campo
                          >
                            <RxCheck size={30} style={{color: "white"}}/>
                          </Button>
                      )}
                    </div>
                  </div>

                  {isTax ? (
                      <>
                        <br/>
                        <hr/>
                        <p className="priceProdTotalPedido">
                          Subtotal:
                          ${formatPrice(calcularTotal(dataProductsPedidoModal).toFixed(2))}
                        </p>
                        <p className="priceProdTotalPedido">
                          {typeTax} ({typeTaxValue}{valueTax}):
                          ${formatPrice(calculateSubtotal(typeTax, typeTaxValue, valueTax))}
                        </p>
                        <p className="priceProdTotalPedido" style={{fontWeight: 'bold', fontSize: '1.2em'}}>
                          Total: ${formatPrice(calculateTotalWithTax(typeTax, typeTaxValue, valueTax))}
                        </p>
                        <hr/>
                      </>
                  ) : (
                      <>
                        <br/>
                        <hr/>
                        <p className="priceProdTotalPedido" style={{fontWeight: 'bold', fontSize: '1.2em'}}>
                          Total: ${formatPrice(calcularTotal(dataProductsPedidoModal).toFixed(2))}
                        </p>
                        <hr/>
                      </>
                  )}

                  <br/>
                  <div style={{marginLeft: '5%'}}>
                    <label for="observations" class="col-form-label">Obvservaciones del pedido:</label>
                    <textarea rows="3" className="form-control observationsPedido" id="observationsPedido"
                              alue={observationsPedido} onChange={(e) => {
                      setObservationsPedido(e.target.value);
                    }}/>
                  </div>
                </div>
                <div class="modal-footer modalPedido">
                  <button id="btnCloseModalAdd" type="button" class="btn btn-dark" data-bs-dismiss="modal"
                          onClick={() => cleanPedidoBorrador()}>Borrar pedido
                  </button>
                  <button id="btnSendPedidoForWhatsapp" type="button" class="btn"
                          data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalDataForPedido"
                          style={{backgroundColor: colorshop, color: '#ffffff'}}
                          disabled={dataProductsPedidoModal.length == 0 ? true : false}>Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>

        <div class="modal fade modal-lg" id="modalDataForPedido" data-bs-backdrop="static" data-bs-keyboard="false"
             tabindex="-1" aria-labelledby="modalDataForPedidoLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header modalPedido">
                <h1 class="modal-title fs-5" id="modalDataForPedidoLabel" className="titleModalDatosPedido">Datos del
                  cliente</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                        onClick={() => cleanErrorSendPedido()}></button>
                <button id="btnCloseModalPersona" hidden={true} type="button" data-bs-dismiss="modal" aria-label="Close"
                        onClick={() => cleanErrorSendPedido()}></button>
              </div>
              <div class="modal-body modalPedido">


                <label htmlFor="nameAndSurname" className="col-form-label">Nombre y apellido:</label>
                <input type="text" className="form-control nameAndSuername" id="nameAndSurname"
                       value={nameAndSurname} onChange={(e) => {
                  setNameAndSurname(e.target.value);
                  cleanErrorField('nameAndSurname', 'errorNameAndSurname');
                }}/>
                <div className='errorNameAndSurname' id='errorNameAndSurname'>Campo obligatorio</div>

                {invoicingFeature && (
                    <>
                      <label htmlFor="clientSelect" className="col-form-label">Cliente a facturar:</label>
                      <select className="form-control" id="clientSelect" value={selectedClientId}
                              onChange={(e) => setSelectedClientId(e.target.value)}>
                        {clientes.map((client) => (
                            <option key={client.id} value={client.id}>
                              {client.fullName || client.companyName}
                            </option>
                        ))}
                      </select>
                    </>
                )}

                <label htmlFor="isDelivery" className="col-form-label">Envío a domicilio:</label>
                <br/>
                <div className="form-check form-check-inline">
                  <input
                      className="form-check-input"
                      type="radio"
                      id="isDeliveryTrue"
                      name="isDelivery"
                      value="true"
                      checked={isDelivery === true}
                      onChange={() => setIsDelivery(true)}
                  />
                  <label className="form-check-label" htmlFor="isDeliveryTrue">
                    Sí
                  </label>
                </div>
                <br/>
                <div className="form-check form-check-inline">
                  <input
                      className="form-check-input"
                      type="radio"
                      id="isDeliveryFalse"
                      name="isDelivery"
                      value="false"
                      checked={isDelivery === false}
                      onChange={() => {
                        setIsDelivery(false);
                        setStateAddress('');
                        setStreetAddress('');
                        setNumberAddress('');
                        setFloorAddress('');
                        setDeptoAddress('');
                        setObservationsAddress('');
                      }}
                  />
                  <label className="form-check-label" htmlFor="isDeliveryFalse">
                    No
                  </label>
                </div>

                {isDelivery && (
                    <>
                      <br/>
                      <p style={{color: "#959595"}}>
                        Valor del envío:{" "}
                        {googleLocalizationFeature
                            ? (shippingPrice !== 0.0 ? formatPrice(shippingPrice) : "Calculando...")
                            : formatPrice(config.deliveryCommon.priceBase)}
                      </p>

                      <label htmlFor="addressPedido" className="col-form-label">Dirección de entrega:</label>
                      <br/>

                      {/* Checkbox para Envío a dirección rural */}
                      {ruralSettingsFeatures?.status && config.ruralSettings && (
                          <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isRuralDelivery"
                                name="isRuralDelivery"
                                checked={isRuralDelivery}
                                onChange={(e) => toggleRuralDelivery(e.target.checked)}
                            />
                            <label className="form-check-label" style={{marginTop: '0.7em'}} htmlFor="isRuralDelivery">
                              Envío a dirección rural
                            </label>
                          </div>
                      )}


                      <div className="row">
                        <div className="col-8">
                          <label htmlFor="streetAddress" className="col-form-label">Calle:</label>
                          <input
                              type="text"
                              className="form-control streetAddress"
                              id="streetAddress"
                              value={streetAddress}
                              onChange={(e) => {
                                setStreetAddress(e.target.value);
                                checkAddressChange();
                                cleanErrorField('streetAddress', 'errorStreetAddress');
                              }}
                              disabled={!isDeliveryActive(config) || isRuralDelivery} // Bloquea si es rural
                          />
                          <div className="errorStreetAddress" id="errorStreetAddress">Campo obligatorio</div>
                        </div>

                        <div className="col">
                          <label htmlFor="numberAddress" className="col-form-label">Número:</label>
                          <input
                              type="number"
                              className="form-control numberAddress"
                              id="numberAddress"
                              value={numberAddress}
                              onChange={(e) => {
                                setNumberAddress(e.target.value);
                                checkAddressChange();
                                cleanErrorField('numberAddress', 'errorNumberAddress');
                              }}
                              disabled={!isDeliveryActive(config) || isRuralDelivery}
                          />
                          <div className="errorNumberAddress" id="errorNumberAddress">Campo obligatorio</div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-8">
                          <label htmlFor="localidadDirection" className="col-form-label">Localidad:</label>
                          <input
                              type="text"
                              className="form-control localidadDirection"
                              id="localidadDirection"
                              value={localidadDirection}
                              onChange={(e) => {
                                setLocalidadDirection(e.target.value);
                                checkAddressChange();
                                cleanErrorField('localidadDirection', 'errorLocalidadDirection');
                              }}
                              disabled={!isDeliveryActive(config) || isRuralDelivery}
                          />
                          <div className="errorLocalidadDirection" id="errorLocalidadDirection">Campo obligatorio</div>
                        </div>

                        {config.verifyAddress && (
                            <div className="col">
                              <label htmlFor="validarDireccion" className="col-form-label"></label>
                              <br/><br/>
                              <button
                                  type="button"
                                  className="btn"
                                  style={{backgroundColor: '#007bff', color: '#ffffff'}}
                                  onClick={() => validateAddress()}
                                  disabled={isRuralDelivery} // Bloquea si es rural
                              >
                                Validar Dirección
                              </button>
                            </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="col">
                          <label htmlFor="piso" className="col-form-label">Piso:</label>
                          <input
                              type="number"
                              className="form-control piso"
                              id="piso"
                              value={!isDeliveryActive(config) ? "" : floorAddress}
                              onChange={(e) => setFloorAddress(e.target.value)}
                              disabled={!isDeliveryActive(config) || isRuralDelivery}
                          />
                        </div>

                        <div className="col">
                          <label htmlFor="depto" className="col-form-label">Depto:</label>
                          <input
                              type="text"
                              className="form-control depto"
                              id="depto"
                              value={!isDeliveryActive(config) ? "" : deptoAddress}
                              onChange={(e) => setDeptoAddress(e.target.value)}
                              disabled={!isDeliveryActive(config) || isRuralDelivery}
                          />
                        </div>
                      </div>
                      <br/>

                      <label htmlFor="observations" className="col-form-label">Indicación adicional del destino:</label>
                      <textarea
                          placeholder="Casa con rejas negras, sin timbre"
                          rows="3"
                          className="form-control observations"
                          id="observations"
                          value={observationsAddress}
                          onChange={(e) => setObservationsAddress(e.target.value)}
                      />
                    </>
                )}
              </div>

              <div class="modal-footer modalPedido">
                <button id="btnCloseModalSendPedido" type="button" class="btn btn-secondary"
                        data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modalProductsPedido"
                        onClick={() => cleanErrorSendPedido()}>Volver
                </button>
                <button type="button" className="btn"
                        style={{backgroundColor: colorshop, color: '#ffffff'}}
                        onClick={() => sendOrderForShop()}>Finalizar pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}