import Menu from "../components/Menu/Menu";
import React, {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Table, Pagination, Form, Modal} from 'react-bootstrap';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import { FaArrowLeft } from 'react-icons/fa';
import {PiPlusMinus} from "react-icons/pi"; // Importar ícono de flecha

export default function Egress() {
    const keyLocalStorage = 'authToken';
    const navigate = useNavigate(); // Hook de navegación

    const [egress, setEgress] = useState([]);

    const [showModalAddEgress, setShowModalAddEgress] = useState(false);
    const [showModalEditEgress, setShowModalEditEgress] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    // Estados de Filtros
    const [descriptionFilter, setDescriptionFilter] = useState("");
    const [amountFilter, setAmountFilter] = useState("");
    const [dateFilter, setDateFilter] = useState("");
    const [payMethodFilter, setPayMethodFilter] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [dateAdd, setDateAdd] = useState('');
    const [payMethod, setPayMethod] = useState('');
    const [observation, setObservation] = useState('');

    const [egressIdEdit, setEgressIdEdit] = useState('');
    const [descriptionEdit, setDescriptionEdit] = useState('');
    const [amountEdit, setAmountEdit] = useState('');
    const [dateAddEdit, setDateAddEdit] = useState('');
    const [payMethodEdit, setPayMethodEdit] = useState('');
    const [observationEdit, setObservationEdit] = useState('');

    useEffect(() => {
        document.title = 'QReate - Egresos';
        getPage();
    }, [currentPage, isLoading]);

    function formatPrice(price) {
        if (!price) return ''; // Manejar caso de valor vacío
        const parts = price.toString().split('.'); // Separar parte entera de la decimal
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
        return parts.join(','); // Unir las partes con coma como separador decimal
    }

    // Armado de la página
    const getPage = () => {
        let formattedDate = dateFilter
            ? new Date(dateFilter).toLocaleString("es-ES", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
            }).replace(",", "") // Remueve la coma que podría agregarse al formatear la fecha
            : "";

        axios.get(process.env.REACT_APP_EGRESS_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                page: currentPage,
                description: descriptionFilter,
                amount: amountFilter,
                date: formattedDate, // Envío la fecha ya formateada
                payMethod: payMethodFilter
            }
        })
            .then(response => {
                setEgress(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements);
                setTotalElements(response.data.totalElements);
            })
            .catch(error => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const deleteEgress = (egress) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el egreso?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_EGRESS_ROUTE + '/' + egress.id, {
                    withCredentials: true
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getPage();
                    })
                    .catch(error => {
                        alertError();
                        console.error('Error al realizar la solicitud PUT', error);
                    });
            }
        });
    }

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const cleanFilters = () => {
        setDescriptionFilter("");
        setDateFilter("");
        setPayMethodFilter("");
        setAmountFilter("");
        setIsLoading(true);
        getPage();
    }

    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Descripcion</th>
                            <th>Monto</th>
                            <th>Fecha</th>
                            <th>Metodo de pago</th>
                            <th>Observacion</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="3" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || egress.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || egress.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={2} md={5}>

                    <Col>
                        <Form.Group controlId="descriptionFilter">
                            <Form.Control
                                className="filtersStyle"
                                type="text"
                                placeholder="Buscar por descripción"
                                value={descriptionFilter}
                                onChange={(e) => setDescriptionFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="amountFilter">
                            <Form.Control
                                className="filtersStyle"
                                type="text"
                                placeholder="Buscar por monto"
                                value={amountFilter}
                                onChange={(e) => setAmountFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="dateFilter">
                            <Form.Control
                                className="filtersStyle"
                                type="datetime-local"
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group controlId="payMethodFilter">
                            <Form.Control
                                className="filtersStyle"
                                type="text"
                                placeholder="Buscar por metodo de pago"
                                value={payMethodFilter}
                                onChange={(e) => setPayMethodFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    {/* Botones de Búsqueda y Limpiar */}
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => getPage()}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanFilters()}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    };

    const modalControlButtons = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col className="text-alig-center">
                        <Button variant="primary" className="me-1"
                                onClick={() => setShowModalAddEgress(true)}>Agregar</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Estados para el manejo de errores
    const [errors, setErrors] = useState({
        description: false,
        amount: false,
        dateAdd: false,
        payMethod: false,
        observation: false,
    });

    const [errorsEdit, setErrorsEdit] = useState({
        descriptionEdit: false,
        amountEdit: false,
        dateAddEdit: false,
        payMethodEdit: false,
        observationEdit: false,
    });

    const handleSaveEgress = () => {
        if (validateFields()) {
            saveEgress(); // Llamar a la función para guardar si no hay errores
        }
    };

    const handleEditEgress = () => {
        if (validateFieldsEdit()) {
            editEgress(); // Llamar a la función para guardar si no hay errores
        }
    };

    const validateFields = () => {
        const newErrors = {
            description: description === '',
            amount: amount === '',
            dateAdd: dateAdd === '',
        };
        setErrors(newErrors);

        // Retornar true si no hay errores
        return !Object.values(newErrors).includes(true);
    };

    const validateFieldsEdit = () => {
        const newErrorsEdit = {
            descriptionEdit: descriptionEdit === '',
            amountEdit: amountEdit === '',
            dateAddEdit: dateAddEdit === '',
        };
        setErrorsEdit(newErrorsEdit);

        // Retornar true si no hay errores
        return !Object.values(newErrorsEdit).includes(true);
    };

    const saveEgress = () => {
        const egressDTO = {
            description: description,
            amount: amount,
            date: dateAdd,
            payMethod: payMethod,
            observation: observation,
        }
        axios.post(process.env.REACT_APP_EGRESS_ROUTE, egressDTO, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Creado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setDescription('');
                setAmount('');
                setDateAdd('');
                setPayMethod('');
                setObservation('');
                setShowModalAddEgress(false);
                getPage();
            })
            .catch(error => {
                const errorMessage = error.response?.data.message || 'Ocurrió un error inesperado';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Cerrar',
                });
            });
    }

    const editEgress = () => {
        const egressDTO = {
            id: egressIdEdit,
            description: descriptionEdit,
            amount: amountEdit,
            date: dateAddEdit,
            payMethod: payMethodEdit,
            observation: observationEdit,
        }
        axios.put(process.env.REACT_APP_EGRESS_ROUTE + "/" + egressIdEdit, egressDTO, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Editado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setDescriptionEdit('');
                setAmountEdit('');
                setDateAddEdit('');
                setPayMethodEdit('');
                setObservationEdit('');
                setShowModalEditEgress(false);
                getPage();
            })
            .catch(error => {
                const errorMessage = error.response?.data.message || 'Ocurrió un error inesperado';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Cerrar',
                });
            });
    }

    const addEgressModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalAddEgress} onHide={() => setShowModalAddEgress(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Nuevo egreso
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrors({
                                    description: false,
                                    amount: false,
                                    dateAdd: false,
                                });
                                setDescription('');
                                setAmount('');
                                setDateAdd('');
                                setPayMethod('');
                                setObservation('');
                                setShowModalAddEgress(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="mb-3">
                        <label htmlFor="description" className="col-form-label">Descripcion:</label>
                        <input type="text" className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                               id="description" value={description}
                               onChange={(e) => setDescription(e.target.value)}/>
                        {errors.description && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="amount" className="col-form-label">Monto:</label>
                        <input type="number" className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                               id="amount" value={amount}
                               onChange={(e) => setAmount(e.target.value)}/>
                        {errors.amount && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="dateAdd" className="col-form-label">Fecha y Hora:</label>
                        <input type="datetime-local" className={`form-control ${errors.dateAdd ? 'is-invalid' : ''}`}
                               id="dateAdd" value={dateAdd}
                               onChange={(e) => setDateAdd(e.target.value)}/>
                        {errors.dateAdd && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="amount" className="col-form-label">Metodo de pago:</label>
                        <input type="text" className={`form-control`}
                               id="amount" value={payMethod}
                               onChange={(e) => setPayMethod(e.target.value)}/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="amount" className="col-form-label">Observacion:</label>
                        <textarea
                            className="form-control"
                            id="description"
                            value={observation}
                            onChange={(e) => {
                                const obs = e.target.value;
                                if (obs.length <= 255) {
                                    setObservation(obs);
                                }
                            }}
                            rows="3" // Ajusta la cantidad de líneas visibles
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrors({
                            description: false,
                            amount: false,
                            dateAdd: false
                        });
                        setDescription('');
                        setAmount('');
                        setDateAdd('');
                        setPayMethod('');
                        setObservation('');
                        setShowModalAddEgress(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSaveEgress}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editEgressModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalEditEgress} onHide={() => setShowModalEditEgress(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Editar egreso ID: {egressIdEdit}
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrorsEdit({
                                    descriptionEdit: false,
                                    amountEdit: false,
                                    dateAddEdit: false,
                                });
                                setDescriptionEdit('');
                                setAmountEdit('');
                                setDateAddEdit('');
                                setPayMethodEdit('');
                                setObservationEdit('');
                                setShowModalEditEgress(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="mb-3">
                        <label htmlFor="description" className="col-form-label">Descripcion:</label>
                        <input type="text" className={`form-control ${errorsEdit.descriptionEdit ? 'is-invalid' : ''}`}
                               id="description" value={descriptionEdit}
                               onChange={(e) => setDescriptionEdit(e.target.value)}/>
                        {errorsEdit.descriptionEdit && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="amount" className="col-form-label">Monto:</label>
                        <input type="number" className={`form-control ${errorsEdit.amountEdit ? 'is-invalid' : ''}`}
                               id="amount" value={amountEdit}
                               onChange={(e) => setAmountEdit(e.target.value)}/>
                        {errorsEdit.amountEdit && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="dateAdd" className="col-form-label">Fecha y Hora:</label>
                        <input type="datetime-local" className={`form-control ${errorsEdit.dateAddEdit ? 'is-invalid' : ''}`}
                               id="dateAdd" value={dateAddEdit}
                               onChange={(e) => setDateAddEdit(e.target.value)}/>
                        {errorsEdit.dateAddEdit && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="amount" className="col-form-label">Metodo de pago:</label>
                        <input type="text" className={`form-control`}
                               id="amount" value={payMethodEdit}
                               onChange={(e) => setPayMethodEdit(e.target.value)}/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="amount" className="col-form-label">Observacion:</label>
                        <textarea
                            className="form-control"
                            id="description"
                            value={observationEdit}
                            onChange={(e) => {
                                const obs = e.target.value;
                                if (obs.length <= 255) {
                                    setObservationEdit(obs);
                                }
                            }}
                            rows="3" // Ajusta la cantidad de líneas visibles
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrorsEdit({
                            descriptionEdit: false,
                            amountEdit: false,
                            dateAddEdit: false,
                        });
                        setDescriptionEdit('');
                        setAmountEdit('');
                        setDateAddEdit('');
                        setPayMethodEdit('');
                        setObservationEdit('');
                        setShowModalEditEgress(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleEditEgress}
                            data-test-cy="btnGuardarEditLocation">
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editEgressField = (egress) => {
        console.log(egress)
        setEgressIdEdit(egress.id);
        setDescriptionEdit(egress.description);
        setAmountEdit(egress.amount);
        setDateAddEdit(egress.date);
        setPayMethodEdit(egress.payMethod);
        setObservationEdit(egress.observation);

        // Convertir formato de fecha de "DD/MM/YYYY HH:mm" a "YYYY-MM-DDTHH:mm"
        if (egress.date) {
            const [datePart, timePart] = egress.date.split(" "); // Separar fecha y hora
            const [day, month, year] = datePart.split("/"); // Extraer día, mes y año
            const formattedDate = `${year}-${month}-${day}T${timePart}`; // Formato correcto
            setDateAddEdit(formattedDate);
        } else {
            setDateAddEdit(""); // Si no hay fecha, dejar vacío
        }
    }

    return (
        <div className="containersStock">

            <>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="titleStock">Egresos</h1>
                        </Col>
                    </Row>
                </Container>

                <div className="productsContainer">

                    <>
                        {filtersContainer()}

                        {(isLoading) ? (
                            loadingTable()
                        ) : (
                            <div className="m-3">
                                <Table responsive className="table-striped">
                                    <thead className="tableHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Descripcion</th>
                                        <th>Monto</th>
                                        <th>Fecha</th>
                                        <th>Metodo de pago</th>
                                        <th>Observacion</th>
                                        <th style={{width: '5%'}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        egress.map(egress => (
                                            <tr key={egress.id}>
                                                <td className="text-alig-center fw-bold">{egress.id}</td>
                                                        <td className="text-alig-center">{egress.description}</td>
                                                        <td className="text-alig-center">$ {formatPrice(egress.amount)}</td>
                                                        <td className="text-alig-center">{egress.date}</td>
                                                        <td className="text-alig-center">{egress.payMethod}</td>
                                                        <td className="text-alig-center">{egress.observation}</td>
                                                <td className="text-alig-center">
                                                    <button className="btn btnEdit" data-test-cy="btnEditLocation">
                                                        <RequestQuote
                                                            className="btnEdit" size="24"
                                                            onClick={() => {
                                                                editEgressField(egress);
                                                                setShowModalEditEgress(true);
                                                            }}/></button>
                                                    <button className="btn btnDelete" data-test-cy="btnDeleteEgress"
                                                            onClick={() => deleteEgress(egress)}><TrashCan
                                                        className="btnDelete" size="24"/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>

                            </div>
                        )}
                                {paginationModule()}
                                {modalControlButtons()}
                                {addEgressModal()}
                                {editEgressModal()}
                            </>
                    </div>
                </>
        </div>
    );
}
