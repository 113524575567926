import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

let stompClient = null;

export const connectWebSocket = (onConfigUpdate) => {
    if (stompClient && stompClient.connected) {
        console.log("WebSocket ya está conectado. No se crearán nuevas suscripciones.");
        return; // Evita duplicar la conexión y suscripciones
    }

    // Conexión para el WebSocket
    const socket = new SockJS(`${process.env.REACT_APP_API_WHATSAPP_CONFIGS_SOCKET_ROUTE}`);
    stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
        console.log("Conectado al WebSocket");

        // Suscribe a configuraciones de delivery
        stompClient.subscribe("/topic/configDeliveryCommon", (message) => {
            const deliveryConfig = JSON.parse(message.body);
            //console.log("config Delivery: ", deliveryConfig);

            onConfigUpdate(prevConfig => ({
                ...prevConfig,
                deliveryCommon: deliveryConfig
            }));
        });

        // Suscribe a configuraciones de delivery custom
        stompClient.subscribe("/topic/configDeliveryCustom", (message) => {
            const deliveryConfig = JSON.parse(message.body);

            onConfigUpdate(prevConfig => ({
                ...prevConfig,
                deliveryCustom: deliveryConfig
            }));
        });

        // Suscribe a configuraciones de whatsapp
        stompClient.subscribe("/topic/configWhatsapp", (message) => {
            const config = JSON.parse(message.body);
            //console.log("config Whatsapp: ", config);
            onConfigUpdate(config);
        });

        // Suscribe a alertas de bajo stock
        stompClient.subscribe("/topic/lowStockAlerts", (message) => {
            const alertMessage = parseMessageBody(message);

            const location = window.location.pathname; // Obtén la ruta actual directamente
            const excludedRoutes = ["/carta", "/estadoDelPedido"]; // Rutas excluidas

            if (!excludedRoutes.includes(location)) { // Muestra la alerta si la ruta no está excluida
                toast.warning(alertMessage, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    style: {
                        backgroundColor: 'rgb(78, 176, 223)',
                        color: 'white',
                        fontWeight: 'bold',
                    },
                });
            }
        });
    });
};

const parseMessageBody = (message) => {
    let alertMessage = message.body;
    if (message.isBinaryBody) {
        const decoder = new TextDecoder("utf-8");
        alertMessage = decoder.decode(message._binaryBody);
    }
    return alertMessage;
};

// Función para enviar un mensaje al backend
export const sendConfigUpdateWhatsapp = (params) => {
    if (stompClient && stompClient.connected) {
        stompClient.send(
            `/app/updateConfigWhatsapp`, // Ruta que coincida con el @MessageMapping en el backend
            {}, // Headers opcionales
            JSON.stringify(params) // Cuerpo del mensaje
        );
    } else {
        console.error('WebSocket no está conectado');
    }
};

// Función para enviar un mensaje al backend
export const sendConfigUpdateDelivery = (params) => {
    if (stompClient && stompClient.connected) {
        stompClient.send(
            `/app/updateConfigDeliveryCommon`, // Ruta que coincida con el @MessageMapping en el backend
            {}, // Headers opcionales
            JSON.stringify(params) // Cuerpo del mensaje
        );
    } else {
        console.error('WebSocket no está conectado');
    }
};


// Función para enviar un mensaje al backend
export const sendConfigUpdateDeliveryCustom = (params) => {
    if (stompClient && stompClient.connected) {
        stompClient.send(
            `/app/updateConfigDeliveryCustom`, // Ruta que coincida con el @MessageMapping en el backend
            {}, // Headers opcionales
            JSON.stringify(params) // Cuerpo del mensaje
        );
    } else {
        console.error('WebSocket no está conectado');
    }
};
