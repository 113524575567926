import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button, Modal, Form, Pagination } from 'react-bootstrap';
import { FiEdit, FiSearch } from 'react-icons/fi';
import { TrashCan, View, Search } from '@carbon/icons-react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from "react-select";

export default function PriceLists() {
    const [priceLists, setPriceLists] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalEditPriceMassive, setShowModalEditPriceMassive] = useState(false);
    const [editingList, setEditingList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [nameFilter, setNameFilter] = useState('');

    const [adjustmentType, setAdjustmentType] = useState(''); // 'aumentar' o 'disminuir'
    const [valueType, setValueType] = useState(''); // 'porcentaje' o 'valor'
    const [adjustmentValue, setAdjustmentValue] = useState(); // Valor del ajuste
    const [priceListIdFilter, setPriceListIdFilter] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        isDefault: false
    });

    const [showProductsModal, setShowProductsModal] = useState(false);
    const [selectedListProducts, setSelectedListProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [selectedListName, setSelectedListName] = useState('');

    // Agregar estados para la paginación de productos
    const [productCurrentPage, setProductCurrentPage] = useState(1);
    const [productTotalPages, setProductTotalPages] = useState(0);
    const [productTotalElements, setProductTotalElements] = useState(0);

    // Agregar nuevos estados
    const [showEditPriceModal, setShowEditPriceModal] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [newPrice, setNewPrice] = useState('');

    // Agregar nuevo estado para el filtro de productos
    const [productFilter, setProductFilter] = useState('');

    // Agregar nuevos estados
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [availableProducts, setAvailableProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [newProductPrice, setNewProductPrice] = useState('');
    const [searchProductTerm, setSearchProductTerm] = useState('');

    // Agregar nuevo estado para el filtro
    const [productNameFilter, setProductNameFilter] = useState('');

    useEffect(() => {
        document.title = 'QReate - Listas de Precios';
        setIsLoading(true);
        getPage();
    }, [currentPage, nameFilter]);

    const getPage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PRICE_LISTS_ROUTE}/v1`, {
                withCredentials: true,
                params: {
                    pageNumber: currentPage - 1,
                    size: 10,
                    filter: nameFilter || ''
                }
            });

            if (response.data && typeof response.data === 'object') {
                setPriceLists(response.data.content || []);
                setTotalPages(response.data.totalPages || 0);
                setCurrentPage((response.data.number || 0) + 1);
                setCurrentNumberOfElements(response.data.numberOfElements || 0);
                setTotalElements(response.data.totalElements || 0);
            }
        } catch (error) {
            console.error('Error completo:', error);
            Swal.fire('Error', 'No se pudieron cargar las listas de precios', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getPage();
        }
    };

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                           disabled={currentPage === totalPages || priceLists.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                           disabled={currentPage === totalPages || priceLists.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingList) {
                await axios.put(`${process.env.REACT_APP_API_PRICE_LISTS_ROUTE}/v1/${editingList.id}`, formData, {
                    withCredentials: true
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_PRICE_LISTS_ROUTE}/v1`, formData, {
                    withCredentials: true
                });
            }
            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Lista de precios guardada correctamente.',
                timer: 1500,
                showConfirmButton: false,
            });
            handleCloseModal();
            getPage();
        } catch (error) {
            console.error('Error saving price list:', error);
            Swal.fire('Error', 'No se pudo guardar la lista de precios', 'error');
        }
    };

    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: "Esta acción no se puede deshacer",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                await axios.delete(`${process.env.REACT_APP_API_PRICE_LISTS_ROUTE}/v1/${id}`, {
                    withCredentials: true
                });
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'La lista de precios ha sido eliminada',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage();
            }
        } catch (error) {
            console.error('Error deleting price list:', error);
            Swal.fire('Error', 'No se pudo eliminar la lista de precios', 'error');
        }
    };

    const handleEdit = (priceList) => {
        setEditingList(priceList);
        setFormData({
            name: priceList.name,
            isDefault: priceList.default
        });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingList(null);
        setFormData({
            name: '',
            isDefault: false
        });
    };

    const loadingTable = () => {
        return (
            <div className="text-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
                <p className="mt-2">Cargando listas de precios...</p>
            </div>
        );
    };

    const fetchPriceListItems = async (priceListId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}/list/${priceListId}`, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching price list items:', error);
            Swal.fire('Error', 'No se pudieron cargar los items de la lista de precios', 'error');
            return [];
        }
    };

    const handleApplyPercentage = async (priceListId, percentage) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}/apply-percentage/${priceListId}?percentage=${percentage}`, {}, {
                withCredentials: true
            });
            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Porcentaje aplicado correctamente.',
                timer: 1500,
                showConfirmButton: false,
            });
        } catch (error) {
            console.error('Error applying percentage:', error);
            Swal.fire('Error', 'No se pudo aplicar el porcentaje', 'error');
        }
    };

    const handleShowProducts = async (list, page = 0) => {
        setLoadingProducts(true);
        setSelectedListName(list.name);
        setEditingList(list);
        
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}/list/${list.id}/details`, {
                withCredentials: true,
                params: {
                    pageNumber: page,
                    size: 10,
                    filter: productNameFilter || ''
                }
            });
            setSelectedListProducts(response.data.content);
            setProductTotalPages(response.data.totalPages);
            setProductCurrentPage(response.data.number + 1);
            setProductTotalElements(response.data.totalElements);
            setShowProductsModal(true);
        } catch (error) {
            console.error('Error fetching products:', error);
            Swal.fire('Error', 'No se pudieron cargar los productos de la lista', 'error');
        } finally {
            setLoadingProducts(false);
        }
    };

    // Agregar función para manejar la búsqueda de productos
    const handleProductSearch = () => {
        handleShowProducts({ id: selectedListProducts[0]?.priceListId, name: selectedListName }, 0);
    };

    // Agregar función para cambiar página de productos
    const handleProductPageChange = (page) => {
        if (editingList) {
            handleShowProducts(editingList, page - 1);
        }
    };

    // Basicos
    const pageHeader = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col>
                        <h1 className="titleProducts">Listas de Precios</h1>
                    </Col>
                </Row>
            </Container>
        );
    };
    
    // Agregar nuevas funciones para manejar las acciones
    const handleEditPrice = (product) => {
        console.log('Producto a editar:', product); // Para debug
        setEditingProduct({
            id: product.id,
            priceListId: product.priceListId,
            productId: product.productId,
            productName: product.productName,
            price: product.price
        });
        setNewPrice(product.price?.toString() || '');
        setShowEditPriceModal(true);
    };

    const handleSavePrice = async () => {
        if (!editingProduct?.id) {
            console.error('ID del producto no definido:', editingProduct);
            Swal.fire('Error', 'Error al identificar el producto', 'error');
            return;
        }

        try {
            const priceListItemDTO = {
                id: editingProduct.id,
                priceListId: editingProduct.priceListId,
                productId: editingProduct.productId,
                price: parseFloat(newPrice),
                productName: editingProduct.productName
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}/${editingProduct.id}`, 
                priceListItemDTO,
                {
                    withCredentials: true
                }
            );
            
            // Si la actualización fue exitosa
            if (response.data) {
                // Mostrar mensaje de éxito
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Precio actualizado correctamente',
                    timer: 1500,
                    showConfirmButton: false,
                });
                
                // Cerrar modal de edición
                setShowEditPriceModal(false);
                
                // Refrescar el listado usando handleShowProducts
                handleShowProducts({ 
                    id: response.data.priceListId,
                    name: selectedListName 
                }, productCurrentPage - 1);
            }
        } catch (error) {
            console.error('Error updating price:', error);
            Swal.fire('Error', 'No se pudo actualizar el precio', 'error');
        }
    };

    const handleDeleteProduct = async (productId, priceListId) => {
        try {
            const result = await Swal.fire({
                title: '¿Estás seguro?',
                text: "Esta acción no se puede deshacer",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            });

            if (result.isConfirmed) {
                console.log('Deleting product:', { productId, priceListId });
                
                await axios.delete(
                    `${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}/list/${editingList.id}/product/${productId}`,
                    {
                        withCredentials: true
                    }
                );
                
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'Producto eliminado de la lista',
                    timer: 1500,
                    showConfirmButton: false,
                });
                
                handleShowProducts({ 
                    id: editingList.id,
                    name: selectedListName 
                }, productCurrentPage - 1);
            }
        } catch (error) {
            console.error('Error deleting product:', error);
            Swal.fire('Error', 'No se pudo eliminar el producto', 'error');
        }
    };

    // Modificar la función searchProducts
    const searchProducts = async (searchTerm) => {
        if (!searchTerm || searchTerm.length < 2) {
            setAvailableProducts([]);
            return;
        }
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PRODUCTS_ROUTE}`, {
                withCredentials: true,
                params: {
                    filter: searchTerm
                }
            });
            setAvailableProducts(response.data || []);
        } catch (error) {
            console.error('Error searching products:', error);
            setAvailableProducts([]);
        }
    };

    // Agregar función para manejar la selección de producto
    const handleProductSelect = (product) => {
        setSelectedProduct(product);
        setSearchProductTerm(product.name);
    };

    // Modificar la función que abre el modal
    const handleOpenAddProductModal = () => {
        // Usar el ID de la lista que estamos viendo actualmente
        const currentList = {
            id: editingList.id,
            name: selectedListName
        };
        
        console.log('Opening modal with list:', currentList);
        
        if (!currentList.id) {
            Swal.fire('Error', 'No se pudo identificar la lista de precios', 'error');
            return;
        }
        
        setEditingList(currentList);
        setShowAddProductModal(true);
    };

    // Modificar handleAddProductToList para incluir más validaciones
    const handleAddProductToList = async () => {
        console.log('Selected Product:', selectedProduct);
        console.log('New Price:', newProductPrice);
        console.log('Editing List:', editingList);

        if (!selectedProduct) {
            Swal.fire('Error', 'Debe seleccionar un producto', 'error');
            return;
        }

        if (!newProductPrice) {
            Swal.fire('Error', 'Debe especificar un precio', 'error');
            return;
        }

        if (!editingList?.id) {
            Swal.fire('Error', 'Error al identificar la lista de precios', 'error');
            return;
        }

        try {
            const priceListItemDTO = {
                id: null,
                priceListId: editingList.id,
                productId: selectedProduct.id,
                price: parseFloat(newProductPrice),
                productName: selectedProduct.name
            };

            console.log('Sending DTO:', priceListItemDTO);

            await axios.post(
                `${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}`, 
                priceListItemDTO,
                {
                    withCredentials: true
                }
            );

            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Producto agregado correctamente',
                timer: 1500,
                showConfirmButton: false,
            });

            handleCloseAddProductModal();
            handleShowProducts({ 
                id: editingList.id,
                name: selectedListName 
            }, productCurrentPage - 1);
        } catch (error) {
            console.error('Error adding product:', error);
            Swal.fire('Error', 'No se pudo agregar el producto', 'error');
        }
    };

    // Modificar handleCloseAddProductModal para limpiar también editingList
    const handleCloseAddProductModal = () => {
        setShowAddProductModal(false);
        setSearchProductTerm('');
        setSelectedProduct(null);
        setNewProductPrice('');
        setAvailableProducts([]);
        setEditingList(null); // Limpiar la lista en edición
    };

    // Agregar la función para manejar la adición de todos los productos
    const handleAddAllProducts = async () => {
        const result = await Swal.fire({
            title: '¿Agregar todos los productos?',
            text: 'Al agregar todos los productos, los mismos se agregarán con el valor de la lista default. Luego podrás cambiarle el valor a los que requieran.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, agregar todos',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE}/list/${editingList.id}/addAllProducts`,
                    {},
                    { withCredentials: true }
                );

                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Todos los productos han sido agregados',
                    timer: 1500,
                    showConfirmButton: false,
                });

                // Refrescar la lista de productos
                handleShowProducts({ 
                    id: editingList.id,
                    name: selectedListName 
                }, 0);
            } catch (error) {
                console.error('Error adding all products:', error);
                Swal.fire('Error', 'No se pudieron agregar todos los productos', 'error');
            }
        }
    };

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    const saveEditPriceListMasive = () => {
        let editPriceProductFilter = {
            priceListIdFilter: priceListIdFilter,
            adjustmentType: adjustmentType,
            valueType: valueType,
            value: adjustmentValue,
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_EDIT_PRICE_FILTER_PRICE_LIST_ROUTE, editPriceProductFilter, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Editado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setAdjustmentType('');
                setAdjustmentValue('');
                setValueType('');
                setShowModalEditPriceMassive(false);
                getPage();
            })
            .catch(error => {
                alertError();
            });
    }

    return (
        <div className="productsContainer">
            {pageHeader()}
            <div className="contentContainer">
                {isLoading ? (
                    loadingTable()
                ) : (
                    <div className="m-3">
                        <Table responsive className="tableContent">
                            <thead className="tableHead">
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Productos</th>
                                    <th>Por defecto</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {priceLists.length > 0 ? (
                                    priceLists.map((list) => (
                                        <tr key={list.id}>
                                            <td className="text-alig-center fw-bold">{list.id}</td>
                                            <td className="text-alig-center">{list.name}</td>
                                            <td className="text-alig-center">{list.productCount || 0}</td>
                                            <td className="text-alig-center">
                                                {list.default ? 
                                                    <span className="badge bg-success">Sí</span> : 
                                                    <span className="badge bg-secondary">No</span>
                                                }
                                            </td>
                                            <td>
                                                <div className="colBtnsAction">
                                                    <button 
                                                        className="btn btnEdit"
                                                        onClick={() => handleEdit(list)}
                                                    >
                                                        <FiEdit size="20"/>
                                                    </button>
                                                    <button 
                                                        className="btn"
                                                        onClick={() => handleShowProducts(list)}
                                                    >
                                                        <View size="20"/>
                                                    </button>
                                                    {!list.default && list.id !== 1 && (
                                                        <button 
                                                            className="btn btnDelete"
                                                            onClick={() => handleDelete(list.id)}
                                                        >
                                                            <TrashCan size="20"/>
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No hay listas de precios registradas
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                )}

                <Row>
                    <Col style={{display: 'flex', flexDirection: 'row-reverse', marginRight: '3%'}}>
                        <Button
                            variant="primary"
                            className="me-1"
                            onClick={() => setShowModalEditPriceMassive(true)}
                        >
                            Editar precios de productos masivo
                        </Button>
                    </Col>
                </Row>


                {paginationModule()}

                <Container>
                    <Row>
                        <Col className="text-alig-center">
                            <Button
                                variant="primary"
                                className="me-1"
                                onClick={() => setShowModal(true)}
                            >
                                Nueva Lista de Precios
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <Modal show={showModal} onHide={handleCloseModal} className="modalContent">
                    <Modal.Header closeButton className="modalHeader">
                        <Modal.Title>
                            {editingList ? 'Editar Lista de Precios' : 'Nueva Lista de Precios'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formData.name}
                                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                                    required
                                />
                            </Form.Group>

                            <div className="mb-3 d-flex align-items-center">
                                <label className="me-2">Lista por defecto (carta)</label>
                                <input
                                    type="checkbox"
                                    className="form-check-input mt-0"
                                    id="defaultCheck"
                                    checked={formData.isDefault}
                                    onChange={(e) => setFormData({...formData, isDefault: e.target.checked})}
                                    disabled={editingList?.default}
                                />
                            </div>

                            <div className="modal-footer">
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal 
                    show={showProductsModal} 
                    onHide={() => setShowProductsModal(false)}
                    size="lg"
                    className="modalContent"
                >
                    <Modal.Header closeButton className="modalHeader">
                        <Modal.Title>
                            Productos de la lista: {selectedListName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loadingProducts ? (
                            <div className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="mb-3">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Buscar producto por nombre..."
                                            value={productNameFilter}
                                            onChange={(e) => setProductNameFilter(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleShowProducts({ 
                                                        id: selectedListProducts[0]?.priceListId, 
                                                        name: selectedListName 
                                                    }, 0);
                                                }
                                            }}
                                        />
                                        <button 
                                            className="btn btn-outline-secondary" 
                                            type="button"
                                            onClick={() => handleShowProducts({ 
                                                id: selectedListProducts[0]?.priceListId, 
                                                name: selectedListName 
                                            }, 0)}
                                        >
                                            <FiSearch size={20} />
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={() => {
                                                setProductNameFilter('');
                                                handleShowProducts({ 
                                                    id: selectedListProducts[0]?.priceListId, 
                                                    name: selectedListName 
                                                }, 0);
                                            }}
                                        >
                                            Limpiar
                                        </button>
                                    </div>
                                </div>

                                <Table responsive className="tableContent">
                                    <thead className="tableHead">
                                        <tr>
                                            <th>ID</th>
                                            <th>Producto - (Marca)</th>
                                            <th>Descripción</th>
                                            <th>Precio</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedListProducts.length > 0 ? (
                                            selectedListProducts.map((product) => (
                                                <tr key={product.id}>
                                                    <td className="text-alig-center">{product.productId}</td>
                                                    <td className="text-alig-center">{product.productName} - ({product.brandName != null ? product.brandName : "Sin marca"})</td>
                                                    <td className="text-alig-center">{product.productDescription}</td>
                                                    <td className="text-alig-center">
                                                        ${product.price?.toFixed(2) || '0.00'}
                                                    </td>
                                                    <td>
                                                        <div className="colBtnsAction">
                                                            <button 
                                                                className="btn btnEdit"
                                                                onClick={() => handleEditPrice(product)}
                                                            >
                                                                <FiEdit size="20" />
                                                            </button>
                                                            {!editingList?.default && (
                                                                <button 
                                                                    className="btn btnDelete"
                                                                    onClick={() => handleDeleteProduct(product.id, product.priceListId)}
                                                                >
                                                                    <TrashCan size="20" />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No hay productos en esta lista
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                {selectedListProducts.length > 0 && (
                                    <Container fluid>
                                        <Row>
                                            <Col className="d-flex align-items-center justify-content-between">
                                                <Pagination>
                                                    <Pagination.First 
                                                        onClick={() => handleProductPageChange(1)} 
                                                        disabled={productCurrentPage === 1}
                                                    />
                                                    <Pagination.Prev 
                                                        onClick={() => handleProductPageChange(productCurrentPage - 1)} 
                                                        disabled={productCurrentPage === 1}
                                                    />
                                                    {productCurrentPage > 2 && (
                                                        <Pagination.Item onClick={() => handleProductPageChange(productCurrentPage - 2)}>
                                                            {productCurrentPage - 2}
                                                        </Pagination.Item>
                                                    )}
                                                    {productCurrentPage > 1 && (
                                                        <Pagination.Item onClick={() => handleProductPageChange(productCurrentPage - 1)}>
                                                            {productCurrentPage - 1}
                                                        </Pagination.Item>
                                                    )}
                                                    <Pagination.Item active>{productCurrentPage}</Pagination.Item>
                                                    {productCurrentPage < productTotalPages && (
                                                        <Pagination.Item onClick={() => handleProductPageChange(productCurrentPage + 1)}>
                                                            {productCurrentPage + 1}
                                                        </Pagination.Item>
                                                    )}
                                                    {productCurrentPage < productTotalPages - 1 && (
                                                        <Pagination.Item onClick={() => handleProductPageChange(productCurrentPage + 2)}>
                                                            {productCurrentPage + 2}
                                                        </Pagination.Item>
                                                    )}
                                                    <Pagination.Next 
                                                        onClick={() => handleProductPageChange(productCurrentPage + 1)}
                                                        disabled={productCurrentPage === productTotalPages}
                                                    />
                                                    <Pagination.Last 
                                                        onClick={() => handleProductPageChange(productTotalPages)}
                                                        disabled={productCurrentPage === productTotalPages}
                                                    />
                                                </Pagination>
                                                <span>Total: {productTotalElements} productos</span>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}

                                <Container className="mt-3">
                                    <Row>
                                        <Col className="text-center d-flex justify-content-center gap-2">
                                            <Button 
                                                variant="primary"
                                                onClick={handleOpenAddProductModal}
                                            >
                                                Agregar Productos
                                            </Button>
                                            <Button 
                                                variant="secondary"
                                                onClick={handleAddAllProducts}
                                            >
                                                Agregar Todos
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowProductsModal(false)}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showEditPriceModal}
                    onHide={() => setShowEditPriceModal(false)}
                    className="modalContent"
                >
                    <Modal.Header closeButton className="modalHeader">
                        <Modal.Title>Editar Precio en Lista</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Producto: {editingProduct?.name}</Form.Label>
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    value={newPrice}
                                    onChange={(e) => setNewPrice(e.target.value)}
                                    placeholder="Ingrese el nuevo precio"
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditPriceModal(false)}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={handleSavePrice}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={showAddProductModal}
                    onHide={handleCloseAddProductModal}
                    className="modalContent"
                >
                    <Modal.Header closeButton className="modalHeader">
                        <Modal.Title>Agregar Producto a la Lista</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label>Buscar Producto</Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        type="text"
                                        value={searchProductTerm}
                                        onChange={(e) => setSearchProductTerm(e.target.value)}
                                        placeholder="Escriba para buscar productos..."
                                        autoComplete="off"
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                searchProducts(searchProductTerm);
                                            }
                                        }}
                                    />
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={() => searchProducts(searchProductTerm)}
                                    >
                                        <FiSearch size={20} />
                                    </Button>
                                </div>
                                {availableProducts.length > 0 && (
                                    <div 
                                        className="position-absolute w-100 bg-white border rounded shadow-sm" 
                                        style={{ 
                                            zIndex: 1000, 
                                            maxHeight: '200px', 
                                            overflowY: 'auto',
                                            top: '100%'
                                        }}
                                    >
                                        {availableProducts.map(product => (
                                            <div
                                                key={product.id}
                                                className="p-2 cursor-pointer hover-bg-light border-bottom"
                                                onClick={() => {
                                                    handleProductSelect(product);
                                                    setSearchProductTerm(product.name);
                                                    setAvailableProducts([]); // Limpiar sugerencias después de seleccionar
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                onMouseOver={(e) => e.target.style.backgroundColor = '#f8f9fa'}
                                                onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                                            >
                                                <div className="fw-bold">{product.name}</div>
                                                <small className="text-muted">
                                                    {product.description || 'Sin descripción'} - 
                                                    Categoría: {product.categoryName || 'Sin categoría'} - 
                                                    Precio: ${product.price || '0.00'}
                                                </small>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Form.Group>

                            {selectedProduct && (
                                <Form.Group className="mb-3">
                                    <div className="mb-3">
                                        <Form.Label className="text-muted">
                                            Precio lista default: ${parseFloat(selectedProduct.price || 0).toFixed(2)}
                                        </Form.Label>
                                    </div>
                                    <Form.Label>Precio para la lista</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        value={newProductPrice}
                                        onChange={(e) => setNewProductPrice(e.target.value)}
                                        placeholder="Ingrese el precio"
                                    />
                                </Form.Group>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAddProductModal}>
                            Cancelar
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={handleAddProductToList}
                            disabled={!selectedProduct || !newProductPrice}
                        >
                            Agregar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal size="l" show={showModalEditPriceMassive} onHide={() => setShowModalEditPriceMassive(false)}>
                    <Modal.Header className="modal-header">
                        <h1 className="modal-title fs-5" id="modaleEditPriceProductsLabel"
                            className="titleModalCreateProduct">Editar precio de productos masivo</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    setAdjustmentType('');
                                    setValueType('');
                                    setAdjustmentValue('');
                                    setShowModalEditPriceMassive(false);
                                }}></button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        {/* Selección de listas de precios */}
                        <div className="mb-3">
                            {/* Selección de listas de precios */}
                            <div className="mb-3">
                                <label className="form-label" style={{marginBottom: '3%'}}><strong>Listas de precios:</strong></label>
                                <Select
                                    isMulti
                                    options={priceLists.map(list => ({
                                        value: list.id,
                                        label: list.name
                                    }))}
                                    onChange={(selectedOptions) => {
                                        setPriceListIdFilter(selectedOptions ? selectedOptions.map(option => option.value).join(',') : '');

                                        const selectAllCheckbox = document.getElementById('selectAllPriceListsFilter');
                                        if (selectAllCheckbox) {
                                            selectAllCheckbox.checked = false;
                                        }
                                    }}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Seleccione listas de precio"
                                    isDisabled={document.getElementById('selectAllPriceListsFilter')?.checked}
                                />
                            </div>
                        </div>

                        {/* Conjunto de 2 radio buttons para Aumentar o Disminuir */}
                        <div className="mb-3">
                            <label className="form-label" style={{marginBottom: '3%'}}><strong> Acción: </strong></label>
                            <div>
                                <input
                                    type="radio"
                                    id="aumentar"
                                    name="adjustmentType"
                                    value="INCREASE"
                                    checked={adjustmentType === "INCREASE"}
                                    onChange={() => setAdjustmentType("INCREASE")}
                                />
                                <label htmlFor="aumentar" className="ms-2">Aumentar</label>

                                <input
                                    type="radio"
                                    id="disminuir"
                                    name="adjustmentType"
                                    value="DECREASE"
                                    className="ms-4"
                                    checked={adjustmentType === "DECREASE"}
                                    onChange={() => setAdjustmentType("DECREASE")}
                                />
                                <label htmlFor="disminuir" className="ms-2">Disminuir</label>
                            </div>
                        </div>

                        {/* Conjunto de 2 radio buttons para Porcentaje o Valor Fijo */}
                        <div className="mb-3">
                            <label className="form-label" style={{marginBottom: '3%'}}><strong> Tipo de
                                ajuste: </strong></label>
                            <div>
                                <input
                                    type="radio"
                                    id="porcentaje"
                                    name="valueType"
                                    value="PERCENTAGE"
                                    checked={valueType === "PERCENTAGE"}
                                    onChange={() => setValueType("PERCENTAGE")}
                                />
                                <label htmlFor="porcentaje" className="ms-2">Porcentaje</label>

                                <input
                                    type="radio"
                                    id="valor"
                                    name="valueType"
                                    value="FIXED_VALUE"
                                    className="ms-4"
                                    checked={valueType === "FIXED_VALUE"}
                                    onChange={() => setValueType("FIXED_VALUE")}
                                />
                                <label htmlFor="valor" className="ms-2">Valor Fijo</label>
                            </div>
                        </div>

                        {/* Input de número para ingresar el valor */}
                        <div className="mb-3">
                            <label htmlFor="adjustmentValue" className="form-label"
                                   style={{marginBottom: '3%'}}><strong> Valor del ajuste: </strong></label>
                            <input
                                type="number"
                                id="adjustmentValue"
                                className="form-control"
                                value={adjustmentValue}
                                onChange={(e) => setAdjustmentValue(parseFloat(e.target.value))}
                                min="0"
                                step="0.01"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="button" className="btn btn-secondary"
                                onClick={() => {
                                    setAdjustmentType('');
                                    setValueType('');
                                    setAdjustmentValue('');
                                    setShowModalEditPriceMassive(false);
                                }}
                                onHide={() => setShowEditPriceModal(false)}>Cerrar
                        </button>
                        <button type="button" className="btn btn-primary"
                                disabled={!priceListIdFilter || !adjustmentType || !valueType || !adjustmentValue}
                                onClick={() => {
                                    saveEditPriceListMasive();
                                }}>Editar
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}