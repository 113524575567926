// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reporteCategoriasContainer {
    margin-left: 50px;
}

.titleReporteCategorias {
    font-family: 'Poppins', sans-serif;
    size: 4em;
    text-align: center;
    font-weight: bold;
    color: rgb(255, 157, 146);
}`, "",{"version":3,"sources":["webpack://./src/screens/ReporteEgresos.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".reporteCategoriasContainer {\n    margin-left: 50px;\n}\n\n.titleReporteCategorias {\n    font-family: 'Poppins', sans-serif;\n    size: 4em;\n    text-align: center;\n    font-weight: bold;\n    color: rgb(255, 157, 146);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
