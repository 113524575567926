import React, {useEffect, useState} from 'react'
import axios from "axios";
import {Button, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import './ReporteEgresos.css';
import {Search} from "@carbon/icons-react";

const ReporteEgresos = () => {

    const [egressReport, setEgressReport] = useState([]);
    const [totalAmountEgress, setTotalAmountEgress] = useState(null);
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [isDateFromInvalid, setIsDateFromInvalid] = useState(false);
    const [isDateToInvalid, setIsDateToInvalid] = useState(false);

    const [isExporting, setIsExporting] = useState(false);

    const getConfig = () => {
        return {
            withCredentials: true,
            params: {
                fromDate: dateFrom,
                toDate: dateTo
            }
        }
    }

    useEffect(() => {
        document.title = 'QReate - Reporte de Egresos';
    });

    const getAllEgress = () => {
        if(!dateFrom && !dateTo) {
            setIsDateFromInvalid(true);
            setIsDateToInvalid(true);
        } else {
            axios.get(process.env.REACT_APP_API_REPORTE_EGRESOS_ROUTE, getConfig())
                .then((response) => {
                    setEgressReport(response.data.reportEgressDetailsDTO);
                    setTotalAmountEgress(response.data.totalAmountEgress);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'dateFrom') {
            setDateFrom(value);
            setIsDateFromInvalid(false);
        } else if (name === 'dateTo') {
            setDateTo(value);
            setIsDateToInvalid(false);
        }
    };

    function formatPrice(price) {
        if (!price) return ''; // Manejar caso de valor vacío
        const parts = price.toString().split('.'); // Separar parte entera de la decimal
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
        return parts.join(','); // Unir las partes con coma como separador decimal
    }

    const exportData = () => {
        setIsExporting(true);

        axios.get(process.env.REACT_APP_API_REPORTE_EGRESOS_EXPORT_ROUTE, {
            ...getConfig(),
            responseType: 'blob'
        })
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Reporte_Egresos.xlsx');
                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
                // Mostrar mensaje de error al usuario
            })
            .finally(() => {
                setIsExporting(false);
            });
    };

// En el JSX
    <Button
        onClick={exportData}
        disabled={isExporting}
    >
        {isExporting ? 'Exportando...' : 'Exportar'}
    </Button>

    return (
        <div className="reporteCategoriasContainer">
            <Container className="mt-3 mb-3">
                <Row>
                    <Col>
                        <h1 className="titleReporteCategorias">Reporte de egresos</h1>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={5}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Fecha Desde</InputGroup.Text>
                            <Form.Control
                                aria-label="dateFrom"
                                type="date"
                                name="dateFrom"
                                value={dateFrom}
                                onChange={handleDateChange}
                                isInvalid={isDateFromInvalid}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={5}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Fecha Hasta</InputGroup.Text>
                            <Form.Control
                                aria-label="dateTo"
                                type="date"
                                name="dateTo"
                                value={dateTo}
                                onChange={handleDateChange}
                                isInvalid={isDateToInvalid}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={1}>
                        <Button className="me-1" variant="primary" onClick={() => getAllEgress()}>
                            <Search/>
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col>
                        <Table responsive className="table-striped">
                            <thead>
                            <tr>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Descripcion de egreso
                                </th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Monto
                                </th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Fecha
                                </th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Forma de pago
                                </th>
                                <th style={{
                                    backgroundColor: "rgb(235, 120, 107)",
                                    color: "#ffffff"
                                }}>Observacion
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {egressReport.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="text-center">Por favor ingrese un filtro</td>
                                </tr>
                            ) : (
                                egressReport.map((egress, index) => (
                                    <tr key={egress.id}>
                                        <td>{egress.description}</td>
                                        <td>$ {egress.amount}</td>
                                        <td>{egress.date}</td>
                                        <td>{egress.payMethod}</td>
                                        <td>{egress.observation}</td>
                                    </tr>
                                )))
                            }
                            </tbody>
                        </Table>
                        {totalAmountEgress != null &&
                            <label>Monto total de egresos: <strong>${totalAmountEgress}</strong></label>
                        }
                    </Col>
                </Row>
            </Container>
            {totalAmountEgress != null && <Button disabled={isExporting} style={{marginTop: '3%', marginLeft: '1%'}} onClick={exportData}>Exportar</Button> }

        </div>
    )

}

export default ReporteEgresos;