import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {useConfig} from "../context/ConfigContext";
import Swal from 'sweetalert2';
import {PiInfo} from "react-icons/pi";

function Configuraciones(props) {

    // MODULOS DE CONFIGURACIONES DE NEGOCIO
    const { features } = useConfig();
    const ordersFeature = features.find(feature => feature.name === 'MODULO BASICO');
    const googleLocalizationFeature = features.find(feature => feature.name === 'GEOLOCALIZACION');
    const ruralSettingsFeatures = features.find(feature => feature.name === 'ZONA RURAL');

    // Obteniendo datos y funciones desde el contexto Config
    const {
        config = { deliveryCommon: { priceBase: 0, rangeMaxMeters: 0 } },
        toggleOrders,
        toggleDelivery,
        toggleVerifyAddress,
        changeDeliveryPrice,
        changeDeliveryRange,
        toggleActivePrice,
        toggleActiveMessageWpp,
        toggleDeliveryCustom,
        changeDeliveryPriceCustom,
        changeDeliveryRangeCustom,
        changeDeliveryPriceMetersExcessiveCustom,
        changeDeliveryCantMetersExcessiveCustom,
        changeDeliveryMetersMaxCustom,
        toggleVerifyZonaRural,
        changeDeliveryPriceRural
    } = useConfig();

    //delivery common
    const [priceBase, setPriceBase] = useState(config?.deliveryCommon?.priceBase || 0);
    const [rangeMaxMeters, setRangeMaxMeters] = useState(config?.deliveryCommon?.rangeMaxMeters || 0);

    //delivery custom
    const [priceBaseCustom, setPriceBaseCustom] = useState(config?.deliveryCustom?.priceBase || 0);
    const [metersBaseCustom, setMetersBaseCustom] = useState(config?.deliveryCustom?.metersBase || 0);
    const [priceMetersExcessiveCustom, setPriceMetersExcessiveCustom] = useState(config?.deliveryCustom?.priceMetersExcessive || 0);
    const [cantMetersExcessiveCustom, setCantMetersExcessiveCustom] = useState(config?.deliveryCustom?.cantMetersExcessive || 0);
    const [cantMetersMaxCustom, setCantMetersMaxCustom] = useState(config?.deliveryCustom?.metersMax || 0);

    //precio rural
    const [priceRuralBase, setPriceRuralBase] = useState(config?.priceRuralBase || 0);

    //mensaje de informacion
    const [colorshop, setColorShop] = useState('#000000');
    const showAlert = ({ title, text, icon, confirmButton = true }) => {
        Swal.fire({
            title,
            text,
            icon,
            showConfirmButton: confirmButton,
            confirmButtonColor: colorshop,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    };

    const handleInfoClickGeocodingValidate = () => {
        if (!googleLocalizationFeature.status) {
            const message = 'Para habilitar esta funcionalidad, comunicate con el equipo de Ucode.';
            showAlert({ title: 'Informacion', text: message, icon: 'info' });
        } else {
            showAlert({ title: 'Información', text: 'La función de validación de ubicaciones está habilitada para ser usada.', icon: 'info' });
        }
    };

    const handleInfoClickRuralDeliveryValidate = () => {
        if (!ruralSettingsFeatures.status) {
            const message = 'Para habilitar esta funcionalidad, comunicate con el equipo de Ucode.';
            showAlert({ title: 'Informacion', text: message, icon: 'info' });
        } else {
            showAlert({ title: 'Información', text: 'La función localización de zona rural está habilitada para ser usada.', icon: 'info' });
        }
    };


    useEffect(() => {

        document.title = 'QReate';

    }, [config]);


    return (
            <div className="containerConfig">
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="titleConfig">Configuraciones</h1>
                        </Col>
                    </Row>
                </Container>


                {ordersFeature && ordersFeature.status && (
                    <Container className="mt-3 configs-container" style={{padding: '0px'}}>
                        <h3 className="configs-title">Configuraciones Pedidos Catálogo</h3>
                        <br/>
                        <Row className="mt-5 display-flex" xs={1} xl={3}>
                            <Col className="col-xl-6">
                                <h4 className="btnIsDelivery configs-label">Permitir Realizar Pedidos</h4>
                                <input
                                    type="checkbox"
                                    id="isPedido"
                                    className="switch isDelivery configs-input"
                                    checked={config.status}
                                    onChange={(e) => toggleOrders(e.target.checked)}
                                />
                            </Col>
                            <Col className="col-xl-6 configs-col">
                                <h4 className="buttonIsPriceActive configs-label">Visualizar Precios</h4>
                                <input type="checkbox" className="switch isDelivery configs-input"
                                       checked={config.priceActive}
                                       onChange={(e) => toggleActivePrice(e.target.checked)}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-5 display-flex" xs={1} xl={3}>
                            <Col className="col-xl-6 configs-col mt-3">
                                <h4 className="buttonIsPriceActive configs-label">Recibir notificacion por WhatsApp</h4>
                                <input type="checkbox" className="switch isDelivery configs-input"
                                       checked={config.messageWppActive}
                                       onChange={(e) => toggleActiveMessageWpp(e.target.checked)}
                                />
                            </Col>
                        </Row>

                        {googleLocalizationFeature && (
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsValidDelivery configs-label">Validar Ubicaciones
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleInfoClickGeocodingValidate}
                                            style={{
                                                marginLeft: '8px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <PiInfo size="24" style={{color: '#2658cb'}}/>
                                        </button>
                                    </h4>
                                    <input
                                        type="checkbox"
                                        className={`switch isDelivery configs-input ${!googleLocalizationFeature.status ? 'disabled-checkbox' : ''}`}
                                        checked={config.verifyAddress}
                                        disabled={
                                            !googleLocalizationFeature.status ||
                                            (googleLocalizationFeature.status && config.verifyAddress) // Una vez activado, no se puede desactivar
                                        }
                                        onChange={(e) => toggleVerifyAddress(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                        )}

                        {ruralSettingsFeatures && (
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsRuralSettings configs-label">Permitir Rural
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleInfoClickRuralDeliveryValidate}
                                            style={{
                                                marginLeft: '8px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <PiInfo size="24" style={{color: '#2658cb'}}/>
                                        </button>
                                    </h4>
                                    <input
                                        type="checkbox"
                                        className={`switch isDelivery configs-input ${!ruralSettingsFeatures.status ? 'disabled-checkbox' : ''}`}
                                        checked={config.ruralSettings}
                                        disabled={!ruralSettingsFeatures.status}
                                        onChange={(e) => toggleVerifyZonaRural(e.target.checked)}
                                    />
                                </Col>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsDelivery configs-label">Precio del envío Rural
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleInfoClickRuralDeliveryValidate}
                                            style={{
                                                marginLeft: '8px',
                                                background: 'transparent',
                                                border: 'none',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <PiInfo size="24" style={{color: '#2658cb'}}/>
                                        </button>
                                    </h4>
                                    <div className="price-input">
                                        <p className="dollar-sign"> $ </p>
                                        <input
                                            type="number"
                                            className="form-control priceDelivery w-25 configs-input"
                                            value={ruralSettingsFeatures.status ? priceRuralBase : ''}
                                            disabled={!ruralSettingsFeatures.status}
                                            onChange={(e) => setPriceRuralBase(e.target.value)}
                                        />
                                    </div>
                                        <button
                                            className="btn btn-primary mt-2"
                                            disabled={!ruralSettingsFeatures.status}
                                            onClick={() => changeDeliveryPriceRural(priceRuralBase)}
                                        >
                                            Guardar Precio Rural
                                        </button>
                                </Col>
                            </Row>
                        )}

                        {config.deliveryType === "COMMON" && (
                            <>
                                <h3 className="configs-title">Configuraciones Delivery</h3>
                                <br/>
                                <Row className="mt-5 display-flex" xs={1} xl={3}>
                                    <Col className="col-xl-6 configs-col">
                                        <h4 className="btnIsDelivery configs-label">Permitir Delivery</h4>
                                        <input
                                            type="checkbox"
                                            className="switch isDelivery configs-input"
                                            checked={config.deliveryCommon?.active || false}
                                            onChange={(e) => toggleDelivery(e.target.checked)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-5 display-flex" xs={1} xl={3}>
                                    <Col className="col-xl-6 configs-col">
                                        <h4 className="btnIsDelivery configs-label">Precio del envío</h4>
                                        <div className="price-input">
                                            <p className="dollar-sign"> $ </p>
                                            <input
                                                type="number"
                                                className="form-control priceDelivery w-25 configs-input"
                                                value={priceBase}
                                                onChange={(e) => setPriceBase(e.target.value)} // Solo actualiza el valor local
                                            />
                                        </div>
                                            <button
                                                className="btn btn-primary mt-2"
                                                onClick={() => changeDeliveryPrice(priceBase)} // Guarda al hacer clic
                                            >
                                                Guardar Precio
                                            </button>
                                    </Col>

                                    {googleLocalizationFeature && (
                                        <Col className="col-xl-6 configs-col">
                                        <h4 className="btnIsRange configs-label">Metros Max. Envío
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={handleInfoClickGeocodingValidate}
                                                style={{
                                                    marginLeft: '8px',
                                                    background: 'transparent',
                                                    border: 'none',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <PiInfo size="24" style={{color: '#2658cb'}}/>
                                            </button>
                                        </h4>
                                        <input
                                            type="number"
                                            className={`form-control priceDelivery w-25 ${!googleLocalizationFeature.status ? 'disabled-checkbox' : ''}`}
                                            value={googleLocalizationFeature.status ? rangeMaxMeters : ''}
                                            disabled={!googleLocalizationFeature.status}
                                            onChange={(e) => setRangeMaxMeters(e.target.value)}
                                        />

                                        <button
                                            className="btn btn-primary mt-2"
                                            disabled={!googleLocalizationFeature.status}
                                            onClick={() => changeDeliveryRange(rangeMaxMeters)}
                                        >
                                            Guardar Metros
                                        </button>
                                    </Col>
                                )}
                            </Row>
                            </>
                         )}

                        {config.deliveryType === "CUSTOM"  && (
                            <>
                            <h3 className="configs-title">Configuraciones Delivery por rangos</h3>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsDelivery configs-label">Permitir Delivery</h4>
                                    <input
                                        type="checkbox"
                                        className="switch isDelivery configs-input"
                                        checked={config.deliveryCustom?.active || false}
                                        onChange={(e) => toggleDeliveryCustom(e.target.checked)}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsDelivery configs-label">Precio Base</h4>
                                    <div className="price-input">
                                        <p className="dollar-sign"> $ </p>
                                        <input
                                            type="number"
                                            className="form-control priceDelivery w-25 configs-input"
                                            value={priceBaseCustom}
                                            onChange={(e) => setPriceBaseCustom(e.target.value)} // Solo actualiza el valor local
                                        />
                                    </div>
                                        <button
                                            className="btn btn-primary mt-2"
                                            onClick={() => changeDeliveryPriceCustom(priceBaseCustom)} // Guarda al hacer clic
                                        >
                                            Guardar Precio
                                        </button>
                                </Col>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsRange configs-label">Metros Base</h4>
                                    <input
                                        type="number"
                                        className="form-control priceDelivery w-25 configs-input"
                                        value={metersBaseCustom}
                                        onChange={(e) => setMetersBaseCustom(e.target.value)} // Solo actualiza el valor local
                                    />
                                    <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => changeDeliveryRangeCustom(metersBaseCustom)} // Guarda al hacer clic
                                    >
                                        Guardar Metros
                                    </button>
                                </Col>
                            </Row>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsDelivery configs-label">Precio Mtrs. Excedente</h4>
                                    <div className="price-input">
                                        <p className="dollar-sign"> $ </p>
                                        <input
                                            type="number"
                                            className="form-control priceDelivery w-25 configs-input"
                                            value={priceMetersExcessiveCustom}
                                            onChange={(e) => setPriceMetersExcessiveCustom(e.target.value)} // Solo actualiza el valor local
                                        />
                                    </div>
                                        <button
                                            className="btn btn-primary mt-2"
                                            onClick={() => changeDeliveryPriceMetersExcessiveCustom(priceMetersExcessiveCustom)} // Guarda al hacer clic
                                        >
                                            Guardar Precio
                                        </button>
                                </Col>
                                <Col className="col-xl-6 configs-col">
                                <h4 className="btnIsRange configs-label">Mtrs. Excedente</h4>
                                    <input
                                        type="number"
                                        className="form-control priceDelivery w-25 configs-input"
                                        value={cantMetersExcessiveCustom}
                                        onChange={(e) => setCantMetersExcessiveCustom(e.target.value)} // Solo actualiza el valor local
                                    />
                                    <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => changeDeliveryCantMetersExcessiveCustom(cantMetersExcessiveCustom)} // Guarda al hacer clic
                                    >
                                        Guardar Metros
                                    </button>
                                </Col>
                            </Row>
                            <Row className="mt-5 display-flex" xs={1} xl={3}>
                                <Col className="col-xl-6 configs-col">
                                    <h4 className="btnIsRange configs-label">Mtrs. Max</h4>
                                    <input
                                        type="number"
                                        className="form-control priceDelivery w-25 configs-input"
                                        value={cantMetersMaxCustom}
                                        onChange={(e) => setCantMetersMaxCustom(e.target.value)} // Solo actualiza el valor local
                                    />
                                    <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => changeDeliveryMetersMaxCustom(cantMetersMaxCustom)} // Guarda al hacer clic
                                    >
                                        Guardar Metros
                                    </button>
                                </Col>
                            </Row>
                            </>
                        )}

                    </Container>
                )}
            </div>
    );
}

export default Configuraciones;