import Menu from "../components/Menu/Menu";
import React, {useEffect, useState} from 'react';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import { IoLocationOutline } from "react-icons/io5";
import { PiArrowsLeftRight } from "react-icons/pi";
import { PiPlusMinus } from "react-icons/pi";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import {Container, Row, Col, Button, Table, Pagination, Modal} from 'react-bootstrap';

export default function SocialMedia() {
    const keyLocalStorage = 'authToken';
    const navigate = useNavigate(); // Hook de navegación

    const [products, setProducts] = useState([]);
    const [locations, setLocations] = useState([]);

    const [showModalAddStock, setShowModalAddStock] = useState(false);
    const [showModalMovementStock, setShowModalMovementStock] = useState(false);

    //Datos para crear un nuevo stock
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [quantity, setQuantity] = useState('');

    //Datos para crear un movimiento
    const [quantityStock, setQuantityStock] = useState();
    const [observation, setObservation] = useState();
    const [movementType, setMovementType] = useState();
    const [selectedStock, setSelectedStock] = useState();
    const [stockIdMovement, setStockIdMovement] = useState();

    const [productIdToTransfer, setProductIdToTransfer] = useState();

    // Estados para paginado de todos los stocks
    const [stocks, setStocks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    // Estados de Filtros
    const [productFilter, setProductFilter] = useState("");
    const [locationFilter, setLocationFilter] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const [movementTypeError, setMovementTypeError] = useState(false);
    const [quantityStockError, setQuantityStockError] = useState(false);
    const [observationError, setObservationError] = useState(false);
    const [selectedStockError, setSelectedStockError] = useState(false);

    useEffect(() => {
        document.title = 'QReate - Stock';
        getPage();
        getAllProducts();
        getAllStockLocations();
    }, [currentPage, isLoading]);

    const getAllProducts = () => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
           setProducts(response.data);
        })
        .catch(error => {
            console.error(error)
        });
    }

    const getAllStockLocations = () => {
        axios.get(process.env.REACT_APP_STOCK_LOCATION_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                setLocations(response.data);
            })
            .catch(error => {
                console.error(error)
            });
    }

    // Armado de la página
    const getPage = () => {
        axios.get(process.env.REACT_APP_STOCK_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                page: currentPage,
                product: productFilter,
                location: locationFilter
            }
        })
            .then(response => {
                setStocks(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
            })
            .catch(error => {
                console.log("rompio pal carajo")
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getPage()
        }
    };

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || stocks.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || stocks.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={2} md={3}>
                    {/* Filtro por Producto: Guarda el ID del producto */}
                    <Col>
                        <select
                            className="selectFilterProd"
                            value={productFilter} // El estado debe almacenar el ID del producto
                            onChange={(e) => setProductFilter(e.target.value)} // Actualiza con el ID del producto seleccionado
                        >
                            <option value=''>Seleccione producto</option>
                            {/* Opciones dinámicas de productos */}
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name} {/* Nombre visible, pero el valor es el ID */}
                                </option>
                            ))}
                        </select>
                    </Col>

                    {/* Filtro por Locación: Guarda el ID de la locación */}
                    <Col>
                        <select
                            className="selectFilterLoc"
                            value={locationFilter} // El estado debe almacenar el ID de la locación
                            onChange={(e) => setLocationFilter(e.target.value)} // Actualiza con el ID de la locación seleccionada
                        >
                            <option value=''>Seleccione locación</option>
                            {/* Opciones dinámicas de locaciones */}
                            {locations.map((location) => (
                                <option key={location.id} value={location.id}>
                                    {location.name} {/* Nombre visible, pero el valor es el ID */}
                                </option>
                            ))}
                        </select>
                    </Col>

                    {/* Botones de Búsqueda y Limpiar */}
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => getPage()}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanFilters()}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    };


    const cleanFilters = () => {
        setProductFilter("");
        setLocationFilter("");
        setIsLoading(true);
        getPage();
    }

    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Categoria - Producto</th>
                            <th>Locacion</th>
                            <th>Cantidad stock</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="7" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    const addStockModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalAddStock} onHide={() => setShowModalAddStock(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Nuevo stock
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrors({
                                    selectedProduct: false,
                                    selectedLocation: false,
                                    quantity: false,
                                });
                                setSelectedProduct('');
                                setSelectedLocation('');
                                setQuantity('');
                                setShowModalAddStock(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Selector de Producto */}
                    <div className="mb-3">
                        <label htmlFor="selectProduct" className="col-form-label">Producto:</label>
                        <select className={`form-select ${errors.selectedProduct ? 'is-invalid' : ''}`}
                                id="selectProduct" value={selectedProduct}
                                onChange={(e) => setSelectedProduct(e.target.value)}>
                            <option value="" disabled>Seleccione un producto</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>{product.name}</option>
                            ))}
                        </select>
                        {errors.selectedProduct && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    {/* Selector de Locación */}
                    <div className="mb-3">
                        <label htmlFor="selectLocation" className="col-form-label">Locación:</label>
                        <select className={`form-select ${errors.selectedLocation ? 'is-invalid' : ''}`}
                                id="selectLocation" value={selectedLocation}
                                onChange={(e) => setSelectedLocation(e.target.value)}>
                            <option value="" disabled>Seleccione una locación</option>
                            {locations.map((location) => (
                                <option key={location.id} value={location.id}>{location.name}</option>
                            ))}
                        </select>
                        {errors.selectedLocation && (
                            <div className="invalid-feedback">Campo obligatorio</div>
                        )}
                    </div>

                    {/* Campo de Cantidad de Stock */}
                    <div className="mb-3">
                        <label htmlFor="quantity" className="col-form-label">Cantidad de stock:</label>
                        <input type="number" className={`form-control ${errors.quantity ? 'is-invalid' : ''}`}
                               id="quantity" min="0" value={quantity}
                               onChange={(e) => setQuantity(e.target.value)} />
                        {errors.quantity && (
                            <div className="invalid-feedback">Debe ingresar una cantidad mayor a 0</div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrors({
                            selectedProduct: false,
                            selectedLocation: false,
                            quantity: false,
                        });
                        setSelectedProduct('');
                        setSelectedLocation('');
                        setQuantity('');
                        setShowModalAddStock(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSaveStock}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const saveMovementStock = () => {
        let hasError = false;

        // Validar tipo de movimiento
        if (!movementType) {
            setMovementTypeError(true);
            hasError = true;
        } else {
            setMovementTypeError(false);
        }

        // Validar cantidad de stock
        if (!quantityStock || isNaN(quantityStock) || parseInt(quantityStock, 10) <= 0) {
            setQuantityStockError(true);
            hasError = true;
        } else {
            setQuantityStockError(false);
        }

        // Validar cantidad de stock
        if (movementType === 'TRASFER' && !selectedStock || isNaN(selectedStock) || selectedStock == null) {
            setSelectedStockError(true);
            hasError = true;
        } else {
            setSelectedStockError(false);
        }

        // Si no hay errores, proceder a guardar el movimiento de stock
        if (!hasError) {
            let stockDTOToTransfer = {
                id: selectedStock
            }
            let stockMovement = {
                type: movementType,
                quantity: quantityStock,
                comment: observation,
                stockDTO:{
                    id: stockIdMovement
                },
                stockDTOToTransfer: movementType === 'TRANSFER' ? stockDTOToTransfer : null,
                sourceType: 'MANUAL'
            }

            axios.post(process.env.REACT_APP_STOCK_MOVEMENT_ROUTE, stockMovement, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Creado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setStockIdMovement('');
                setProductIdToTransfer('');
                setMovementTypeError(false);
                setQuantityStockError(false);
                setObservationError(false);
                setSelectedStockError(false);
                setMovementType('');
                setQuantityStock('');
                setObservation('');
                setSelectedStock('');
                setShowModalMovementStock(false);
                getPage();
            })
            .catch(error => {
                const errorMessage = error.response?.data.message || 'Ocurrió un error inesperado';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Cerrar',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    };

    const movementStockModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalMovementStock} onHide={() => setShowModalMovementStock(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Nuevo movimiento de stock
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setMovementTypeError(false);
                                setQuantityStockError(false);
                                setObservationError(false);
                                setSelectedStockError(false);
                                setMovementType('');
                                setQuantityStock('');
                                setObservation('');
                                setStockIdMovement('');
                                setProductIdToTransfer('');
                                setShowModalMovementStock(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Tipo de movimiento */}
                    <div className="mb-3">
                        <label htmlFor="movementType" className="col-form-label">Tipo de movimiento:</label>
                        <div id="movementType">
                            <div className="form-check">
                                <input className="form-check-inline" type="radio" name="movementType" id="income"
                                       value="IN" checked={movementType === 'IN'}
                                       onChange={(e) => {
                                           setMovementType(e.target.value);
                                           setSelectedStock('');
                                           setSelectedStockError(false);
                                       }}/>
                                <label className="form-check-label" htmlFor="income">
                                    Ingreso
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-inline" type="radio" name="movementType" id="expense"
                                       value="OUT" checked={movementType === 'OUT'}
                                       onChange={(e) => {
                                           setMovementType(e.target.value);
                                           setSelectedStock('');
                                           setSelectedStockError(false);
                                       }}/>
                                <label className="form-check-label" htmlFor="expense">
                                    Egreso
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-inline" type="radio" name="movementType" id="transfer"
                                       value="TRANSFER" checked={movementType === 'TRANSFER'}
                                       onChange={(e) => setMovementType(e.target.value)}/>
                                <label className="form-check-label" htmlFor="transfer">
                                    Transferencia
                                </label>
                            </div>
                        </div>
                        {movementTypeError && <div className="text-danger">Campo obligatorio</div>}
                    </div>

                    {/* Seleccionable de stock */}
                    {movementType === 'TRANSFER' && (
                        <div className="mb-3">
                            <label htmlFor="stockSelect" className="col-form-label">Seleccionar Stock:</label>
                            <select
                                id="stockSelect"
                                className="form-select"
                                value={selectedStock}  // Asume que tienes un estado llamado selectedStock
                                onChange={(e) => setSelectedStock(e.target.value)}
                            >
                                <option value="">Seleccione un stock</option>
                                {stocks
                                    .filter(stock =>
                                        stock.product.id === productIdToTransfer && stock.id !== stockIdMovement // Filtra según productId y excluye stockIdToTransfer
                                    )
                                    .map((filteredStock) => (
                                        <option key={filteredStock.id} value={filteredStock.id}>
                                            {`${filteredStock.product.categoryName} - ${filteredStock.product.name} → Ubicación: ${filteredStock.location.name}`}
                                        </option>
                                    ))}
                            </select>
                            {selectedStockError && <div className="text-danger">Debe seleccionar un stock</div>}
                        </div>
                    )}

                    {/* Cantidad de stock */}
                    <div className="mb-3">
                        <label htmlFor="quantityStock" className="col-form-label">Cantidad de stock:</label>
                        <input type="number" className="form-control" id="quantityStock" min="0"
                               value={quantityStock} onChange={(e) => setQuantityStock(e.target.value)} />
                        {quantityStockError && <div className="text-danger">Campo obligatorio. Debe ser un número positivo.</div>}
                    </div>

                    {/* Observación */}
                    <div className="mb-3">
                        <label htmlFor="description" className="col-form-label">Observación de movimiento de stock:</label>
                        <input type="text" className="form-control" id="description"
                               value={observation} onChange={(e) => setObservation(e.target.value)} />
                        {observationError && <div className="text-danger">Campo obligatorio</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setMovementTypeError(false);
                        setQuantityStockError(false);
                        setObservationError(false);
                        setSelectedStockError(false);
                        setMovementType('');
                        setQuantityStock('');
                        setObservation('');
                        setStockIdMovement('');
                        setProductIdToTransfer('');
                        setShowModalMovementStock(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={saveMovementStock}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        );
    };

    const modalControlButtons = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col className="text-alig-center">
                        <Button variant="primary" className="me-1"
                                onClick={() => setShowModalAddStock(true)}>Agregar</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Estados para el manejo de errores
    const [errors, setErrors] = useState({
        selectedProduct: false,
        selectedLocation: false,
        quantity: false,
    });

    // Función para validar los campos del formulario
    const validateFields = () => {
        const newErrors = {
            selectedProduct: selectedProduct === '',
            selectedLocation: selectedLocation === '',
            quantity: quantity === '' || quantity <= 0,
        };
        setErrors(newErrors);

        // Retornar true si no hay errores
        return !Object.values(newErrors).includes(true);
    };

    // Función que se llama al hacer clic en "Guardar"
    const handleSaveStock = () => {
        if (validateFields()) {
            saveStock(); // Llamar a la función para guardar si no hay errores
        }
    };

    const saveStock = () => {
        const stockDTO = {
            quantity: quantity,
            product: {
                id: selectedProduct
            },
            location: {
                id: selectedLocation
            }
        }
        axios.post(process.env.REACT_APP_STOCK_ROUTE, stockDTO, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
        .then(response => {
            Swal.fire({
                icon: 'success',
                title: '¡Éxito!',
                text: 'Creado correctamente.',
                timer: 1500,
                showConfirmButton: false,
            });
            setSelectedProduct('');
            setSelectedLocation('');
            setQuantity('');
            setShowModalAddStock(false);
            getPage();
        })
        .catch(error => {
            const errorMessage = error.response?.data.message || 'Ocurrió un error inesperado';
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
                confirmButtonText: 'Cerrar',
            });
        });
    }

    return (
        <div className="containersStock">
            <>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="titleStock">Stock de productos</h1>
                        </Col>
                    </Row>
                </Container>

                <div className="linksContainer">
                    <Link to="/stockLocations">
                        <Button>
                            <IoLocationOutline size={23}/> Locaciones de stock
                        </Button>
                    </Link>

                    <Link to="/stockMovements">
                        <Button>
                            <PiArrowsLeftRight size={23}/> Movimientos de stock
                        </Button>
                    </Link>
                </div>

                <div className="productsContainer">
                    <>
                        {filtersContainer()}

                        {(isLoading) ? (
                            loadingTable()
                        ) : (
                            <div className="m-3">
                                <Table responsive className="table-striped">
                                    <thead className="tableHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Categoria - Producto</th>
                                        <th>Locacion</th>
                                        <th>Cantidad stock</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        stocks.length > 0 ? (
                                            stocks.map(stock => (
                                                <tr key={stock.id}>
                                                    <td className="text-alig-center fw-bold">{stock.id}</td>
                                                    <td className="text-alig-center">{stock.product.categoryName + " - " + stock.product.name}</td>
                                                    <td className="text-alig-center">{stock.location.name}</td>
                                                    <td className="text-alig-center">{stock.quantity}</td>
                                                    <td width="5%">
                                                        <div className="colBtnsActionStock">
                                                            <button className="btn" title="Generar movimiento de stock"><PiPlusMinus size="24"
                                                                 onClick={() => {
                                                                     setProductIdToTransfer(stock.product.id);
                                                                     setStockIdMovement(stock.id);
                                                                     setShowModalMovementStock(true);
                                                                 }}/>
                                                            </button>
                                                            <button className="btn" title="Ver movimientos asociados"><PiArrowsLeftRight size="24"
                                                                 onClick={() => {
                                                                     navigate(`/stockMovements`, { state: { idStock: stock.id } })
                                                                 }}/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-alig-center">Aun no hay stock cargado</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>

                            </div>
                        )}
                        {paginationModule()}
                        {modalControlButtons()}
                        {addStockModal()}
                        {movementStockModal()}

                    </>
                </div>
            </>
        </div>
    );
}
