import React, {useEffect, useState} from 'react';
import {PiInfo, PiNotepad} from "react-icons/pi";
import Loading from "../components/Loading";
import axios from 'axios';
import Swal from 'sweetalert2';
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {TbStatusChange} from "react-icons/tb";
import {FiEdit} from "react-icons/fi";
import {AddAlt, SubtractAlt} from "@carbon/icons-react";
import {RxCheck, RxCross2} from "react-icons/rx";
import {useConfig} from "../context/ConfigContext";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import InvoiceModal from "../screens/InvoiceModal";
import { FaRegPaperPlane } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const Order = ({order, getAllOrders, actualStatus, selectedOrdersToEdit, setSelectedOrdersToEdit}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [colorshop, setColorShop] = useState('#000000');

    const [orderDetails, setOrdersDetails] = useState([]);
    const [orderLogsStatus, setOrderLogsStatus] = useState([]);
    const [orderAddress, setOrdersAddress] = useState(null);

    const [reasonCancellation, setReasonCancellation] = useState("");
    const [showModalDetails, setShowModalDetails] = useState(false);
    const [showModalAddress, setShowModalAddress] = useState(false);
    const [showModalCancell, setShowModalCancell] = useState(false);
    const [showModalSetStatus, setShowModalSetStatus] = useState(false);
    const [showModalLogsStatus, setShowModalLogsStatus] = useState(false);
    const [showModalEditOrder, setShowModalEditOrder] = useState(false);
    const [showModalAddProduct, setShowModalAddProduct] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState('');
    const [observationChangeStatus, setObservationChangeStatus] = useState('');
    const [restockProducts, setRestockProducts] = React.useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedPriceList, setSelectedPriceList] = useState('');
    const [quantityAddProduct, setQuantityAddProduct] = useState(1);
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    const [priceListItemForProduct, setPriceListItemForProduct] = useState([]);

    const [isDelivery, setIsDelivery] = useState(order.delivery);
    const [nameAndSurname, setNameAndSurname] = useState(order.ownerOrder);
    const [streetAddress, setStreetAddress] = useState('');
    const [numberAddress, setNumberAddress] = useState('');
    const [floorAddress, setFloorAddress] = useState('');
    const [deptoAddress, setDeptoAddress] = useState('');
    const [observationsAddress, setObservationsAddress] = useState('');
    const [observationsPedido, setObservationsPedido] = useState(order.orderObservation);
    const [localidadDirection, setLocalidadDirection] = useState('');

    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [clients, setClients] = useState([]);
    const [pointsOfSale, setPointsOfSale] = useState([]);

    // MODULOS DE CONFIGURACIONES DE NEGOCIO
    //const { features } = useConfig();
    //const invoiceActive = features.find(feature => feature.name === 'MODULO BASICO');

    const getTotalAmount = () => {
        return order.hasAmountAdjustments ? order.totalAmountWithAdjustments : order.totalAmount;
    }

    //Datos para rechargo o bonificacion
    const [isTax, setIsTax] = useState(order.hasAmountAdjustments);
    const [typeTax, setTypeTax] = useState('');
    const [typeTaxValue, setTypeTaxValue] = useState('');
    const [valueTax, setValueTax] = useState(0);

    const [idPaymentAdjustment, setIdPaymentAdjustment] = useState();

    const [reload, setReload] = useState(false);
    const [canDelete, setCanDelete] = useState(true);
    const [contador, setContador] = useState(1)

    const createTaxAndUpdateTotal = () => {
        setIsTax(true);
        createPaymentAdjustments();
    };

    // Estados de validaciones activas/inactivas
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [lastValidatedAddress, setLastValidatedAddress] = useState({});

    //Estados config
    const {
        config
    } = useConfig();

    // chequea si tiene habilitada la feature de negocio
    const {features} = useConfig();
    const stockFeature = features.find(feature => feature.name === 'STOCK');
    const googleLocalizationFeature = features.find(
        (feature) => feature.name === 'GEOLOCALIZACION' && feature.status === true
    );
    const invoicingFeature = features.find(
        (feature) => feature.name === 'FACTURACION' && feature.status === true
    );
    const ruralSettingsFeatures = features.find(feature => feature.name === 'ZONA RURAL');
    const [shippingPrice, setShippingPrice] = useState(0.0);
    // fin chequea si tiene habilitada la features

    const [isRuralDelivery, setIsRuralDelivery] = useState(false);

    //el precio base de los delivery
    function getDeliveryPriceBase(config, isRuralDelivery) {
        if (isRuralDelivery) {
            return config.priceRuralBase || 0;
        } else {
            return shippingPrice;
        }
    }

    const navigate = useNavigate();
    const generateInvoice = async (orderId) => {
        if (!orderId) {
            Swal.fire("Error", "Orden no válida para generar factura.", "error");
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_GET_AUTHORIZER_ROUTE}${orderId}`, { withCredentials: true });
            const invoiceData = response.data;
            // Redirige con los datos de la factura{
            navigate('/invoice', { state: { invoiceData: invoiceData } });
        } catch (error) {
            console.error("Error al obtener la factura:", error);
            Swal.fire("Error", "No se pudo obtener la factura. Intenta nuevamente.", "error");
        }
    };


    const showAlert = ({title, text, icon, confirmButton = true}) => {
        Swal.fire({
            title,
            text,
            icon,
            showConfirmButton: confirmButton,
            confirmButtonColor: colorshop,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    };

    const toggleRuralDelivery = (isChecked) => {
        setIsRuralDelivery(isChecked);
        if (isChecked) {
            setShippingPrice(config.priceRuralBase);
            cleanErrorSendPedidoWithoutDeliveryReset();
        } else {
            setShippingPrice(order.amountDelivery);
        }
    };

    const cleanErrorSendPedidoWithoutDeliveryReset = () => {
        cleanError();
    };


    // Detecta si la dirección ha cambiado después de la validación
    const checkAddressChange = () => {
        const hasChanged =
            streetAddress !== lastValidatedAddress.streetAddress ||
            numberAddress !== lastValidatedAddress.numberAddress ||
            localidadDirection !== lastValidatedAddress.localidadDirection;

        if (hasChanged) {
            setIsAddressValid(false);
        }
    };

    // Funciones sobre taxes
    const calculateSubtotal = (typeTax, typeTaxValue, valueTax) => {
        const total = order.totalAmount;
        if (typeTax === "DISCOUNT") {
            if (typeTaxValue === "PERCENTAGE") {
                return ((total * (1 - valueTax / 100)) - total).toFixed(2);
            } else if (typeTaxValue === "FIXED_AMOUNT") {
                return ((total - parseFloat(valueTax)) - total).toFixed(2);
            }
        } else if (typeTax === "SURCHARGE") {
            if (typeTaxValue === "PERCENTAGE") {
                return ((total * (1 + valueTax / 100)) - total).toFixed(2);
            } else if (typeTaxValue === "FIXED_AMOUNT") {
                return ((total + parseFloat(valueTax)) - total).toFixed(2);
            }
        }
        return total.toFixed(2);  // Valor por defecto si no coincide ninguna condición
    };

    const calculateTotalWithTax = (typeTax, typeTaxValue, valueTax) => {
        let valueTaxPercentage = valueTax / 100;
        let orderAmount = order.totalAmount;
        if (typeTax === "DISCOUNT") {
            if (typeTaxValue === "PERCENTAGE") {
                return ((orderAmount - orderAmount * valueTaxPercentage) + shippingPrice).toFixed(2);
            } else if (typeTaxValue === "FIXED_AMOUNT") {
                return (orderAmount - parseFloat(valueTax) + shippingPrice).toFixed(2);
            }
        } else if (typeTax === "SURCHARGE") {
            if (typeTaxValue === "PERCENTAGE") {
                return (orderAmount + orderAmount * valueTaxPercentage + shippingPrice).toFixed(2);
            } else if (typeTaxValue === "FIXED_AMOUNT") {
                return (orderAmount + parseFloat(valueTax) + shippingPrice).toFixed(2);
            }
        }
        return orderAmount.toFixed(2);  // Valor por defecto si no coincide ninguna condición
    };

    // Función para eliminar el tax y resetear los inputs
    const deleteTaxAndResetInputs = () => {
        // Resetear los inputs
        setTypeTax('');
        setTypeTaxValue('');
        setValueTax('');
        setIsTax(false);
        if (idPaymentAdjustment != null) {
            deletePaymentAdjustments();
        }
    };

    const SendOrderType = {
        A_DOMICILIO: {label: "A domicilio", value: 1},
        RETIRO_LOCAL: {label: "Retira en local", value: 2}
    };

    const OrderType = {
        WHATSAP: {label: "WhatsApp", value: 1},
        EN_MESA: {label: "En Mesa", value: 2},
        DELIVERY: {label: "Delivery", value: 3},
        A_DISTANCIA: {label: "A Distancia", value: 4},
        MOSTRADOR: {label: "Mostrador", value: 5}
    };

    const AdjustmentType = {
        DISCOUNT: {label: "Descuento"},
        SURCHARGE: {label: "Recargo"},
    };

    const getAdjustmentType = (adjustmentType) => {
        switch (adjustmentType) {
            case "DISCOUNT":
                return AdjustmentType.DISCOUNT.label;
            case "SURCHARGE":
                return AdjustmentType.SURCHARGE.label;
        }
    }

    const OrderStatusOption = ({id, value, label, checked, onChange}) => (
        <div key={id} className="orderStatus mb-3">
            <Form.Check
                inline
                label={label}
                name="flexRadioDefault"
                type="radio"
                id={`orderStatus-${id}`}
                value={value}
                checked={checked}
                onChange={onChange}
            />
        </div>
    );

    const getOrderTypeValueByLabel = (label) => {
        for (const key in OrderType) {
            if (OrderType[key].label === label) {
                return OrderType[key].value;
            }
        }
        return null;
    };

    const handleCloseModal = () => {
        setShowModalDetails(false);
    };

    const getAllCategories = () => {
        axios.get(process.env.REACT_APP_API_CATEGORIES_PRODUCTS_ROUTE_NO_CACHED, {
            withCredentials: true
        })
            .then(response => {
                setCategories(response.data);
                setAllProducts(response.data.flatMap(category => category.productDTOList));
            })
            .catch(error => {
                console.error(error)
            });
    }

    useEffect(() => {
        findOrdersDetails()
        validateAddress();
    }, [contador]);

    const validateAddress = async () => {
        const localId = 1;
        const baseUrl = process.env.REACT_APP_API_LOCALIZATION_VALIDATE_ROUTE;

        const address = `${streetAddress} ${numberAddress}`;
        const encodedAddress = encodeURIComponent(address);
        const encodedLocality = encodeURIComponent(localidadDirection);
        if (streetAddress && numberAddress && localidadDirection) {
            try {
                const response = await fetch(`${baseUrl}${localId}?address=${encodedAddress}&locality=${encodedLocality}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'X-Geolocation-Key': process.env.REACT_APP_API_KEY_GEOLOCATION,
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    const {valid, message, shippingPrice, isOutOfRange} = data;

                    if (valid === true) {
                        showAlert({
                            title: 'Dirección validada',
                            text: `La dirección ha sido validada correctamente. Precio de envío: ${formatPrice(shippingPrice) || 'N/A'}`,
                            icon: 'success',
                        });
                        setShippingPrice(shippingPrice);
                        setIsAddressValid(true);
                        setLastValidatedAddress({
                            streetAddress,
                            numberAddress,
                            localidadDirection,
                        });
                    } else {
                        showAlert({title: 'Error de validación:', text: message, icon: 'error',});
                        setIsAddressValid(false);
                        if (isOutOfRange) {
                            showAlert({title: 'Error de validación:', text: message, icon: 'error',});
                        }
                    }
                }
            } catch (error) {
                showAlert({
                    title: 'Error de conexión:',
                    text: 'Hubo un problema al validar la dirección.',
                    icon: 'error',
                });
                setIsAddressValid(false);
                throw error;
            }
        }
    };
    const cleanErrorField = (inputId, errorClass) => {
        const inputElement = document.getElementById(inputId);
        const fieldRequiredElement = document.querySelector(`.${errorClass}`);

        if (inputElement?.value) {
            fieldRequiredElement.style.display = 'none';
            inputElement.style.border = '';
        }
    };

    const cleanError = () => {
        const errorElements = [
            {error: '.errorStreetAddress', input: 'streetAddress'},
            {error: '.errorNumberAddress', input: 'numberAddress'},
            {error: '.errorLocalidadDirection', input: 'localidadDirection'}
        ];

        errorElements.forEach(({error, input}) => {
            const fieldRequiredElement = document.querySelector(error);
            const inputElement = document.getElementById(input);

            if (fieldRequiredElement) fieldRequiredElement.style.display = 'none';
            if (inputElement) inputElement.style.border = '1px solid #ced4da';
        });
    };

    const findOrdersDetails = () => {
        axios.get(process.env.REACT_APP_API_ORDER_DETAILS_VIEW_ROUTE + order.orderId, {
            withCredentials: true
        })
            .then(response => {
                setOrdersDetails(response.data.listOrderDetailsDTO);
                setShippingPrice(response.data.deliveryAmount);
                let paymentAdjustment = response.data.paymentAdjustmentDTO;
                if (paymentAdjustment != null) {
                    setIdPaymentAdjustment(paymentAdjustment.id);
                    setIsTax(true);
                    setTypeTax(paymentAdjustment.adjustmentType);
                    setTypeTaxValue(paymentAdjustment.valueType);
                    setValueTax(paymentAdjustment.value);
                }
            })
            .catch(() => {
                alertError();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const findOrdersLogsStatus = () => {
        axios.get(process.env.REACT_APP_API_ORDER_LOGS_ROUTE + order.orderId, {
            withCredentials: true
        })
            .then(response => {
                setOrderLogsStatus(response.data);
            })
            .catch((r) => {
                alertError();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const findAddressOrder = () => {
        axios.get(process.env.REACT_APP_API_ORDER_WHATSAPP_ADDRESS_ROUTE + order.orderId, {
            withCredentials: true
        })
            .then(response => {
                setOrdersAddress(response.data);
                if (response.data != null) {
                    setStreetAddress(response.data.adress);
                    setDeptoAddress(response.data.departament);
                    setNumberAddress(response.data.numberAdress);
                    setFloorAddress(response.data.flor);
                    setObservationsAddress(response.data.deliveryObservation);
                }
            })
            .catch(() => {
                alertError();
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const cancelOrder = () => {
        Swal.fire({
            title: '¿Estás seguro de cancelar el pedido?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(
                    `${process.env.REACT_APP_API_ORDER_ROUTE}/${order.orderId}` + "/status",
                    null,  // No se envía ningún cuerpo de solicitud
                    {
                        withCredentials: true,
                        params: {
                            status: 'CANCELLED',
                            observation: reasonCancellation,
                            restockProducts: restockProducts,
                        },
                    }
                )
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Se canceló correctamente!',
                            timer: 1200,
                            showConfirmButton: false,
                        });
                        setObservationChangeStatus("");
                        setSelectedStatus('');
                        setShowModalCancell(false);
                        getAllOrders(actualStatus);
                    })
                    .catch(error => {
                        alertError();
                        console.error(error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        });
    }

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    function formatPrice(price) {
        if (price == null || isNaN(price)) return '';
        return new Intl.NumberFormat('es-AR', {style: 'currency', currency: 'ARS'}).format(price);
    }


    const getStatus = (status) => {
        switch (status) {
            case "RECEIVED" :
                return "Recibido";
            case "IN_PREPARATION" :
                return "En preparacion";
            case "DELIVERY_IN_PROGRESS" :
                return "En camino";
            case "FINALIZED" :
                return "Finalizado";
            case "CANCELLED" :
                return "Cancelado";
        }
    }

    const renderStatusOptions = () => {
        const statusOptions = [];

        if (order.status === 'RECEIVED') {
            statusOptions.push(
                {id: 'IN_PREPARATION', value: 'IN_PREPARATION', label: 'En preparación'},
                {id: 'DELIVERY_IN_PROGRESS', value: 'DELIVERY_IN_PROGRESS', label: 'En camino'},
                {id: 'FINALIZED', value: 'FINALIZED', label: 'Finalizado'},
                {id: 'CANCELLED', value: 'CANCELLED', label: 'Cancelado'}
            );
        } else if (order.status === 'IN_PREPARATION') {
            statusOptions.push(
                {id: 'DELIVERY_IN_PROGRESS', value: 'DELIVERY_IN_PROGRESS', label: 'En camino'},
                {id: 'FINALIZED', value: 'FINALIZED', label: 'Finalizado'},
                {id: 'CANCELLED', value: 'CANCELLED', label: 'Cancelado'}
            );
        } else {
            statusOptions.push(
                {id: 'FINALIZED', value: 'FINALIZED', label: 'Finalizado'},
                {id: 'CANCELLED', value: 'CANCELLED', label: 'Cancelado'}
            );
        }

        return statusOptions.map((option) => (
            <React.Fragment key={option.id}>
                <OrderStatusOption
                    id={option.id}
                    value={option.value}
                    label={option.label}
                    checked={selectedStatus === option.value}
                    onChange={(event) => setSelectedStatus(event.target.value)}
                />
            </React.Fragment>
        ));
    };

    const setStatusOrder = () => {
        if (selectedStatus === 'CANCELLED') {
            Swal.fire({
                title: '¿Estás seguro de cancelar el pedido?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                confirmButtonColor: 'rgb(246, 139, 127)',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.put(
                        `${process.env.REACT_APP_API_ORDER_ROUTE}/${order.orderId}` + "/status",
                        null,  // No se envía ningún cuerpo de solicitud
                        {
                            withCredentials: true,
                            params: {
                                status: selectedStatus,
                                observation: observationChangeStatus,
                                restockProducts: restockProducts,
                            },
                        }
                    )
                        .then(response => {
                            Swal.fire({
                                icon: 'success',
                                title: 'Listo',
                                timer: 800,
                                showConfirmButton: false,
                            });
                            setShowModalSetStatus(false);
                            getAllOrders(actualStatus);
                        })
                        .catch(error => {
                            alertError();
                            console.error(error);
                        })
                        .finally(() => {
                            setIsLoading(false);
                            setSelectedStatus('');
                            setObservationChangeStatus('');
                        });
                }
            });
        } else {
            axios.put(
                `${process.env.REACT_APP_API_ORDER_ROUTE}/${order.orderId}` + "/status",
                null,  // No se envía ningún cuerpo de solicitud
                {
                    withCredentials: true,
                    params: {
                        status: selectedStatus,
                        observation: observationChangeStatus,
                        restockProducts: restockProducts,
                    },
                }
            )
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Listo',
                        timer: 800,
                        showConfirmButton: false,
                    });
                    setShowModalSetStatus(false);
                    getAllOrders(actualStatus);
                })
                .catch(error => {
                    alertError();
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                    setSelectedStatus('');
                    setObservationChangeStatus('');
                });
        }

    };

    const contCantProdDetail = (detail) => {
        if (detail.quantity === 0) {
            setOrdersDetails(prevDetails => prevDetails.filter(d => d.id !== detail.id));
        }
        document.getElementById('cantEnPedido' + detail.id).innerText = detail.quantity;
        document.getElementById('subtotal' + detail.id).innerText = formatPrice(detail.unitaryPrice * detail.quantity);
        document.getElementById('total').innerText = formatPrice(orderDetails.reduce((acc, detail) => acc + (detail.quantity * detail.unitaryPrice), 0) + (isDelivery ? 500 : 0));
    }

    // Función reutilizable para mostrar errores de campo
    const showFieldError = (selectorError, selectorInput) => {
        const fieldRequiredElement = document.querySelector(selectorError);
        const inputElement = document.getElementById(selectorInput);
        if (fieldRequiredElement && inputElement) {
            fieldRequiredElement.style.display = 'block';
            inputElement.style.border = '1px solid #ff1717';
        }
    };

    const validateAndSendOrder = () => {
        const isNameInvalid = !nameAndSurname?.trim();
        const isDeliveryInvalid = isDelivery && !isRuralDelivery && (
            !streetAddress?.trim() ||
            !numberAddress?.trim() ||
            !localidadDirection?.trim()
        );

        // Validación de nombre y dirección
        if (isNameInvalid || isDeliveryInvalid) {
            if (isNameInvalid) {
                showFieldError('.errorNameAndSurname', 'nameAndSurname');
            }

            if (isDelivery) {
                if (!streetAddress?.trim()) {
                    showFieldError('.errorStreetAddress', 'streetAddress');
                }
                if (!numberAddress?.trim()) {
                    showFieldError('.errorNumberAddress', 'numberAddress');
                }
                if (!localidadDirection?.trim()) {
                    showFieldError('.errorLocalidadDirection', 'localidadDirection');
                }
            }
            return;  // Detener si hay errores de validación
        }

        // Validación de dirección en caso de envío
        if (isDelivery && !isRuralDelivery && config.verifyAddress && !isAddressValid) {
            showAlert({
                title: 'Error de dirección',
                text: 'La dirección ha cambiado o no ha sido validada. Por favor, validá la dirección antes de continuar.',
                icon: 'error',
            });
            return;
        }

        // Si todo está validado, enviar la orden
        updateOrder();
    };

    const updateOrder = () => {
        console.log("detalles del pedido a editar")
        console.log(orderDetails)
        let orderEdit = {
            id: order.orderId,
            orderNumber: order.orderNumber,
            ownerOrder: nameAndSurname,
            totalAmount: 0,
            tableId: 1, //setear numero de mesa que selecciono el usuario
            orderType: getOrderTypeValueByLabel(order.orderType),
            sendOrderType: (isDelivery) ? SendOrderType.A_DOMICILIO.value : SendOrderType.RETIRO_LOCAL.value,
            address: streetAddress,
            numberAdress: numberAddress,
            floor: floorAddress,
            department: deptoAddress,
            provinceId: 20, //ver como mando el id de la provincia
            orderDetails: orderDetails,
            orderObservation: observationsPedido,
            deliveryObservation: observationsAddress,
            shippingPrice: getDeliveryPriceBase(config),
        }
        axios.put(process.env.REACT_APP_API_ORDER_ROUTE, orderEdit, {
            withCredentials: true,
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Pedido editado!',
                    timer: 1000,
                    showConfirmButton: false,
                });
                setShowModalEditOrder(false);
                getAllOrders(actualStatus);
            })
            .catch(error => {
                alertError();
            })
            .finally(() => {
                setSelectedStatus('');
                setObservationChangeStatus('');
            });
    }

    const addProduct = () => {
        let category = categories.find(cat => cat.id === parseInt(selectedCategory));
        let products = category.productDTOList;
        let product = products.find(prod => prod.id === parseInt(selectedProduct));
        let priceList = selectedPriceList;

        if (stockFeature && stockFeature.status) {
            if (quantityAddProduct <= product.stock) {
                let existingDetail = orderDetails.find(detail => detail.productId === product.id);

                if (existingDetail != null) {
                    existingDetail.quantity += quantityAddProduct;
                    existingDetail.subTotal = (parseFloat(existingDetail.unitaryPrice) * existingDetail.quantity);
                } else {
                    let newDetail = {
                        "id": Math.random(),
                        "orderId": order.id,
                        "productId": product.id,
                        "productName": product.name,
                        "brandName": product.brandName,
                        "quantity": quantityAddProduct,
                        "subTotal": (parseFloat(priceList.price) * quantityAddProduct),
                        "unitaryPrice": parseFloat(priceList.price),
                        "priceListName": priceList.priceListName
                    };
                    orderDetails.push(newDetail);
                }

                setShowModalAddProduct(false);
                setSelectedCategory('');
                setSelectedProduct('');
                setSelectedPriceList('');
                setQuantityAddProduct(1);
                setShowModalEditOrder(true);
            } else {
                let fieldRequiredElement = document.getElementById('errorQuantityStock');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('quantityInput');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let existingDetail = orderDetails.find(detail => detail.productId === product.id);

            if (existingDetail != null) {
                existingDetail.quantity += quantityAddProduct;
                existingDetail.subTotal = (parseFloat(existingDetail.unitaryPrice) * existingDetail.quantity);
            } else {
                let newDetail = {
                    "id": Math.random(),
                    "orderId": order.id,
                    "productId": product.id,
                    "productName": product.name,
                    "brandName": product.brandName,
                    "quantity": quantityAddProduct,
                    "subTotal": (parseFloat(priceList.price) * quantityAddProduct),
                    "unitaryPrice": parseFloat(priceList.price),
                    "priceListName": priceList.priceListName
                };
                orderDetails.push(newDetail);
            }

            setShowModalAddProduct(false);
            setSelectedCategory('');
            setSelectedProduct('');
            setQuantityAddProduct(1);
            setShowModalEditOrder(true);
        }
    };

    const cleanErrorNameAndSurname = () => {
        if (nameAndSurname != '' && nameAndSurname != null) {
            let fieldRequiredElement = document.querySelector('.errorNameAndSurname');
            fieldRequiredElement.style.display = 'none';
            let inputElement = document.getElementById('nameAndSurname');
            inputElement.style.border = '';
        }
    }

    const receiptPrint = async (orderId) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_RECEIPT_DOWNLOAD_TICKET_ROUTE, {
                withCredentials: true,
                params: {
                    localId: 1,
                    orderId: orderId
                },
                responseType: 'blob' // Para manejar archivos binarios como PDFs
            })

            // Crear un link para descargar el PDF
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ticket.pdf'); // Nombre del archivo descargado
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading ticket:', error);
        }
    };

    const createPaymentAdjustments = () => {
        setCanDelete(false);
        let paymentAdjustment = {
            orderId: order.orderId,
            adjustmentType: typeTax,
            valueType: typeTaxValue,
            value: valueTax,
            eventName: "CREATE"
        }
        axios.put(process.env.REACT_APP_API_ORDER_PAYMENT_ADJUSTMENT_ROUTE, paymentAdjustment, {
            withCredentials: true,
        })
            .then(response => {
                setReload(true);
                setIdPaymentAdjustment(response.data.id);
                order.hasAmountAdjustments = true;
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Guardado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
            })
            .catch(error => {
                alertError();
            })
            .finally(() => {
                setCanDelete(true);
            });
    }

    const deletePaymentAdjustments = () => {
        axios.delete(process.env.REACT_APP_API_ORDER_PAYMENT_ADJUSTMENT_ROUTE + "/" + idPaymentAdjustment, {
            withCredentials: true,
        })
            .then(response => {
                setContador(contador + 1)
                setReload(true);
                order.hasAmountAdjustments = false;
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Eliminado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
            })
            .catch(error => {
                alertError();
            });
    }

    const handleSelectOrder = (orderId) => {
        if (selectedOrdersToEdit.includes(orderId)) {
            // Si la order ya está en la lista, lo eliminamos
            setSelectedOrdersToEdit(selectedOrdersToEdit.filter(id => id !== orderId));
        } else {
            // Si no está en la lista, lo agregamos
            setSelectedOrdersToEdit([...selectedOrdersToEdit, orderId]);
        }
    };

    const fetchPriceListsForProduct = async (productId) => {
        if (!productId) {
            setPriceListItemForProduct([]);
            return;
        }

        try {
            axios.get(process.env.REACT_APP_API_PRICE_LIST_ITEMS_ROUTE + "/product/" + productId, {
                withCredentials: true
            })
                .then(response => {
                    setPriceListItemForProduct(response.data);
                })
                .catch(() => {
                    alertError();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } catch (error) {
            console.error("Error al obtener listas de precios:", error);
            setPriceListItemForProduct([]);
        }
    };

    // Llamar a la API cuando cambia el producto seleccionado
    useEffect(() => {
        if (selectedProduct) {
            fetchPriceListsForProduct(selectedProduct);
        } else {
            setPriceListItemForProduct([]); // Reset cuando no hay producto
        }
    }, [selectedProduct]);

    return (
        <>

            <tr key={order.id}>
                <td className="text-alig-center">{order.orderId}</td>
                <td className="text-alig-center">{order.orderNumber}</td>
                <td className="text-alig-center">{order.orderDate}</td>
                <td className="text-alig-center">{order.ownerOrder}</td>
                <td className="text-alig-center">{order.orderType}</td>
                <td className="text-alig-center">{getStatus(order.status)}</td>
                <td className="text-alig-center">{formatPrice(getTotalAmount() + (order.amountDelivery != null ? order.amountDelivery : 0))}</td>
                <td className="text-alig-center">
                    {(order.delivery) ? (
                        <>
                            SI
                            <button type="button" className="btn" onClick={() => {
                                findAddressOrder();
                                setIsLoading(true);
                                setShowModalAddress(true)
                            }}>
                                <PiInfo className="" size="24" style={{color: '#2658cb'}}/>
                            </button>
                        </>
                    ) : "NO"}
                </td>
                <td>
                    <div className='divBtnsActionsOrder'>
                        <button type="button" className="btn btn-success" onClick={() => {
                            findOrdersDetails();
                            setIsLoading(true)
                            setShowModalDetails(true)
                        }}>
                            Detalle
                        </button>

                        {order.status === "FINALIZED" ? (
                            <button type="button" className="btn btn-danger" style={{marginLeft: '6%'}}
                                    onClick={() => setShowModalCancell(true)}>
                                Cancelar
                            </button>
                        ) : (
                            order.status !== "CANCELLED" && (
                                <>
                                    <button type="button" className="btn btn-warning" style={{marginLeft: '3%'}}
                                            onClick={() => {
                                                setShowModalSetStatus(true);
                                            }}>
                                        <TbStatusChange size={30} style={{color: '#fff'}}/>
                                    </button>

                                    <button type="button" className="btn btn-info" style={{marginLeft: '3%'}}
                                            onClick={() => {
                                                findOrdersDetails();
                                                if (order.delivery) {
                                                    findAddressOrder();
                                                }
                                                getAllCategories();
                                                setIsLoading(true);
                                                setShowModalEditOrder(true);
                                            }}>
                                        <FiEdit size={28} style={{color: '#fff'}}/>
                                    </button>
                                </>
                            ))
                        }

                        <button type="button" className="btn btn-secondary" style={{marginLeft: '3%'}} onClick={() => {
                            findOrdersLogsStatus();
                            setIsLoading(true);
                            setShowModalLogsStatus(true);
                        }}>
                            <PiNotepad size={30}/>
                        </button>

                        {/* Renderiza los botones solo si el estado es "FINALIZED" y invoicingFeature es verdadero */}
                        {order?.status === "FINALIZED" && invoicingFeature && (
                            <>
                                {!order?.afipAutorized && (
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => setShowInvoiceModal(true)}
                                        aria-label="Facturar"
                                    >
                                        <FaRegPaperPlane size={30}/>
                                    </button>
                                )}
                                {order?.afipAutorized && (
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => generateInvoice(order.orderId)}
                                        aria-label="Ver Factura"
                                    >
                                        <LiaFileInvoiceDollarSolid size={30} />
                                    </button>
                                )}
                            </>
                        )}

                        {/* Modal de Facturación */}
                        <InvoiceModal
                            showModal={showInvoiceModal}
                            setShowModal={setShowInvoiceModal}
                            orderId={order.orderId}
                            orderClientId={order.clientId}
                        />
                        {/* Checkbox para seleccionar orden */}
                        {actualStatus != null && actualStatus != "" && actualStatus != "CANCELLED" &&
                        <button style={{all: "unset", cursor: "pointer"}}>
                            <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedOrdersToEdit.includes(order.orderId)}
                            onChange={() => handleSelectOrder(order.orderId)}
                        /></button>}
                    </div>
                </td>
            </tr>

            <Modal size="l" show={showModalSetStatus} onHide={() => setShowModalSetStatus(false)}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel"
                        style={{marginTop: '3%'}}>Cambio de estado del pedido #{order.orderId}</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setShowModalSetStatus(false);
                                setSelectedStatus('');
                                setObservationChangeStatus('');
                                setRestockProducts(false);
                            }}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalOrderDetails">
                    <p>Pasar el pedido a:</p>
                    {renderStatusOptions()}
                    {/* Mostrar el checkbox si se selecciona "CANCELLED" */}
                    {stockFeature && stockFeature.status && selectedStatus === 'CANCELLED' && (
                        <div className="form-check-inline-cancel">
                            <input
                                className="form-check-input-cancel"
                                type="checkbox"
                                id="restockCheckbox"
                                checked={restockProducts}
                                onChange={(e) => setRestockProducts(e.target.checked)}
                            />
                            <label className="form-check-label-cancel" htmlFor="restockCheckbox">
                                ¿Desea reingresar estos productos al stock?
                            </label>
                        </div>
                    )}

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="canceledText" value={observationChangeStatus}
                               onChange={(e) => {
                                   setObservationChangeStatus(e.target.value)
                               }}/>
                        <label htmlFor="floatingInput">Observacion de cambio:</label>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={() => {
                                setShowModalSetStatus(false);
                                setSelectedStatus('');
                                setObservationChangeStatus('');
                                setRestockProducts(false);
                            }}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={setStatusOrder}
                            disabled={selectedStatus == null || selectedStatus == ''}>Confirmar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="xl" show={showModalEditOrder} onHide={() => setShowModalEditOrder(false)}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel">
                        Editando pedido #{order.orderId}
                    </h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => setShowModalEditOrder(false)}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalOrderDetails">
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <>
                            <Container className='align-items-center'>
                                {orderDetails.map(detail => (
                                    <Row key={detail.id} className="pb-2 mt-2" style={{borderBottom: '1px solid #ccc'}}>
                                        <Col xs={6} className="d-flex align-items-center">
                                            <p className="text-break" style={{margin: '0px'}}>
                                                <b>{detail.productName + " (ID: " + detail.productId + ")"}</b> {(detail.brandName != null ? " | MARCA: " + detail.brandName : "")} - {detail.productDescription}
                                            </p>
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center justify-content-center">
                                            <div className='divBotonera'>
                                                <button className='btn m-2' style={{margin: '0px', padding: '0px'}}>
                                                    <SubtractAlt size={25} onClick={() => {
                                                        detail.quantity = Math.max(0, detail.quantity - 1);
                                                        contCantProdDetail(detail);
                                                    }}/>
                                                </button>
                                                <p id={"cantEnPedido" + detail.id}
                                                   style={{margin: '0'}}>{detail.quantity}</p>
                                                <button className='btn m-2' style={{margin: '0px', padding: '0px'}}>
                                                    <AddAlt size={25} style={{marginLeft: '5%'}} onClick={() => {
                                                        detail.quantity += 1;
                                                        contCantProdDetail(detail);
                                                    }}/>
                                                </button>
                                            </div>
                                        </Col>
                                        <Col xs={4} className="d-flex align-items-center justify-content-end">
                                            <p style={{marginBottom: '0px'}} id={"subtotal" + detail.id}>{
                                                formatPrice(detail.subTotal)}</p>
                                        </Col>
                                        {stockFeature && stockFeature.status &&
                                            <label style={{color: 'gray', marginTop: '-10px'}}
                                                   className="form-label">Stock: {allProducts.find(prod => prod.id === parseInt(detail.productId)) != null ? allProducts.find(prod => prod.id === parseInt(detail.productId)).stock : 0}</label>}
                                        {detail.priceListName != null && detail.priceListName != '' &&
                                            <p className="text-break" style={{margin: '0px'}}>
                                                Lista de precio: <b>{detail.priceListName}</b>
                                            </p>
                                        }
                                    </Row>
                                ))}
                                {isDelivery &&
                                    <Row className='pb-2 mt-2' style={{borderBottom: '1px solid #ccc'}}>
                                        <Col xs={8} className="d-flex align-items-center justify-content-start">
                                            <p style={{margin: '0px'}}><strong>Envío</strong></p>
                                        </Col>
                                        <Col xs={{span: 2, offset: 2}}
                                             className="d-flex align-items-center justify-content-end">
                                            <strong>
                                                <p id="envio" style={{margin: '0px'}}>

                                                    {googleLocalizationFeature
                                                        ? shippingPrice !== 0.0
                                                            ? formatPrice(getDeliveryPriceBase(config))
                                                            : "Calculando..."
                                                        : formatPrice(getDeliveryPriceBase(config))
                                                    }

                                                </p>
                                            </strong>
                                        </Col>
                                    </Row>
                                }
                                <Row className='pb-2 mt-2' style={{borderBottom: '1px solid #ccc'}}>
                                    <Col xs={8} className="d-flex align-items-center justify-content-start">
                                        <p style={{margin: '0px'}}><strong>Subtotal</strong></p>
                                    </Col>
                                    <Col xs={{span: 2, offset: 2}}
                                         className="d-flex align-items-center justify-content-end">
                                        <p style={{margin: '0px'}}>
                                            <strong id="total">
                                                {formatPrice(
                                                    orderDetails.reduce((acc, detail) =>
                                                        acc + (Number(detail.quantity) * Number(detail.unitaryPrice)), 0
                                                    ) + (isDelivery ? Number(shippingPrice) : 0)
                                                )}
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                            <button type="button" className="btn btn-secondary mt-3"
                                    style={{backgroundColor: '#C65A4E', border: "none"}} onClick={() => {
                                setShowModalAddProduct(true);
                                setShowModalEditOrder(false);
                            }}>Agregar producto
                            </button>

                            <br/>
                            <br/>

                            <div>
                                <label htmlFor="observations" className="col-form-label">Obvservaciones del
                                    pedido:</label>
                                <textarea rows="3" className="form-control observationsPedido" id="observationsPedido"
                                          value={observationsPedido} onChange={(e) => {
                                    setObservationsPedido(e.target.value);
                                }}/>
                            </div>

                            <br/>
                            <br/>

                            <label htmlFor="nameAndSurname" className="col-form-label">Nombre y apellido:</label>
                            <input type="text" className="form-control nameAndSurname" id="nameAndSurname"
                                   value={nameAndSurname} onChange={(e) => {
                                setNameAndSurname(e.target.value);
                                cleanErrorNameAndSurname();
                            }}
                                   onBlur={() => cleanErrorNameAndSurname()}/>
                            <div className='errorNameAndSurname' id='errorNameAndSurname'>Campo obligatorio</div>

                            <br/>
                            <label htmlFor="isDelivery" className="col-form-label">Envío a domicilio:</label>
                            <br/>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id="isDeliveryTrue"
                                    name="isDelivery"
                                    value="true"
                                    checked={isDelivery === true}
                                    onChange={() => setIsDelivery(true)}
                                />
                                <label className="form-check-label" htmlFor="isDeliveryTrue">
                                    Sí
                                </label>
                            </div>
                            <br/>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id="isDeliveryFalse"
                                    name="isDelivery"
                                    value="false"
                                    checked={isDelivery === false}
                                    onChange={() => {
                                        setIsDelivery(false);
                                        setStreetAddress('');
                                        setNumberAddress('');
                                        setFloorAddress('');
                                        setDeptoAddress('');
                                        setObservationsAddress('')
                                    }}
                                />
                                <label className="form-check-label" htmlFor="isDeliveryFalse">
                                    No
                                </label>
                            </div>
                            {isDelivery && (
                                <>
                                    <br/>
                                    <p style={{color: "#959595"}}>
                                        Valor del envío:{" "}
                                        {googleLocalizationFeature
                                            ? shippingPrice !== 0.0
                                                ? formatPrice(shippingPrice)
                                                : "Calculando..."
                                            : formatPrice(config.deliveryCommon.priceBase)}
                                    </p>

                                    <label htmlFor="addressPedido" className="col-form-label">Dirección de
                                        entrega:</label>
                                    <br/>

                                    {/* Checkbox para Envío a dirección rural */}
                                    {ruralSettingsFeatures?.status && config.ruralSettings && (
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isRuralDelivery"
                                                name="isRuralDelivery"
                                                checked={isRuralDelivery}
                                                onChange={(e) => toggleRuralDelivery(e.target.checked)}
                                            />
                                            <label className="form-check-label" style={{marginTop: '0.7em'}}
                                                   htmlFor="isRuralDelivery">
                                                Envío a dirección rural
                                            </label>
                                        </div>
                                    )}

                                    <div className='row'>
                                        <div className="col-8">
                                            <label htmlFor="streetAddress" className="col-form-label">Calle:</label>
                                            <input
                                                type="text"
                                                className="form-control streetAddress"
                                                id="streetAddress"
                                                value={streetAddress}
                                                onChange={(e) => {
                                                    setStreetAddress(e.target.value);
                                                    checkAddressChange();
                                                    cleanErrorField('streetAddress', 'errorStreetAddress');
                                                }}
                                                disabled={isRuralDelivery}
                                            />
                                            <div className="errorStreetAddress" id="errorStreetAddress">Campo
                                                obligatorio
                                            </div>
                                        </div>

                                        <div className="col">
                                            <label htmlFor="numberAddress" className="col-form-label">Número:</label>
                                            <input
                                                type="number"
                                                className="form-control numberAddress"
                                                id="numberAddress"
                                                value={numberAddress}
                                                onChange={(e) => {
                                                    setNumberAddress(e.target.value);
                                                    checkAddressChange();
                                                    cleanErrorField('numberAddress', 'errorNumberAddress');
                                                }}
                                                disabled={isRuralDelivery}
                                            />
                                            <div className="errorNumberAddress" id="errorNumberAddress">Campo
                                                obligatorio
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-8">
                                            <label htmlFor="localidadDirection"
                                                   className="col-form-label">Localidad:</label>
                                            <input
                                                type="text"
                                                className="form-control localidadDirection"
                                                id="localidadDirection"
                                                value={localidadDirection}
                                                onChange={(e) => {
                                                    setLocalidadDirection(e.target.value);
                                                    checkAddressChange();
                                                    cleanErrorField('localidadDirection', 'errorLocalidadDirection');
                                                }}
                                                disabled={isRuralDelivery}
                                            />
                                            <div className="errorLocalidadDirection" id="errorLocalidadDirection">Campo
                                                obligatorio
                                            </div>
                                        </div>

                                        {config.verifyAddress && (
                                            <div className="col">
                                                <label htmlFor="validarDireccion" className="col-form-label"></label>
                                                <br/><br/>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    style={{backgroundColor: '#007bff', color: '#ffffff'}}
                                                    onClick={() => validateAddress()}
                                                    disabled={isRuralDelivery} // Bloquea si es rural
                                                >
                                                    Validar Dirección
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div className='row'>
                                        <div className="col">
                                            <label htmlFor="piso" className="col-form-label">Piso:</label>
                                            <input
                                                type="number"
                                                className="form-control piso"
                                                id="piso"
                                                value={floorAddress}
                                                onChange={(e) => setFloorAddress(e.target.value)}
                                                disabled={isRuralDelivery}
                                            />
                                        </div>

                                        <div className="col">
                                            <label htmlFor="depto" className="col-form-label">Depto:</label>
                                            <input
                                                type="text"
                                                className="form-control depto"
                                                id="depto"
                                                value={deptoAddress}
                                                onChange={(e) => setDeptoAddress(e.target.value)}
                                                disabled={isRuralDelivery}
                                            />
                                        </div>
                                    </div>
                                    <br/>

                                    <label htmlFor="observations" className="col-form-label">Indicacion adicional del
                                        destino:</label>
                                    <textarea placeholder='Casa con rejas negras, sin timbre' rows="3"
                                              className="form-control observations" id="observations"
                                              value={observationsAddress} onChange={(e) => {
                                        setObservationsAddress(e.target.value);
                                    }}
                                    />
                                </>

                            )}
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={() => setShowModalEditOrder(false)}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => validateAndSendOrder()}>Guardar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="xl" show={showModalAddProduct} onHide={() => setShowModalAddProduct(false)}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel"
                        style={{marginTop: '3%'}}>
                        Agregar producto
                    </h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setSelectedCategory('');
                                setSelectedProduct('');
                                setSelectedPriceList('');
                                setQuantityAddProduct(1);
                                setShowModalAddProduct(false);
                                setShowModalEditOrder(true);
                            }}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalOrderDetails">
                    <div className="mb-3">
                        <label htmlFor="categorySelect" className="form-label">Categoría</label>
                        <select
                            id="categorySelect"
                            className="form-select"
                            value={selectedCategory}
                            onChange={e => {
                                setSelectedProduct('');
                                setSelectedCategory(e.target.value);
                                const category = categories.find(cat => cat.id === parseInt(e.target.value));
                                if (category) {
                                    const productIdsInDetails = orderDetails.map(detail => detail.productId);
                                    const filteredProducts = category.productDTOList.filter(
                                        product => !productIdsInDetails.includes(product.id)
                                    );
                                    setProducts(filteredProducts);
                                } else {
                                    setProducts([]);
                                }
                            }}
                        >
                            <option value="">Seleccione una categoría</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="productSelect" className="form-label">Producto</label>
                        <select
                            id="productSelect"
                            className="form-select"
                            value={selectedProduct}
                            onChange={e => setSelectedProduct(e.target.value)}
                            disabled={!selectedCategory}
                        >
                            <option value="">Seleccione un producto</option>
                            {products.map(product => (
                                <option key={product.id}
                                        value={product.id}>{product.name} {product.brandName != null && product.brandName != "" ? " | MARCA: " + product.brandName : ""}</option>
                            ))}
                        </select>
                        {stockFeature && stockFeature.status && selectedProduct != null && selectedProduct != '' &&
                            <label style={{color: 'gray'}}
                                   className="form-label">Stock: {products.find(prod => prod.id === parseInt(selectedProduct)).stock}</label>}
                    </div>
                    {/* Selección de Lista de Precio */}
                    <div className="mb-3">
                        <label htmlFor="priceListSelect" className="form-label">Lista de precio</label>
                        <select
                            id="priceListSelect"
                            className="form-select"
                            value={selectedPriceList ? selectedPriceList.priceListId : ""}
                            onChange={e => {
                                const selectedItem = priceListItemForProduct.find(item => item.priceListId === parseInt(e.target.value));
                                setSelectedPriceList(selectedItem || null);
                            }}
                            disabled={!selectedProduct}
                        >
                            <option value="">Seleccione un listado de precio</option>
                            {priceListItemForProduct.map(priceListItem => (
                                <option key={priceListItem.priceListId} value={priceListItem.priceListId}>
                                    {priceListItem.priceListName} - ({formatPrice(priceListItem.price)})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="quantityInput" className="form-label">Cantidad</label>
                        <input
                            disabled={selectedProduct == null || selectedProduct == ''}
                            type="number"
                            id="quantityInput"
                            className="form-control"
                            value={quantityAddProduct}
                            min='1'
                            max={selectedProduct != null && selectedProduct != '' ?
                                products.find(prod => prod.id === parseInt(selectedProduct)) != null ?
                                    products.find(prod => prod.id === parseInt(selectedProduct)).stock
                                    : 1000000
                                : 1000000}
                            onChange={e => setQuantityAddProduct(Math.max(1, parseInt(e.target.value)))}
                        />
                        <label style={{color: '#ff1717', display: 'none'}} id='errorQuantityStock'
                               className="form-label">No posee el stock necesario</label>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={() => {
                                setSelectedCategory('');
                                setSelectedProduct('');
                                setSelectedPriceList('');
                                setQuantityAddProduct(1);
                                setShowModalAddProduct(false);
                                setShowModalEditOrder(true);
                            }}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={() => addProduct()}>Agregar</button>
                </Modal.Footer>
            </Modal>

            <Modal size="xl" show={showModalLogsStatus} onHide={() => setShowModalLogsStatus(false)}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel"
                        style={{marginTop: '3%'}}>Todos los cambios de estado del pedido #{order.orderId}</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setShowModalLogsStatus(false);
                            }}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalOrderDetails">
                    <p>Historico del Pedido:</p>

                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <>
                            <div className="orderLogTable">
                                <div className="orderLogRow header">
                                    <div style={{width: '8%'}}>ID mov.</div>
                                    <div style={{width: '25%'}}>Estado</div>
                                    <div style={{width: '20%'}}>Fecha y hora</div>
                                    <div>Observación</div>
                                </div>
                                {orderLogsStatus.map(log => (
                                    <div className="orderLogRow" key={log.id}>
                                        <div>{log.id}</div>
                                        <div>{getStatus(log.status)}</div>
                                        <div>{log.dateStatus}</div>
                                        <div>{log.observation}</div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )
                    }

                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={() => {
                                setShowModalLogsStatus(false);
                            }}>Cerrar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal id="modalOrderDetails" size="xl" show={showModalDetails} onHide={handleCloseModal}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalOrderDetailsLabel">
                        Detalles del pedido #{order.orderId}
                    </h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setShowModalDetails(false);
                                if (!isTax) {
                                    setIsTax(false);
                                    setTypeTax('');
                                    setTypeTaxValue('');
                                    setValueTax('');
                                }
                                if (reload) {
                                    window.location.reload();
                                }
                            }}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalOrderDetails">
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <>
                            <Container className="mb-3">
                                {/* Lista de detalles del pedido */}
                                {orderDetails.map(detail => (
                                    <Row key={detail.id} className="pb-2 mt-2" style={{borderBottom: '1px solid #ccc'}}>
                                        <Col xs={6} className="d-flex align-items-center">
                                            <p className="text-break" style={{margin: '0px'}}>
                                                <b>{detail.productName + " (ID: " + detail.productId + ")"}</b> {(detail.brandName != null ? " | MARCA: " + detail.brandName : "")} - {detail.productDescription}
                                            </p>
                                        </Col>
                                        <Col xs={2} className="d-flex align-items-center justify-content-center">
                                            <p style={{margin: '0px'}}>X{detail.quantity}</p>
                                        </Col>
                                        <Col xs={4} className="d-flex align-items-center justify-content-end">
                                            <p style={{margin: '0px'}}>{formatPrice(detail.subTotal)}</p>
                                        </Col>
                                        {detail.priceListName != null && detail.priceListName != '' &&
                                            <p className="text-break" style={{margin: '0px'}}>
                                                Lista de precio: <b>{detail.priceListName}</b>
                                            </p>
                                        }
                                    </Row>
                                ))}

                                {order.delivery && (
                                    <Row className='pb-2 mt-2' style={{borderBottom: '1px solid #ccc'}}>
                                        <Col xs={8} className="d-flex align-items-center justify-content-start">
                                            <p style={{margin: '0px'}}><strong>Envío</strong></p>
                                        </Col>
                                        <Col xs={{span: 2, offset: 2}}
                                             className="d-flex align-items-center justify-content-end">
                                            <strong>
                                                <p style={{margin: '0px'}}>{formatPrice(shippingPrice)}</p>
                                            </strong>
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                            <br/>

                            {order.orderType !== OrderType.WHATSAP.label && <>
                                {/* Sección de agregar impuesto */}
                                <h1 className="modal-title fs-5" className="titleConfPago"
                                    style={{marginTop: '1%', marginLeft: '4%'}}>Configuracion de pago:</h1>
                                <div className="d-flex align-items-center divRechargesAndBonifications">
                                    <div className="inputGroup me-2 divTipo">
                                        <label htmlFor="tipo" className="col-form-label label">Tipo:</label>
                                        <div>
                                            <div className="radioItem">
                                                <input
                                                    disabled={isTax}
                                                    type="radio"
                                                    id="recargo"
                                                    name="tipo"
                                                    value="SURCHARGE"
                                                    checked={typeTax === "SURCHARGE"}
                                                    onChange={(e) => setTypeTax(e.target.value)}
                                                />
                                                <label htmlFor="recargo">Recargo</label>
                                            </div>

                                            <div className="radioItem">
                                                <input
                                                    disabled={isTax}
                                                    type="radio"
                                                    id="descuento"
                                                    name="tipo"
                                                    value="DISCOUNT"
                                                    checked={typeTax === "DISCOUNT"}
                                                    onChange={(e) => setTypeTax(e.target.value)}
                                                />
                                                <label htmlFor="descuento">Descuento</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inputGroup me-2 divTipoValor">
                                        <label htmlFor="tipoValor" className="col-form-label label">Tipo de
                                            Valor:</label>
                                        <div>
                                            <div className="radioItem">
                                                <input
                                                    disabled={isTax}
                                                    type="radio"
                                                    id="porcentaje"
                                                    name="tipoValor"
                                                    value="PERCENTAGE"
                                                    checked={typeTaxValue === "PERCENTAGE"}
                                                    onChange={(e) => setTypeTaxValue(e.target.value)}
                                                />
                                                <label htmlFor="porcentaje">Porcentaje %</label>
                                            </div>

                                            <div className="radioItem">
                                                <input
                                                    disabled={isTax}
                                                    type="radio"
                                                    id="valorFijo"
                                                    name="tipoValor"
                                                    value="FIXED_AMOUNT"
                                                    checked={typeTaxValue === "FIXED_AMOUNT"}
                                                    onChange={(e) => setTypeTaxValue(e.target.value)}
                                                />
                                                <label htmlFor="valorFijo">Valor Fijo $</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="inputGroup divMonto">
                                        <label htmlFor="monto" className="col-form-label label">Cantidad:</label>
                                        <input
                                            disabled={isTax}
                                            type="number"
                                            id="monto"
                                            className="form-control"
                                            value={valueTax}
                                            onChange={(e) => setValueTax(e.target.value)}
                                        />
                                    </div>

                                    <div className="divCheckTax">
                                        {isTax ? (
                                            <Button className="btn btnCheckTaxDelete" onClick={deleteTaxAndResetInputs}
                                                    disabled={!canDelete}>
                                                <RxCross2 size={30} style={{color: "white"}}/>
                                            </Button>
                                        ) : (
                                            <Button
                                                className="btn btnCheckTaxCreate"
                                                onClick={createTaxAndUpdateTotal} // Llamar a la función de creación de tax y actualización de total
                                                disabled={!typeTax || !typeTaxValue || !valueTax}
                                            >
                                                <RxCheck size={30} style={{color: "white"}}/>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {/* Fin de la sección de agregar impuesto */}
                            </>}
                            <br/>
                            {isTax ? (
                                <>
                                    <br/>
                                    <hr/>
                                    <p className="priceProdTotalPedido">
                                        Subtotal:
                                        {formatPrice(order.totalAmount)}
                                    </p>
                                    <p className="priceProdTotalPedido">
                                        {getAdjustmentType(typeTax)} {typeTaxValue == "PERCENTAGE" ? "(" + valueTax + "%)" : ""}:
                                        {formatPrice(calculateSubtotal(typeTax, typeTaxValue, valueTax))}
                                    </p>
                                    <p className="priceProdTotalPedido">
                                        Envío:
                                        {formatPrice(shippingPrice)}
                                    </p>
                                    <p className="priceProdTotalPedido" style={{fontWeight: 'bold', fontSize: '1.2em'}}>
                                        Total (con envío):
                                        {formatPrice(calculateTotalWithTax(typeTax, typeTaxValue, valueTax))}
                                    </p>
                                    <hr/>
                                </>
                            ) : (
                                <p style={{textAlign: 'end'}}>
                                    <strong>TOTAL:</strong> {formatPrice(getTotalAmount() + (shippingPrice != null ? shippingPrice : 0))}
                                </p>
                            )
                            }

                            <label htmlFor="observation" className="col-form-label">Observaciones:</label>
                            <input type="text" className="form-control" value={order.orderObservation} disabled/>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id="btnCloseModalAdd" type="button" className="btn btn-primary" data-bs-dismiss="modal"
                            onClick={() => receiptPrint(order.orderId)}>Imprimir
                    </button>
                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                            onClick={() => {
                                setShowModalDetails(false);
                                if (!isTax) {
                                    setIsTax(false);
                                    setTypeTax('');
                                    setTypeTaxValue('');
                                    setValueTax('');
                                }
                                if (reload) {
                                    window.location.reload();
                                }
                            }}>Cerrar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal size="xl" show={showModalCancell} onHide={() => setShowModalCancell(false)}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalCancelOrderLabel"
                        style={{marginTop: '3%'}}>Cancelar pedido #{order.orderId}</h3>
                    <button type="button" id="btnCloseModalCancelIcon" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close" onClick={() => {
                        setReasonCancellation("");
                        setShowModalCancell(false)
                    }}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalCancelOrder">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="canceledText" placeholder="Cancelado por ..."
                               value={reasonCancellation} onChange={(e) => {
                            setReasonCancellation(e.target.value)
                        }}/>
                        <label htmlFor="floatingInput">Motivo de cancelación</label>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id={"btnCloseModalCancel" + order.orderId} type="button" className="btn btn-secondary"
                            data-bs-dismiss="modal" onClick={() => {
                        setReasonCancellation("");
                        setShowModalCancell(false)
                    }}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={cancelOrder}>Cancelar pedido</button>
                </Modal.Footer>
            </Modal>

            <Modal size="xl" show={showModalAddress} onHide={() => setShowModalAddress(false)}>
                <Modal.Header className="modal-header modalPedido">
                    <h3 className="modal-title fs-5 titleModalOrderDetails" id="modalAddressOrderLabel"
                        style={{marginTop: '3%'}}>Domicilio de entrega del pedido #{order.orderId}</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setShowModalAddress(false);
                            }}></button>
                </Modal.Header>

                <Modal.Body className="modal-body bodyModalAddressOrder">
                    {orderAddress != null ? (
                            <>
                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="stateAddress" className="col-form-label">Calle:</label>
                                        <input type="text" className="form-control" value={orderAddress.adress} disabled/>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor="stateAddress" className="col-form-label">Nro:</label>
                                        <input type="text" className="form-control" value={orderAddress.numberAdress}
                                               disabled/>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="stateAddress" className="col-form-label">Piso:</label>
                                        <input type="text" className="form-control" value={orderAddress.floor} disabled/>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor="stateAddress" className="col-form-label">Depto:</label>
                                        <input type="text" className="form-control" value={orderAddress.department}
                                               disabled/>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <label htmlFor="stateAddress" className="col-form-label">Indicaciones del
                                            domicilio:</label>
                                        <input type="text" className="form-control" value={orderAddress.deliveryObservation}
                                               disabled/>
                                    </div>
                                </div>
                            </>
                        ) :
                        (<></>)}
                </Modal.Body>

                <Modal.Footer className="modal-footer modalPedido">
                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary"
                            onClick={() => setShowModalAddress(false)}>Cerrar
                    </button>
                </Modal.Footer>
            </Modal>

        </>

    );
};

export default Order;