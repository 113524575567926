import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Container, Modal, Pagination, Row, Table} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {IoLocationOutline} from "react-icons/io5";
import {PiArrowsLeftRight, PiPlusMinus} from "react-icons/pi";
import {FiEdit} from "react-icons/fi";
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import Swal from "sweetalert2";

export default function Brands() {
    const keyLocalStorage = 'authToken';
    const navigate = useNavigate();

    const [brands, setBrands] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [showModalAddBrand, setShowModalAddBrand] = useState(false);
    const [showModalEditBrand, setShowModalEditBrand] = useState(false);

    // Estados de Filtros
    const [brandName, setBrandName] = useState("");

    const [brandNameAdd, setBrandNameAdd] = useState("");

    const [brandNameError, setBrandNameError] = useState(false);
    const [brandNameEditError, setBrandNameEditError] = useState(false);

    const [brandIdEdit, setBrandIdEdit] = useState("");
    const [brandNameEdit, setBrandNameEdit] = useState("");

    useEffect(() => {
        document.title = 'QReate - Marcas';
        getPage();
    }, [currentPage, isLoading]);

    const getConfig = () => {
        return {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                sortBy: 'name'
            }
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const getPage = () => {
        axios.get(process.env.REACT_APP_API_BRAND_PAGE_ROUTE, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            },
            params: {
                page: currentPage,
                brandName: brandName
            }
        })
            .then(response => {
                setBrands(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
            })
            .catch(error => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || brands.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || brands.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={2} md={3}>
                    <Col>
                        <input type="text" className="searchTerm" placeholder="Nombre de marca"
                               value={brandName}
                               onChange={(e) => setBrandName(e.target.value)}
                               onKeyUp={handleKeyPress}/>
                    </Col>
                    {/* Botones de Búsqueda y Limpiar */}
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => getPage()}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => cleanFilters()}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getPage()
        }
    };

    const cleanFilters = () => {
        setBrandName("");
        setIsLoading(true);
        getPage();
    }

    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="3" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

    const modalControlButtons = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col className="text-alig-center">
                        <Button variant="primary" className="me-1"
                                onClick={() => setShowModalAddBrand(true)}>Agregar</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    const addBrandModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalAddBrand} onHide={() => setShowModalAddBrand(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Nueva marca
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrors({
                                    nameBrandAdd: false
                                });
                                setBrandNameAdd("");
                                setShowModalAddBrand(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">

                    <div className="mb-3">
                        <label htmlFor="nameBrand" className="col-form-label">Nombre de la marca:</label>
                        <input type="text" id="nameBrand"
                               className={`form-control ${errors.nameBrandAdd ? "is-invalid" : ""}`}
                               value={brandNameAdd} onChange={(e) => setBrandNameAdd(e.target.value)}/>
                        {errors.nameBrandAdd &&
                            <div className="text-danger">Campo obligatorio.</div>}
                    </div>

                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrors({
                            nameBrandAdd: false
                        });
                        setBrandNameAdd("");
                        setShowModalAddBrand(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSaveBrand}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editBrandModal = () => {
        return (
            <Modal className="modal fade modal-lg" show={showModalEditBrand} onHide={() => setShowModalEditBrand(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                        Editar marca ID: {brandIdEdit}
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setErrorsEdit({
                                    nameBrandAdd: false
                                });
                                setBrandNameEdit("");
                                setShowModalEditBrand(false);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">

                    <div className="mb-3">
                        <label htmlFor="nameBrand" className="col-form-label">Nombre de la marca:</label>
                        <input type="text" id="nameBrand"
                               className={`form-control ${errorsEdit.nameBrandEdit ? "is-invalid" : ""}`}
                               value={brandNameEdit} onChange={(e) => setBrandNameEdit(e.target.value)}/>
                        {errorsEdit.nameBrandEdit &&
                            <div className="text-danger">Campo obligatorio.</div>}
                    </div>

                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        setErrorsEdit({
                            nameBrandAdd: false
                        });
                        setBrandNameEdit("");
                        setShowModalEditBrand(false);
                    }}>
                        Cerrar
                    </button>
                    <button type="button" className="btn btn-primary" data-test-cy="btnGuardarEditBrand" onClick={saveEditBrand}>
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const saveEditBrand = () => {
        if (validateFieldsEdit()) {
            const brandDTO = {
                id: brandIdEdit,
                name: brandNameEdit
            }

            axios.put(process.env.REACT_APP_API_BRAND_ROUTE, brandDTO, {
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem(keyLocalStorage),
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Editado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    // Limpiar campos y cerrar modal
                    resetFormEdit();
                })
                .catch(error => {
                    let errorMessage;

                    if (error.response) {
                        // El servidor respondió con un status fuera del rango 2xx
                        errorMessage = error.response.data.message || error.response.data || 'Error del servidor';
                    } else if (error.request) {
                        // La petición fue hecha pero no se recibió respuesta
                        errorMessage = 'No se pudo conectar con el servidor';
                    } else {
                        // Algo sucedió al configurar la petición
                        errorMessage = error.message || 'Error al procesar la solicitud';
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorMessage,
                        confirmButtonText: 'Cerrar',
                    });
                });
        }
    }

    const handleSaveBrand = () => {
        if (validateFields()) {
            saveBrand();
        }
    };

    const validateFields = () => {
        const newErrors = {
            nameBrandAdd: brandNameAdd === '',
        };
        setErrors(newErrors);

        // Retornar true si no hay errores
        return !Object.values(newErrors).includes(true);
    };

    const validateFieldsEdit = () => {
        const newErrorsEdit = {
            nameBrandEdit: brandNameEdit === '',
        };
        setErrorsEdit(newErrorsEdit);

        // Retornar true si no hay errores
        return !Object.values(newErrorsEdit).includes(true);
    };

    const [errors, setErrors] = useState({
        nameBrandAdd: false,
    });

    const [errorsEdit, setErrorsEdit] = useState({
        nameBrandEdit: false,
    });

    const saveBrand = () => {

        const brandDTO = {
            name: brandNameAdd
        }

        axios.post(process.env.REACT_APP_API_BRAND_ROUTE, brandDTO, {
            withCredentials: true,
            headers: {
                Authorization: localStorage.getItem(keyLocalStorage),
            }
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Creado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                // Limpiar campos y cerrar modal
                resetForm();
            })
            .catch(error => {
                let errorMessage;

                if (error.response) {
                    // El servidor respondió con un status fuera del rango 2xx
                    errorMessage = error.response.data.message || error.response.data || 'Error del servidor';
                } else if (error.request) {
                    // La petición fue hecha pero no se recibió respuesta
                    errorMessage = 'No se pudo conectar con el servidor';
                } else {
                    // Algo sucedió al configurar la petición
                    errorMessage = error.message || 'Error al procesar la solicitud';
                }

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    confirmButtonText: 'Cerrar',
                });
            });
    }

    const resetForm = () => {
        setBrandNameAdd("");
        setShowModalAddBrand(false);
        getPage();
    };

    const resetFormEdit = () => {
        setBrandNameEdit("");
        setShowModalEditBrand(false);
        getPage();
    };

    const editBrand = (brand) => {
        setBrandIdEdit(brand.id);
        setBrandNameEdit(brand.name);
    }

    const deleteBrand = (brand) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar la marca?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_BRAND_ROUTE + '/' + brand.id, {
                    withCredentials: true
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getPage();
                    })
                    .catch(error => {
                        let errorMessage;

                        if (error.response) {
                            // El servidor respondió con un status fuera del rango 2xx
                            errorMessage = error.response.data.message || error.response.data || 'Error del servidor';
                        } else if (error.request) {
                            // La petición fue hecha pero no se recibió respuesta
                            errorMessage = 'No se pudo conectar con el servidor';
                        } else {
                            // Algo sucedió al configurar la petición
                            errorMessage = error.message || 'Error al procesar la solicitud';
                        }

                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: errorMessage,
                            confirmButtonText: 'Cerrar',
                        });
                    });
            }
        });
    }

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    return (
        <div className="containersStock">
            <>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h1 className="titleStock">Marcas de productos</h1>
                        </Col>
                    </Row>
                </Container>

                <div className="productsContainer">
                    <>
                        {filtersContainer()}

                        {(isLoading) ? (
                            loadingTable()
                        ) : (
                            <div className="m-3">
                                <Table responsive className="table-striped">
                                    <thead className="tableHead">
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        brands.length > 0 ? (
                                            brands.map(brand => (
                                                <tr key={brand.id}>
                                                    <td className="text-alig-center fw-bold">{brand.id}</td>
                                                    <td className="text-alig-center">{brand.name}</td>
                                                    <td width="5%">
                                                        <div className="colBtnsActionStock">
                                                            <button className="btn btnEdit" data-test-cy="btnEditBrand"><RequestQuote
                                                                className="btnEdit" size="24"
                                                                onClick={() => {
                                                                    editBrand(brand);
                                                                    setShowModalEditBrand(true);
                                                                }}/></button>
                                                            <button className="btn btnDelete" data-test-cy="btnDeleteBrand"
                                                                    onClick={() => deleteBrand(brand)}><TrashCan
                                                                className="btnDelete" size="24"/>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-alig-center">Aun no hay marcas cargadas
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>

                            </div>
                        )}
                        {paginationModule()}
                        {modalControlButtons()}
                        {addBrandModal()}
                        {editBrandModal()}
                    </>
                </div>
            </>
        </div>
    );
}
