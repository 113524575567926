import React, {useEffect, useState} from 'react';
import {CloseLarge, RequestQuote, Search, TrashCan, View, ViewOff} from '@carbon/icons-react';
import {FaRegCalendarAlt} from 'react-icons/fa';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as AxiosService from '../services/AxiosService';
import * as ConversorService from '../services/ConversorService';
import * as DownloadService from '../services/DownloadService';
import {Modal} from "react-bootstrap";
import {Button, Col, Container, Pagination, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {IoLocationOutline, IoList} from "react-icons/io5";
import {useConfig} from "../context/ConfigContext";
import Select from 'react-select';
import {FiCopy, FiTag} from "react-icons/fi";

export default function MisProductos() {

    // Estados para paginado de todos los productos
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentNumberOfElements, setCurrentNumberOfElements] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    // Estados de Filtros
    const [productNameFilter, setProductNameFilter] = useState("");
    const [categoryIdFilter, setCategoryIdFilter] = useState("");
    const [brandIdFilter, setBrandIdFilter] = useState("");
    const [priceListIdFilter, setPriceListIdFilter] = useState("");

    const [priceListIdEditPriceMassive, setPriceListIdEditPriceMassive] = useState("");

    //Lista de todas las categorias
    const [categories, setCategories] = useState([]);
    //Lista de todas las marcas
    const [brands, setBrands] = useState([]);
    const [priceListsForFilter, setPriceListForFilter] = useState([]);
    //list categorias activas
    const [categoriesActive, setCategoriesActive] = useState([]);

    //Lista de productos filtrados
    const [searching, setSearching] = useState(false);

    //Lista de vencimientos de un producto
    const [productExpiration, setProductExpiration] = useState();
    const [listExpirationsProduct, setListExpirationsProduct] = useState([]);

    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedBrands, setSelectedBrands] = useState('');

    const [productIdEdit, setProductIdEdit] = useState('');
    const [productNameEdit, setProductNameEdit] = useState('');
    const [productDescriptionEdit, setProductDescriptionEdit] = useState('');
    const [productPriceEdit, setProductPriceEdit] = useState('');
    const [selectedCategoryEdit, setSelectedCategoryEdit] = useState('');
    const [selectedBrandEdit, setSelectedBrandEdit] = useState('');
    const [productStatusEdit, setProductStatusEdit] = useState('');

    const [fileMassiveProducts, setFileMassiveProducts] = useState(null);
    const [fileNameMassiveProducts, setFileNameMassiveProducts] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [applyTax, setApplyTax] = useState(false);

    const taxCategories = [
        {value: "NO_GRAVADO", label: "NO_GRAVADO (0.0%)", rate: 0.0},
        {value: "EXENTO", label: "EXENTO (0.0%)", rate: 0.0},
        {value: "GRAVADO_TRES", label: "GRAVADO_TRES (0.0%)", rate: 0.0},
        {value: "GRAVADO_CUATRO", label: "GRAVADO_CUATRO (10.5%)", rate: 10.5},
        {value: "GRAVADO_CINCO", label: "GRAVADO_CINCO (21.0%)", rate: 21.0},
        {value: "GRAVADO_SEIS", label: "GRAVADO_SEIS (27.0%)", rate: 27.0},
        {value: "GRAVADO_OCHO", label: "GRAVADO_OCHO (5.0%)", rate: 5.0},
        {value: "GRAVADO_NUEVE", label: "GRAVADO_NUEVE (2.5%)", rate: 2.5},
        {value: "GRAVADO_DIEZ", label: "GRAVADO_DIEZ (19.0%)", rate: 19.0},
        {value: "GRAVADO_ONCE", label: "GRAVADO_ONCE (22.0%)", rate: 22.0},
        {value: "GRAVADO_DOCE", label: "GRAVADO_DOCE (18.0%)", rate: 18.0},
        {value: "GRAVADO_DIECISEIS", label: "GRAVADO_DIECISEIS (16.0%)", rate: 16.0},
    ];


    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [showModalEditImageProduct, setShowModalEditImageProduct] = useState(false);
    const [showModalEditProduct, setShowModalEditProduct] = useState(false);
    const [showModalExpirationProduct, setShowModalExpirationProduct] = useState(false);
    const [showModalAddExpirationProduct, setShowModalAddExpirationProduct] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [isUpdateImage, setIsUpdateImage] = useState(null);
    const [productImage, setProductImage] = useState(null);
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImage(file);
        }
    };

    const [selectedProductsToEdit, setSelectedProductsToEdit] = useState([]);

    const [adjustmentType, setAdjustmentType] = useState(''); // 'aumentar' o 'disminuir'
    const [valueType, setValueType] = useState(''); // 'porcentaje' o 'valor'
    const [adjustmentValue, setAdjustmentValue] = useState(); // Valor del ajuste

    const [showModalEditPrice, setShowModalEditPrice] = useState(false);
    const [showModalEditPriceFilter, setShowModalEditPriceFilter] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);

    const [selectedTaxCategory, setSelectedTaxCategory] = useState(null);
    const [selectedTaxCategoryEdit, setSelectedTaxCategoryEdit] = useState(null);

    const [initialPrice, setInitialPrice] = useState(''); // Guarda el precio original sin IVA

    //datos para crear una nueva expiration
    const [expirationDate, setExpirationDate] = useState("");
    const [daysAlert, setDaysAlert] = useState("");
    const [observation, setObservation] = useState("");
    const [errors, setErrors] = useState({}); // Estado para los errores

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const [priceLists, setPriceLists] = useState([]);
    const [selectedLists, setSelectedLists] = useState([]);

    const [selectedListsEdit, setSelectedListsEdit] = useState([]);

    const validateFields = () => {
        const newErrors = {};
        const today = new Date();
        const expiration = expirationDate ? new Date(expirationDate) : null;

        if (!expirationDate) {
            newErrors.expirationDate = "La fecha de vencimiento es obligatoria.";
        } else if (expiration && expiration <= today) {
            newErrors.expirationDate = "La fecha de vencimiento debe ser una fecha futura.";
        }

        if (!daysAlert || isNaN(daysAlert) || parseInt(daysAlert) <= 0) {
            newErrors.daysAlert = "Los días de alerta deben ser un número mayor a 0.";
        } else if (expiration) {
            const differenceInDays = Math.ceil((expiration - today) / (1000 * 60 * 60 * 24)); // Diferencia en días
            if (parseInt(daysAlert) >= differenceInDays) {
                newErrors.daysAlert = `Los días de alerta deben ser menores a ${differenceInDays} días.`;
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
    };

    const handleSave = () => {
        if (!validateFields()) return; // Validar antes de proceder

        const productDTO = {
            id: productExpiration.id,
        };

        const newExpiration = {
            productDTO,
            expirationDate: expirationDate + "T00:00:00",
            daysAlert: parseInt(daysAlert),
            observation,
        };

        saveExpiration(newExpiration);
        setExpirationDate("");
        setDaysAlert("");
        setObservation("");
        setErrors({}); // Limpiar errores después de guardar
    };

    // Método saveExpiration
    const saveExpiration = (newExpiration) => {
        axios.post(process.env.REACT_APP_API_PRODUCTS_EXPIRATIONS_ROUTE, newExpiration, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Guardado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalExpirationProduct(false);
                setShowModalAddExpirationProduct(false);
                expirationsProduct(productExpiration);
            })
            .catch(error => {
                alertError();
            });
    };

    const handleSelectProduct = (productId) => {
        if (selectedProductsToEdit.includes(productId)) {
            // Si el producto ya está en la lista, lo eliminamos
            setSelectedProductsToEdit(selectedProductsToEdit.filter(id => id !== productId));
        } else {
            // Si no está en la lista, lo agregamos
            setSelectedProductsToEdit([...selectedProductsToEdit, productId]);
        }
    };

    const handleImageUpload = () => {
        setIsUpdateImage(true);
        // Validar si se ha seleccionado una imagen
        if (!selectedImage) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se ha seleccionado ninguna imagen.',
            });
            setIsUpdateImage(false);
            return;
        }

        // Validar el tamaño de la imagen (máximo 1MB)
        const maxFileSize = 1 * 1024 * 1024; // 1MB en bytes
        if (selectedImage.size > maxFileSize) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El archivo excede el tamaño máximo permitido de 1MB.',
            });
            setIsUpdateImage(false);
            return;
        }

        const formData = new FormData();
        formData.append('productId', productIdEdit);
        formData.append('image', selectedImage);

        axios.post(`${process.env.REACT_APP_API_PRODUCTS_IMAGES_ROUTE}`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Imagen cargada correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalEditImageProduct(false);
                setShowModalEditProduct(true);
            })
            .catch(error => {
                const errorMessage = error.response?.data || 'Hubo un problema al cargar la imagen.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                });
                console.error(error);
            }).finally(() => {
            setIsUpdateImage(false);
        });
    };

    const deleteImage = () => {
        axios.delete(process.env.REACT_APP_API_PRODUCTS_IMAGES_GET_ROUTE + productIdEdit.toString(), {
            responseType: 'arraybuffer',
            withCredentials: true
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Imagen eliminada.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setShowModalEditImageProduct(false);
                setShowModalEditProduct(true);
            })
            .catch(error => {
                alertError();
                console.error(error);
            });
    }

    const fetchProductImage = (productId) => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_IMAGES_GET_ROUTE + productId.toString(), {
            responseType: 'arraybuffer',
            withCredentials: true
        })
            .then(response => {
                if (response.data && response.data.byteLength > 0) {
                    const imageBlob = new Blob([response.data], {type: 'image/webp'});
                    const imageUrl = URL.createObjectURL(imageBlob);
                    setProductImage(imageUrl);
                } else {
                    setProductImage(null);
                }

            })
            .catch(error => {
                alertError();
                console.error(error);
                setProductImage(null);
            });
    };

    const updatePriceWithTax = () => {
        const taxRate = taxCategories.find(tax => tax.value === selectedTaxCategory)?.rate || 0;
        const newPrice = parseFloat(productPrice) + (parseFloat(productPrice) * taxRate / 100);
        setProductPrice(newPrice.toFixed(2));
    };

    const handleApplyTax = (checked) => {
        if (checked) {
            // Guarda el precio actual antes de calcular el IVA
            setInitialPrice(productPrice);
            const taxRate = taxCategories.find(tax => tax.value === selectedTaxCategory)?.rate || 0;
            const newPrice = parseFloat(productPrice) + (parseFloat(productPrice) * taxRate / 100);
            setProductPrice(newPrice.toFixed(2));
            setApplyTax(true);
        } else {
            // Restaura el precio original
            setProductPrice(initialPrice);
            setApplyTax(false);
        }
    };

    const handleTaxCategoryChange = (value) => {
        // Cambiar categoría de impuesto y restaurar estado inicial
        setSelectedTaxCategory(value);
        setApplyTax(false); // Desmarcar el checkbox
        setProductPrice(initialPrice); // Restaurar el precio original
    };

    useEffect(() => {
        document.title = 'QReate - Productos';
        setSearching(false);
        getPage();
        getAllCategories();
        getAllBrands();
        getAllPriceList();

        setSelectedTaxCategory("GRAVADO_CINCO"); // IVA 21%

    }, [currentPage, isLoading]);

    const brandOptions = brands.map((brand) => ({
        value: brand.id,
        label: brand.name,
    }));

    // MODULOS DE CONFIGURACIONES DE NEGOCIO
    const {features} = useConfig();
    const expirationsProductFeature = features.find(feature => feature.name === 'VENCIMIENTOS DE PRODUCTOS');

    // Armado de la página
    const getPage = () => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_PAGE_ROUTE, {
            withCredentials: true,
            params: {
                page: currentPage,
                name: productNameFilter,
                category: categoryIdFilter,
                brand: brandIdFilter
            }
        })
            .then(response => {
                setProducts(response.data.content);
                setTotalPages(response.data.totalPages);
                setCurrentPage(response.data.number + 1);
                setCurrentNumberOfElements(response.data.numberOfElements)
                setTotalElements(response.data.totalElements)
            })
            .catch(error => {
                alertError();
                console.warn(error)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    const handleSelectCategoryEdit = (event) => {
        setSelectedCategoryEdit(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearching(true);
            getPage();
        }
    };

    const saveEditPriceProduct = () => {
        let editPriceProduct = {
            productIds: selectedProductsToEdit,
            adjustmentType: adjustmentType,
            valueType: valueType,
            value: adjustmentValue,
            priceListIdFilter: priceListIdFilter,
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_EDIT_PRICE_ROUTE, editPriceProduct, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Editado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setAdjustmentType('');
                setAdjustmentValue('');
                setValueType('');
                setSelectedProductsToEdit([]);
                setShowModalEditPrice(false);
                getPage();
            })
            .catch(error => {
                alertError();
            }).finally(() => {
            setLoadingEdit(false);
        });
    }

    const saveEditPriceFilterProduct = () => {
        let editPriceProductFilter = {
            productNameFilter: productNameFilter,
            categoryIdFilter: categoryIdFilter,
            brandIdFilter: brandIdFilter,
            priceListIdFilter: priceListIdFilter,
            adjustmentType: adjustmentType,
            valueType: valueType,
            value: adjustmentValue,
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_EDIT_PRICE_FILTER_ROUTE, editPriceProductFilter, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Editado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                setAdjustmentType('');
                setAdjustmentValue('');
                setValueType('');
                setShowModalEditPriceFilter(false);
                getPage();
            })
            .catch(error => {
                alertError();
            }).finally(() => {
            setLoadingEdit(false);
        });
    }

    const saveProduct = async () => {
        const priceIsValid = !isNaN(productPrice) && productPrice > 0;

        if (productName == '' || productName == null || selectedCategory == '' || selectedCategory == null || !priceIsValid) {
            if (productName == '' || productName == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredName');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('nombre');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (selectedCategory == '' || selectedCategory == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredCategory');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('selector');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (productPrice == '' || productPrice == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredPrice');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('price');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let newProduct = {
                name: productName,
                description: productDescription,
                price: productPrice,
                idCategory: selectedCategory,
                categoryIva: selectedTaxCategory,
                brand: selectedBrands,
                priceListAddOrEditProductDTOList: selectedLists,
            }

            axios.post(process.env.REACT_APP_API_PRODUCTS_ROUTE, newProduct, {
                withCredentials: true
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    const closeButton = document.getElementById('btnCloseModalAdd');
                    closeButton.click();
                    getPage();
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });

            setProductName('');
            setProductDescription('');
            setSelectedCategory('');
            setProductPrice('');
            setSelectedTaxCategory("GRAVADO_CINCO");
            setSelectedBrands('');
        }
    };

    function alertError() {
        Swal.fire({
            title: 'Upsss',
            text: 'Ocurrió un error, vuelve a intentar',
            icon: 'error',
            timer: 1500,
            showConfirmButton: false,
        });
    }

    function alertErrorConMensaje(mensaje) {
        Swal.fire({
            title: 'Error',
            text: mensaje,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: 'rgb(246, 139, 127)',
            allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
            allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
            allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
        });
    }

    const deleteProduct = (product) => {
        Swal.fire({
            title: '¿Estás seguro de eliminar el producto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, {
                    withCredentials: true
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        getPage();
                    })
                    .catch(error => {
                        alertError();
                        console.error('Error al realizar la solicitud PUT', error);
                    });
            }
        });
    }

    const expirationsProduct = (product) => {
        axios.get(process.env.REACT_APP_API_PRODUCTS_EXPIRATIONS_BY_PRODUCT_ID_ROUTE + product.id, {
            withCredentials: true
        })
            .then(response => {
                setListExpirationsProduct(response.data);
                setProductExpiration(product);
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
        setShowModalExpirationProduct(true);
    }

    const editProduct = (product) => {
        setProductIdEdit(product.id);
        setProductNameEdit(product.name);
        setProductDescriptionEdit(product.description);
        setProductPriceEdit(product.price);
        setSelectedCategoryEdit(product.idCategory);
        setSelectedBrandEdit(product.brandName != null && product.brandName != "" ? product.brandName : "Sin marca");
        setProductStatusEdit(product.status);
        setSelectedTaxCategoryEdit(product.categoryIva);
        let selectedListPrice = [];
        product.priceListAddOrEditProductDTOList.forEach(listPrice => {
            if (listPrice.priceListId != 1) {
                selectedListPrice.push({
                    id: listPrice.priceListId,
                    name: listPrice.name,
                    price: listPrice.price
                })
            }
        })
        setSelectedListsEdit(selectedListPrice);
    }

    const saveEditproduct = () => {
        if (productNameEdit == '' || productPriceEdit == '' || selectedCategoryEdit == '') {
            if (productNameEdit == '' || productNameEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredNameEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('nombreEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (selectedCategoryEdit == '' || selectedCategoryEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredCategoryEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('selectorEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
            if (productPriceEdit == '' || productPriceEdit == null) {
                let fieldRequiredElement = document.querySelector('.fieldRequiredPriceEdit');
                fieldRequiredElement.style.display = 'block';
                let inputElement = document.getElementById('priceEdit');
                inputElement.style.border = '1px solid #ff1717';
            }
        } else {
            let editProduct = {
                name: productNameEdit,
                description: productDescriptionEdit,
                price: productPriceEdit,
                idCategory: selectedCategoryEdit,
                status: productStatusEdit == "ACTIVO" ? 1 : 0,
                categoryIva: selectedTaxCategoryEdit,
                brandName: selectedBrandEdit,
                priceListAddOrEditProductDTOList: selectedListsEdit,
            }

            axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + productIdEdit, editProduct, {
                withCredentials: true
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Editada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getPage();
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });

            const closeButton = document.getElementById('btnCloseModalEdit');
            closeButton.click();

            setProductIdEdit('');
            setProductNameEdit('');
            setProductDescriptionEdit('');
            setProductPriceEdit('');
            setSelectedCategoryEdit('');
            setSelectedBrandEdit('Sin marca');
            setProductStatusEdit('');
        }
    }

    const getAllCategories = () => {
        axios.get(process.env.REACT_APP_API_CATEGORIES_ROUTE, {
            withCredentials: true
        })
            .then(response => {
                setCategories(response.data);
                setCategoriesActive(response.data.filter((categoria) => categoria.status));
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    const getAllBrands = () => {
        axios.get(process.env.REACT_APP_API_BRAND_ROUTE, {
            withCredentials: true
        })
            .then(response => {
                setBrands(response.data);
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    const getAllPriceList = () => {
        axios.get(process.env.REACT_APP_API_PRICE_LISTS_ROUTE + "/v1/list", {
            withCredentials: true
        })
            .then(response => {
                setPriceListForFilter(response.data);
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    const resetValuesAndStyleErrors = () => {
        setProductName('');
        setProductDescription('');
        setSelectedCategory('');
        setProductPrice('');
        setSelectedTaxCategory("GRAVADO_CINCO");
        setSelectedBrands('');
        setApplyTax(false);
        setSelectedLists([]); // Limpiar las listas seleccionadas
        resetStyleErrorName();
        resetStyleErrorDesc();
        resetStyleErrorCat();
        resetStyleErrorPrice();
    };

    const resetStyleErrorsEdit = () => {
        resetStyleErrorNameEdit();
        resetStyleErrorDescEdit();
        resetStyleErrorCatEdit();
        resetStyleErrorPriceEdit();
    }

    const cleanErrorName = () => {
        if (productName != '' && productName != null) {
            resetStyleErrorName();
        }
    }

    const resetStyleErrorName = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredName');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombre');
        inputElement.style.border = '';
    }

    const cleanErrorNameEdit = () => {
        if (productNameEdit != '' && productNameEdit != null) {
            resetStyleErrorNameEdit();
        }
    }

    const resetStyleErrorNameEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredNameEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('nombreEdit');
        inputElement.style.border = '';
    }

    const cleanErrorDesc = () => {
        if (productDescription != '' && productDescription != null) {
            resetStyleErrorDesc();
        }
    }

    const resetStyleErrorDesc = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredDescription');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('description');
        inputElement.style.border = '';
    }

    const resetStyleErrorCat = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredCategory');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('selector');
        inputElement.style.border = '';
    }

    const cleanErrorDescEdit = () => {
        if (productDescriptionEdit != '' && productDescriptionEdit != null) {
            resetStyleErrorDescEdit();
        }
    }

    const resetStyleErrorDescEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredDescriptionEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('descriptionEdit');
        inputElement.style.border = '';
    }

    const resetStyleErrorCatEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredCategoryEdit');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('selectorEdit');
        inputElement.style.border = '';
    }

    const cleanErrorPrice = () => {
        if (productPrice != '' && productPrice != null) {
            resetStyleErrorPrice();
        }
    }

    const resetStyleErrorPrice = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredPrice');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('price');
        inputElement.style.border = '';
    }

    const cleanErrorPriceEdit = () => {
        if (productPriceEdit != '' && productPriceEdit != null) {
            resetStyleErrorPriceEdit();
        }
    }

    const resetStyleErrorPriceEdit = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredPriceEdit');
        if (fieldRequiredElement) {
            fieldRequiredElement.style.display = 'none';
        }
        let inputElement = document.getElementById('priceEdit');
        if (inputElement) {
            inputElement.style.border = '';
        }
    };

    const cleanFilters = () => {
        setProductNameFilter("");
        setCategoryIdFilter("");
        setBrandIdFilter("");
        setIsLoading(true);
        getPage();
    }

    const isCategoryActive = (id) => {
        return categoriesActive.filter((categoria) => categoria.id == id).length > 0;
    };

    const enableProd = (product) => {
        if (product.idCategory != null && isCategoryActive(product.idCategory)) {
            let editProduct = {
                name: product.name,
                description: product.description,
                imgPath: product.imgPath,
                status: 1,
                price: product.price,
                idCategory: product.idCategory
            }

            axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, editProduct, {
                withCredentials: true
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Activado correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });
                    getPage();
                })
                .catch(error => {
                    alertError();
                    console.error(error)
                });
        } else {
            Swal.fire({
                title: 'Error',
                text: "No se puede ACTIVAR un producto sin CATEORIA O con CATEGORÍA INACTIVA",
                icon: 'error',
                showConfirmButton: true,
                confirmButtonColor: 'rgb(246, 139, 127)',
                allowOutsideClick: false,  // Evita que se cierre haciendo clic fuera del mensaje
                allowEscapeKey: false,  // Evita que se cierre pulsando la tecla Esc
                allowEnterKey: false  // Evita que se cierre pulsando la tecla Enter
            });
        }
    }

    const disableProd = (product) => {
        let editProduct = {
            name: product.name,
            description: product.description,
            imgPath: product.imgPath,
            status: 0,
            price: product.price,
            idCategory: product.idCategory
        }

        axios.put(process.env.REACT_APP_API_PRODUCTS_ROUTE + '/' + product.id, editProduct, {
            withCredentials: true
        })
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: '¡Éxito!',
                    text: 'Desactivado correctamente.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                getPage();
            })
            .catch(error => {
                alertError();
                console.error(error)
            });
    }

    function formatPrice(price) {
        if (!price) return ''; // Manejar caso de valor vacío
        const parts = price.toString().split('.'); // Separar parte entera de la decimal
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar separador de miles
        return parts.join(','); // Unir las partes con coma como separador decimal
    }

    const resetStyleErrorFile = () => {
        let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
        fieldRequiredElement.style.display = 'none';
        let inputElement = document.getElementById('fileMassiveProducts');
        inputElement.style.border = '';
        setFileMassiveProducts('');
        setFileNameMassiveProducts('');
    }

    const addProductsMassive = () => {
        if (fileMassiveProducts == '' || fileMassiveProducts == null) {
            let fieldRequiredElement = document.querySelector('.fieldRequiredFile');
            fieldRequiredElement.style.display = 'block';
            let inputElement = document.getElementById('fileMassiveProducts');
            inputElement.style.border = '1px solid #ff1717';
            return;
        }

        setIsButtonDisabled(true);

        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result.split(",")[1]; // Obtiene el string base64

            axios.post(process.env.REACT_APP_API_PRODUCTS_FILE_UPLOAD_ROUTE, base64String, {
                withCredentials: true,
                headers: {
                    "Content-Type": "text/plain"
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Éxito!',
                        text: 'Guardada correctamente.',
                        timer: 1500,
                        showConfirmButton: false,
                    });

                    setTimeout(() => {
                        const btnCloseModal = document.getElementById('btnCloseModalAddMassive');
                        if (btnCloseModal) {
                            btnCloseModal.click();
                        }
                    }, 1800);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2100);
                })
                .catch(error => {
                    setIsButtonDisabled(false);
                    alertErrorConMensaje(error.response.data.errorMsg)
                    console.error(error);
                });
        };

        reader.readAsDataURL(fileMassiveProducts); // Lee el archivo como una URL de datos

    }

    const handleDownload = async () => {
        const {data} = await AxiosService.getWithCredentialsSync(process.env.REACT_APP_API_PRODUCTS_FILE_DOWNLOAD_ROUTE); // Obtengo la data
        const blob = ConversorService.convertBase64ToBlob(data); // Conversion de la data
        DownloadService.downloadFile(blob, "ProductosUCODE", "xlsx"); // Genero y descargo el archivo
    }

    const handleFileChange = (event) => {
        setFileMassiveProducts(event.target.files[0]);
    };

    // Basicos
    const pageHeader = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col>
                        <h1 className="titleProducts">Productos</h1>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de filtros
    const filtersContainer = () => {
        return (
            <Container>
                <Row className="mt-3 justify-content-center" xs={2} md={4}>
                    <Col>
                        <input type="text" className="searchTerm" placeholder="Nombre de producto"
                               value={productNameFilter}
                               onChange={(e) => setProductNameFilter(e.target.value)}
                               onKeyUp={handleKeyPress}/>
                    </Col>
                    <Col>
                        <select
                            className="selectFilterProd"
                            value={categoryIdFilter}
                            onChange={(e) => setCategoryIdFilter(e.target.value)}
                        >
                            <option value=''>Seleccione categoría</option>
                            {categories
                                .map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>))}
                        </select>
                    </Col>
                    <Col>
                        <select
                            className="selectFilterProd"
                            value={brandIdFilter}
                            onChange={(e) => setBrandIdFilter(e.target.value)}
                        >
                            <option value=''>Seleccione marca</option>
                            {brands
                                .map(brand => (
                                    <option key={brand.id} value={brand.id}>{brand.name}</option>))}
                        </select>
                    </Col>
                    <Col className="text-center text-md-start mt-3 mt-md-0 mb-sm-3 mb-md-0">
                        <Button id="btnSearchProduct" className="me-1" variant="primary" onClick={() => {
                            setSearching(true);
                            getPage();
                        }}>
                            <Search/>
                        </Button>
                        <Button id="btnCleanSearchProducts" className="ms-1" variant="primary" onClick={() => {
                            setSearching(false);
                            cleanFilters();
                        }}>
                            <CloseLarge/>
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    // Manejo de tablas
    const loadingTable = () => {
        return (
            <>
                <div className="m-3">
                    <Table responsive className="table-striped">
                        <thead className="tableHead">
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Categoría</th>
                            <th>Marca</th>
                            <th>Descripción</th>
                            <th>Precio</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="8" className="backgroundTable text-center">
                                <div className="loader-container-listado">
                                    <div className="loader"></div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }
    const paginationModule = () => {
        return (
            <Container fluid>
                <Row>
                    <Col className="d-flex align-items-center">
                        <Pagination>
                            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)}
                                             disabled={currentPage === 1}/>
                            {currentPage > 2 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 2)}>
                                    {currentPage - 2}
                                </Pagination.Item>
                            )}
                            {currentPage > 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                    {currentPage - 1}
                                </Pagination.Item>
                            )}
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage < totalPages && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                    {currentPage + 1}
                                </Pagination.Item>
                            )}
                            {currentPage < totalPages - 1 && (
                                <Pagination.Item onClick={() => handlePageChange(currentPage + 2)}>
                                    {currentPage + 2}
                                </Pagination.Item>
                            )}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                             disabled={currentPage === totalPages || categories.length === 0}/>
                            <Pagination.Last onClick={() => handlePageChange(totalPages)}
                                             disabled={currentPage === totalPages || categories.length === 0}/>
                        </Pagination>
                        <div className="ms-2">
                            <p>Mostrando {currentNumberOfElements} de {totalElements} </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // Manejo de modales
    const modalControlButtons = () => {
        return (
            <Container className="mt-3">
                <Row>
                    <Col className="text-alig-center">
                        <Button variant="primary" className="me-1" data-bs-toggle="modal"
                                data-bs-target="#modaleAddProduct">Agregar</Button>
                        <Button variant="primary" className="ms-1" data-bs-toggle="modal"
                                data-bs-target="#modaleAddProductMassive">Carga Masiva</Button>
                    </Col>
                </Row>
            </Container>
        )
    }
    const addProductMassiveModal = () => {
        return (
            <div className="modal fade modal-lg" id="modaleAddProductMassive" data-bs-backdrop="static"
                 data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddProductMassiveLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="modaleAddProductMassiveLabel"
                                className="titleModalCreateCategory">Carga masiva de productos</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        resetStyleErrorFile()
                                    }}></button>
                        </div>
                        <div className="modal-body">
                            <button className="btn" style={{backgroundColor: '#C65A4E', color: 'white'}}
                                    onClick={handleDownload}>
                                Descargar Template
                            </button>
                            <div style={{marginTop: '5%'}}>
                                <label htmlFor="fileMassiveProducts" className="col-form-label">Carga archivo excel con
                                    productos:</label>
                                <input type="file" className="form-control" id="fileMassiveProducts"
                                       value={fileNameMassiveProducts} onChange={(e) => {
                                    setFileNameMassiveProducts(e.target.value);
                                    handleFileChange(e);
                                }}/>
                                <div className="fieldRequiredFile" display='none'>El archivo es obligatorio
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="btnCloseModalAddMassive" type="button" className="btn btn-secondary"
                                    data-bs-dismiss="modal" onClick={() => {
                                resetStyleErrorFile();
                            }}>Cerrar
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={addProductsMassive}
                                    disabled={isButtonDisabled}>
                                {isButtonDisabled ? "Guardando..." : "Guardar"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const addProductModal = () => {
        return (
            <div className="modal fade modal-xl" id="modaleAddProduct" data-bs-backdrop="static"
                 data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modaleAddProductLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 titleModalCreateProduct" id="modaleAddProductLabel">
                                Nuevo producto
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        resetValuesAndStyleErrors();
                                        setSelectedProduct(null);
                                        setSelectedImage(null);
                                        setPreviewImage(null);
                                    }}></button>
                        </div>
                        <div className="modal-body">
                            {/* Nombre */}
                            <div className="mb-3">
                                <label htmlFor="nombre" className="col-form-label">Nombre:</label>
                                <input type="text" className="form-control" id="nombre" value={productName}
                                       onChange={(e) => {
                                           const productNameIng = e.target.value;
                                           if (productNameIng.length <= 100) {
                                               setProductName(productNameIng);
                                           }
                                           cleanErrorName();
                                       }}/>
                                <div className="fieldRequiredName">Campo obligatorio</div>
                            </div>

                            {/* Descripción */}
                            <div className="mb-3">
                                <label htmlFor="description" className="col-form-label">Descripción:</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    value={productDescription}
                                    onChange={(e) => {
                                        const productDescriptionIng = e.target.value;
                                        if (productDescriptionIng.length <= 255) {
                                            setProductDescription(productDescriptionIng);
                                        }
                                        cleanErrorDesc();
                                    }}
                                    rows="3" // Ajusta la cantidad de líneas visibles
                                />
                                <div className="fieldRequiredDescription">Campo obligatorio</div>
                            </div>

                            {/* Categoría */}
                            <div className="mb-3">
                                <label htmlFor="category" className="col-form-label">Categoría:</label>
                                <select id="selector" className="form-control" value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e.target.value)}>
                                    <option value=''>Seleccionar categoría</option>
                                    {categoriesActive.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                                <div className="fieldRequiredCategory">Campo obligatorio</div>
                            </div>

                            {/* Marca */}
                            <div className="mb-3">
                                <label htmlFor="brands" className="col-form-label">Marca:</label>
                                <Select
                                    id="brands"
                                    key={selectedBrands}
                                    options={brandOptions}
                                    value={brandOptions.find(option => selectedBrands == option.value)}
                                    onChange={(selectedOption) => setSelectedBrands(selectedOption ? selectedOption.value : null)}
                                    className="custom-multi-select"
                                    placeholder="Selecciona una marca..."
                                    isClearable
                                />
                            </div>

                            {/* Precio */}
                            <div className="mb-3">
                                <label className="col-form-label fw-bold">Precios</label>

                                {/* Precio Lista Default */}
                                <div className="border rounded p-3 mb-3 bg-light">
                                    <label htmlFor="price" className="col-form-label">Precio Lista Default:</label>
                                    <div className="price-input d-flex align-items-center">
                                        <p className="dollar-sign"> $ </p>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="price"
                                            value={productPrice}
                                            onChange={(e) => {
                                                const productPriceIng = e.target.value;
                                                const hasDecimal = productPriceIng.includes(".");
                                                if (hasDecimal) {
                                                    if (productPriceIng.length <= 20) {
                                                        setProductPrice(productPriceIng);
                                                        setInitialPrice(productPriceIng);
                                                    }
                                                } else {
                                                    if (productPriceIng.length <= 17) {
                                                        setProductPrice(productPriceIng);
                                                        setInitialPrice(productPriceIng);
                                                    }
                                                }
                                                cleanErrorPrice();
                                            }}
                                            placeholder="Precio sin IVA"
                                        />
                                        <div className="ms-3 d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                id="applyTax"
                                                checked={applyTax}
                                                disabled={!productPrice || isNaN(parseFloat(productPrice)) || parseFloat(productPrice) <= 0}
                                                onChange={(e) => handleApplyTax(e.target.checked)}
                                            />
                                            <label htmlFor="applyTax" className="ms-2">Aplicar IVA</label>
                                        </div>
                                    </div>
                                    <div className="fieldRequiredPrice">Campo obligatorio</div>

                                    {/* Categoría de impuesto */}
                                    <div className="mt-3">
                                        <label htmlFor="taxCategory" className="col-form-label">Categoría de
                                            impuesto:</label>
                                        <select
                                            id="taxCategory"
                                            className="form-control"
                                            value={selectedTaxCategory}
                                            onChange={(e) => handleTaxCategoryChange(e.target.value)}
                                        >
                                            {taxCategories.map(tax => (
                                                <option key={tax.value} value={tax.value}>{tax.label}</option>
                                            ))}
                                        </select>
                                        <div className="fieldRequiredTaxCategory">Campo opcional</div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    {/* Otras Listas de Precios */}
                                    <div className="border rounded p-3">
                                        <label className="col-form-label d-flex align-items-center">
                                            <FiTag className="me-2" size={18}/>
                                            <span
                                                className="fw-bold">Otras Listas de Precios ({priceLists.length})</span>
                                        </label>
                                        <small className="text-muted d-block mb-3">
                                            Seleccione las listas donde desea agregar este producto y especifique el
                                            precio para cada una
                                        </small>

                                        {priceLists.length > 0 ? (
                                            <div className="list-group">
                                                {priceLists.map(list => (
                                                    <div key={list.id} className="list-group-item border-0 ps-0">
                                                        <div className="form-check" style={{display: 'flex', alignItems: 'center'}}>
                                                            <div style={{marginLeft: '2%'}}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={`list-${list.id}`}
                                                                    checked={selectedLists.some(l => l.id === list.id)}
                                                                    onChange={(e) => {
                                                                        const updatedLists = e.target.checked
                                                                            ? [...selectedLists, {
                                                                                id: list.id,
                                                                                name: list.name,
                                                                                price: productPrice
                                                                            }]
                                                                            : selectedLists.filter(l => l.id !== list.id);
                                                                        setSelectedLists(updatedLists);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div style={{marginLeft: '1%'}}>
                                                                <label
                                                                    className="form-check-label d-flex align-items-center"
                                                                    htmlFor={`list-${list.id}`}>
                                                                    <FiTag size={14} className="me-2 text-primary"/>
                                                                    <span
                                                                        className="fw-bold text-primary">{list.name}</span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {selectedLists.find(l => l.id === list.id) && (
                                                            <div className="mt-2 ms-4">
                                                                <div className="input-group"
                                                                     style={{maxWidth: '300px'}}>
                                                                    <span className="input-group-text">$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={selectedLists.find(l => l.id === list.id)?.price || ''}
                                                                        onChange={(e) => {
                                                                            const updatedLists = selectedLists.map(l =>
                                                                                l.id === list.id
                                                                                    ? {...l, price: e.target.value}
                                                                                    : l
                                                                            );
                                                                            setSelectedLists(updatedLists);
                                                                        }}
                                                                    />
                                                                    <button
                                                                        className="btn btn-outline-secondary"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            const updatedLists = selectedLists.map(l =>
                                                                                l.id === list.id
                                                                                    ? {...l, price: productPrice}
                                                                                    : l
                                                                            );
                                                                            setSelectedLists(updatedLists);
                                                                        }}
                                                                        title="Usar precio de lista default"
                                                                    >
                                                                        <FiCopy size={18}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-muted">No hay otras listas de precios disponibles</div>
                                        )}
                                    </div>
                                </div>

                                {/* Footer */}
                                <div className="modal-footer">
                                    <button id="btnCloseModalAdd" type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal" onClick={() => resetValuesAndStyleErrors()}>Cerrar
                                    </button>
                                    <button type="button" className="btn btn-primary"
                                            data-test-cy="btnGuardarSaveProduct"
                                            onClick={() => saveProduct()}>Guardar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const editPriceMasiveProductModal = () => {
        return (
            <Modal size="l" show={showModalEditPrice} onHide={() => setShowModalEditPrice(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5" id="modaleEditPriceProductsLabel"
                        className="titleModalCreateProduct">Editar precio de productos</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setAdjustmentType('');
                                setValueType('');
                                setAdjustmentValue('');
                                setShowModalEditPrice(false);
                                setSelectedProduct(null);
                                setSelectedImage(null);
                                setPreviewImage(null);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Selección de listas de precios */}
                    <div className="mb-3">
                        {/* Selección de listas de precios */}
                        <div className="mb-3">
                            <label className="form-label" style={{marginBottom: '3%'}}><strong>Listas de precios:</strong></label>
                            <Select
                                isMulti
                                options={priceListsForFilter.map(list => ({
                                    value: list.id,
                                    label: list.name
                                }))}
                                onChange={(selectedOptions) => {
                                    setPriceListIdFilter(selectedOptions ? selectedOptions.map(option => option.value).join(',') : '');

                                    const selectAllCheckbox = document.getElementById('selectAllPriceListsFilter');
                                    if (selectAllCheckbox) {
                                        selectAllCheckbox.checked = false;
                                    }
                                }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Seleccione listas de precio"
                                isDisabled={document.getElementById('selectAllPriceListsFilter')?.checked}
                            />
                        </div>
                    </div>

                    {/* Conjunto de 2 radio buttons para Aumentar o Disminuir */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong> Acción: </strong></label>
                        <div>
                            <input
                                type="radio"
                                id="aumentar"
                                name="adjustmentType"
                                value="INCREASE"
                                checked={adjustmentType === "INCREASE"}
                                onChange={() => setAdjustmentType("INCREASE")}
                            />
                            <label htmlFor="aumentar" className="ms-2">Aumentar</label>

                            <input
                                type="radio"
                                id="disminuir"
                                name="adjustmentType"
                                value="DECREASE"
                                className="ms-4"
                                checked={adjustmentType === "DECREASE"}
                                onChange={() => setAdjustmentType("DECREASE")}
                            />
                            <label htmlFor="disminuir" className="ms-2">Disminuir</label>
                        </div>
                    </div>

                    {/* Conjunto de 2 radio buttons para Porcentaje o Valor Fijo */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong> Tipo de
                            ajuste: </strong></label>
                        <div>
                            <input
                                type="radio"
                                id="porcentaje"
                                name="valueType"
                                value="PERCENTAGE"
                                checked={valueType === "PERCENTAGE"}
                                onChange={() => setValueType("PERCENTAGE")}
                            />
                            <label htmlFor="porcentaje" className="ms-2">Porcentaje</label>

                            <input
                                type="radio"
                                id="valor"
                                name="valueType"
                                value="FIXED_VALUE"
                                className="ms-4"
                                checked={valueType === "FIXED_VALUE"}
                                onChange={() => setValueType("FIXED_VALUE")}
                            />
                            <label htmlFor="valor" className="ms-2">Valor Fijo</label>
                        </div>
                    </div>

                    {/* Input de número para ingresar el valor */}
                    <div className="mb-3">
                        <label htmlFor="adjustmentValue" className="form-label"
                               style={{marginBottom: '3%'}}><strong> Valor del ajuste: </strong></label>
                        <input
                            type="number"
                            id="adjustmentValue"
                            className="form-control"
                            value={adjustmentValue}
                            onChange={(e) => setAdjustmentValue(parseFloat(e.target.value))}
                            min="0"
                            step="0.01"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                            onClick={() => {
                                setAdjustmentType('');
                                setValueType('');
                                setAdjustmentValue('');
                                setShowModalEditPrice(false);
                                setSelectedProduct(null);
                                setSelectedImage(null);
                                setPreviewImage(null);
                            }}
                            onHide={() => setShowModalEditPrice(false)}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary"
                            disabled={!adjustmentType || !valueType || !adjustmentValue || loadingEdit}
                            onClick={() => {
                                setLoadingEdit(true);
                                saveEditPriceProduct();
                            }}>Editar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editPriceMasiveFilterProductModal = () => {
        return (
            <Modal size="l" show={showModalEditPriceFilter} onHide={() => setShowModalEditPrice(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5" id="modaleEditPriceProductsLabel"
                        className="titleModalCreateProduct">Editar precio de productos filtrados</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => {
                                setAdjustmentType('');
                                setValueType('');
                                setAdjustmentValue('');
                                setShowModalEditPriceFilter(false);
                                setSelectedProduct(null);
                                setSelectedImage(null);
                                setPreviewImage(null);
                            }}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {/* Selección de listas de precios */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong>Listas de precios:</strong></label>
                        <Select
                            isMulti
                            options={priceListsForFilter.map(list => ({
                                value: list.id,
                                label: list.name
                            }))}
                            onChange={(selectedOptions) => {
                                setPriceListIdFilter(selectedOptions ? selectedOptions.map(option => option.value).join(',') : '');

                                const selectAllCheckbox = document.getElementById('selectAllPriceListsFilter');
                                if (selectAllCheckbox) {
                                    selectAllCheckbox.checked = false;
                                }
                            }}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Seleccione listas de precio"
                            isDisabled={document.getElementById('selectAllPriceListsFilter')?.checked}
                        />
                    </div>
                    {/* Conjunto de 2 radio buttons para Aumentar o Disminuir */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong> Acción: </strong></label>
                        <div>
                            <input
                                type="radio"
                                id="aumentar"
                                name="adjustmentType"
                                value="INCREASE"
                                checked={adjustmentType === "INCREASE"}
                                onChange={() => setAdjustmentType("INCREASE")}
                            />
                            <label htmlFor="aumentar" className="ms-2">Aumentar</label>

                            <input
                                type="radio"
                                id="disminuir"
                                name="adjustmentType"
                                value="DECREASE"
                                className="ms-4"
                                checked={adjustmentType === "DECREASE"}
                                onChange={() => setAdjustmentType("DECREASE")}
                            />
                            <label htmlFor="disminuir" className="ms-2">Disminuir</label>
                        </div>
                    </div>

                    {/* Conjunto de 2 radio buttons para Porcentaje o Valor Fijo */}
                    <div className="mb-3">
                        <label className="form-label" style={{marginBottom: '3%'}}><strong> Tipo de
                            ajuste: </strong></label>
                        <div>
                            <input
                                type="radio"
                                id="porcentaje"
                                name="valueType"
                                value="PERCENTAGE"
                                checked={valueType === "PERCENTAGE"}
                                onChange={() => setValueType("PERCENTAGE")}
                            />
                            <label htmlFor="porcentaje" className="ms-2">Porcentaje</label>

                            <input
                                type="radio"
                                id="valor"
                                name="valueType"
                                value="FIXED_VALUE"
                                className="ms-4"
                                checked={valueType === "FIXED_VALUE"}
                                onChange={() => setValueType("FIXED_VALUE")}
                            />
                            <label htmlFor="valor" className="ms-2">Valor Fijo</label>
                        </div>
                    </div>

                    {/* Input de número para ingresar el valor */}
                    <div className="mb-3">
                        <label htmlFor="adjustmentValue" className="form-label"
                               style={{marginBottom: '3%'}}><strong> Valor del ajuste: </strong></label>
                        <input
                            type="number"
                            id="adjustmentValue"
                            className="form-control"
                            value={adjustmentValue}
                            onChange={(e) => setAdjustmentValue(parseFloat(e.target.value))}
                            min="0"
                            step="0.01"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                            onClick={() => {
                                setAdjustmentType('');
                                setValueType('');
                                setAdjustmentValue('');
                                setShowModalEditPriceFilter(false);
                                setSelectedProduct(null);
                                setSelectedImage(null);
                                setPreviewImage(null);
                            }}
                            onHide={() => setShowModalEditPrice(false)}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary"
                            disabled={!adjustmentType || !valueType || !adjustmentValue || loadingEdit}
                            onClick={() => {
                                setLoadingEdit(true);
                                saveEditPriceFilterProduct();
                            }}>Editar
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const editProductModal = () => {
        return (
            <>
                <Modal size="xl" show={showModalEditProduct} onHide={() => setShowModalEditProduct(false)}>
                    <Modal.Header className="modal-header">
                        <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                            className="titleModalEditProduct">Editar producto</h1>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => {
                                setShowModalEditProduct(false);  // Cerrar el modal
                                resetStyleErrorsEdit();          // Limpiar errores
                                setSelectedProduct(null);        // Limpiar estados
                                setSelectedImage(null);
                                setPreviewImage(null);
                            }}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="nombreEdit" className="col-form-label">Nombre:</label>
                            <input type="text" className="form-control" id="nombreEdit" value={productNameEdit}
                                   onChange={(e) => {
                                       const productNameEditIng = e.target.value;
                                       if (productNameEditIng.length <= 100) {
                                           setProductNameEdit(productNameEditIng);
                                       }
                                       cleanErrorNameEdit();
                                   }}/>
                            <div className="fieldRequiredNameEdit">Campo obligatorio</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="descriptionEdit" className="col-form-label">Descripción:</label>
                            <textarea
                                className="form-control"
                                id="descriptionEdit"
                                value={productDescriptionEdit}
                                onChange={(e) => {
                                    const productDescriptionEditIng = e.target.value;
                                    if (productDescriptionEditIng.length <= 255) {
                                        setProductDescriptionEdit(productDescriptionEditIng);
                                    }
                                    cleanErrorDescEdit();
                                }}
                                rows="3" // Ajusta la altura del textarea
                            />
                            <div className="fieldRequiredDescriptionEdit">Campo obligatorio</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="category" className="col-form-label">Categoría:</label>
                            <select id="selectorEdit" className="form-control" value={selectedCategoryEdit}
                                    onChange={handleSelectCategoryEdit}>
                                {categoriesActive
                                    .map(category => (
                                        <option value={category.id}>{category.name}</option>
                                    ))}
                            </select>
                            <div className="fieldRequiredCategoryEdit">Campo obligatorio</div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="brands" className="col-form-label">Marca:</label>
                            <Select
                                id="brands"
                                options={[{label: "Sin marca", value: null}, ...brandOptions]} // Agregar "Sin marca"
                                value={[{
                                    label: "Sin marca",
                                    value: null
                                }, ...brandOptions].find(option => option.label === selectedBrandEdit)}
                                onChange={(selectedOption) => setSelectedBrandEdit(selectedOption ? selectedOption.label : "")}
                                className="custom-select"
                                placeholder="Selecciona una marca..."
                            />
                            <div style={{color: "gray"}}>Campo opcional</div>
                        </div>
                        <label className="col-form-label fw-bold">Precios</label>
                        <div className="border rounded p-3 mb-3 bg-light">
                            <div className="mb-3">
                                <label htmlFor="priceEdit" className="col-form-label">Precio Lista Default:</label>
                                <div className="price-input">
                                    <p className="dollar-sign"> $ </p>
                                    <input type="number" className="form-control" id="priceEdit"
                                           value={productPriceEdit} onChange={(e) => {
                                        const productPriceEditIng = e.target.value;
                                        const hasDecimal = productPriceEditIng.includes(".");
                                        if (hasDecimal) {
                                            if (productPriceEditIng.length <= 20) {
                                                setProductPriceEdit(productPriceEditIng);
                                            }
                                        } else {
                                            if (productPriceEditIng.length <= 17) {
                                                setProductPriceEdit(productPriceEditIng);
                                            }
                                        }
                                        cleanErrorPriceEdit();
                                    }}/>
                                </div>
                                <div className="fieldRequiredPriceEdit">Campo obligatorio</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="taxCategoryEdit" className="col-form-label">Categoría de
                                    impuesto:</label>
                                <select id="taxCategoryEdit" className="form-control" value={selectedTaxCategoryEdit}
                                        onChange={(e) => setSelectedTaxCategoryEdit(e.target.value)}>
                                    {taxCategories.map(tax => (
                                        <option key={tax.value} value={tax.value}>{tax.label}</option>
                                    ))}
                                </select>
                                <div className="fieldRequiredTaxCategoryEdit" style={{color: "gray"}}>Campo opcional
                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="col-form-label">Listas de Precios:</label>
                            {priceLists.length > 0 ? (
                                <div className="list-group">
                                    {priceLists.map(list => (
                                        <div key={list.id} className="list-group-item border-0 ps-0">
                                            <div className="form-check" style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{marginLeft: '2%'}}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`list-${list.id}`}
                                                        checked={selectedListsEdit.some(l => l.id === list.id)}
                                                        onChange={(e) => {
                                                            const updatedLists = e.target.checked
                                                                ? [...selectedListsEdit, {
                                                                    id: list.id,
                                                                    name: list.name,
                                                                    price: productPriceEdit
                                                                }]
                                                                : selectedListsEdit.filter(l => l.id !== list.id);
                                                            setSelectedListsEdit(updatedLists);
                                                        }}
                                                    />
                                                </div>

                                                <div style={{marginLeft: '1%'}}>
                                                    <label className="form-check-label d-flex align-items-center"
                                                           htmlFor={`list-${list.id}`}>
                                                        <FiTag size={14} className="me-2 text-primary"/>
                                                        <span className="fw-bold text-primary">{list.name}</span>
                                                    </label>
                                                </div>

                                            </div>

                                            {selectedListsEdit.find(l => l.id === list.id) && (
                                                <div className="mt-2 ms-4">
                                                    <div className="input-group" style={{maxWidth: '300px'}}>
                                                    <span className="input-group-text">$</span>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={selectedListsEdit.find(l => l.id === list.id)?.price || ''}
                                                            onChange={(e) => {
                                                                const updatedLists = selectedListsEdit.map(l =>
                                                                    l.id === list.id
                                                                        ? {...l, price: e.target.value}
                                                                        : l
                                                                );
                                                                setSelectedListsEdit(updatedLists);
                                                            }}
                                                        />
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            onClick={() => {
                                                                const updatedLists = selectedListsEdit.map(l =>
                                                                    l.id === list.id
                                                                        ? {...l, price: productPriceEdit}
                                                                        : l
                                                                );
                                                                setSelectedListsEdit(updatedLists);
                                                            }}
                                                            title="Usar precio de lista default"
                                                        >
                                                            <FiCopy size={18}/>
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-muted">No hay otras listas de precios disponibles</div>
                            )}
                        </div>

                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-primary mt-3"
                                onClick={() => {
                                    fetchProductImage(productIdEdit);
                                    setShowModalEditImageProduct(true);
                                    const closeButton = document.getElementById('btnCloseModalEdit');
                                    closeButton.click();
                                }}
                            >
                                Editar Imagen del Producto
                            </button>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button id="btnCloseModalEdit" type="button" className="btn btn-secondary"
                                onClick={() => {
                                    resetStyleErrorsEdit();
                                    setShowModalEditProduct(false)
                                }}>Cerrar
                        </button>
                        <button type="button" className="btn btn-primary"
                                data-test-cy="btnGuardarEditProduct"
                                onClick={() => saveEditproduct()}>Guardar
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de edición de imagen */}
                <Modal size="xl" show={showModalEditImageProduct} onHide={() => setShowModalEditImageProduct(false)}>
                    <Modal.Header className="modal-header modalImageProduct">
                        <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                            className="titleModalEditProduct">Editar imagen del producto</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    setShowModalEditImageProduct(false);
                                }}></button>
                    </Modal.Header>

                    <Modal.Body className="modal-body bodyModalImageProduct">
                        {productImage ? (
                            <>
                                <p>Imagen del producto:</p>
                                <img src={productImage} alt="Imagen del producto" className="img-fluid" width={200}/>
                                <br/>
                                <br/>
                                <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary"
                                        onClick={() => {
                                            deleteImage();
                                        }}
                                        disabled={isUpdateImage}>Eliminar imagen
                                </button>
                                <br/>
                                <br/>
                                <p>Cambiar imagen:</p>
                                <p className='recomendacionImg'>Tamaño recomendado: 225 x 225 px</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e)}
                                    className="form-control mt-3"
                                />
                            </>
                        ) : (
                            <>
                                <p>No hay imagen cargada.</p>
                                <p>Cargar imagen:</p>
                                <p className='recomendacionImg'>Tamaño recomendado: 225 x 225 px</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(e)}
                                    className="form-control mt-3"
                                />
                            </>
                        )}

                    </Modal.Body>

                    <Modal.Footer className="modal-footer modalImageProduct">
                        <button id="btnCloseModalImageProduct" type="button" className="btn btn-secondary"
                                data-bs-dismiss="modal" onClick={() => {
                            setShowModalEditImageProduct(false);
                            setShowModalEditProduct(true);
                        }}>Cerrar
                        </button>
                        {productImage ? (
                            <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary"
                                    onClick={() => {
                                        handleImageUpload();
                                    }}
                                    disabled={isUpdateImage}>Editar imagen
                            </button>
                        ) : (
                            <button id="btnSaveModalImageProduct" type="button" className="btn btn-primary"
                                    onClick={() => {
                                        handleImageUpload();
                                    }}
                                    disabled={isUpdateImage}>Subir imagen
                            </button>
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const addNewExpirationProduct = () => {
        return (
            <Modal
                show={showModalAddExpirationProduct}
                onHide={() => {
                    setShowModalAddExpirationProduct(false);
                    setExpirationDate('');
                    setDaysAlert('');
                    setObservation('');
                    setErrors({});
                }}
                size="lg"
            >
                <Modal.Header closeButton>
                    <h1 className="modal-title fs-5 titleModalEditProduct" id="modaleEditProductLabel">
                        Agregar vencimiento para el producto ({productExpiration?.name || ""})
                    </h1>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="expirationDate" className="form-label col-form-label">
                                Fecha de vencimiento*
                            </label>
                            <input
                                type="date"
                                id="expirationDate"
                                className={`form-control ${errors.expirationDate ? "is-invalid" : ""}`}
                                value={expirationDate}
                                onChange={(e) => setExpirationDate(e.target.value)}
                            />
                            {errors.expirationDate && (
                                <div className="invalid-feedback">{errors.expirationDate}</div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="daysAlert" className="form-label col-form-label">
                                Días de alerta antes del vencimiento*
                            </label>
                            <input
                                type="number"
                                id="daysAlert"
                                className={`form-control ${errors.daysAlert ? "is-invalid" : ""}`}
                                value={daysAlert}
                                onChange={(e) => setDaysAlert(e.target.value)}
                            />
                            {errors.daysAlert && (
                                <div className="invalid-feedback">{errors.daysAlert}</div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="observation" className="form-label col-form-label">
                                Comentario
                            </label>
                            <textarea
                                id="observation"
                                className="form-control"
                                rows="3"
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                            ></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            setShowModalAddExpirationProduct(false);
                            setExpirationDate('');
                            setDaysAlert('');
                            setObservation('');
                            setErrors({});
                        }}
                    >
                        Cancelar
                    </button>
                    <button className="btn btn-primary" onClick={handleSave}
                            data-test-cy="btnSaveAddNewExpirationsProd">
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    const expirationProductModal = () => {
        return (
            <Modal size="xl" show={showModalExpirationProduct} onHide={() => setShowModalExpirationProduct(false)}>
                <Modal.Header className="modal-header">
                    <h1 className="modal-title fs-5" id="modaleEditProductLabel"
                        className="titleModalEditProduct">Vencimientos del producto
                        ({productExpiration?.name || ""})</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => setShowModalExpirationProduct(false)}></button>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Producto</th>
                            <th>Fecha de Vencimiento</th>
                            <th>Días de alerta antes del vencimiento</th>
                            <th>Observación</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {listExpirationsProduct.length > 0 ? (
                            listExpirationsProduct.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.productDTO.name}</td>
                                    <td>{item.expirationDate}</td>
                                    <td>{item.daysAlert}</td>
                                    <td>{item.observation}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger btn-sm" data-test-cy="btnDeleteExpiration"
                                            onClick={() => handleDelete(item.id)}
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center">
                                    No hay vencimientos registrados.
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <button id="btnCloseModalEdit" type="button" className="btn btn-secondary"
                            onClick={() => {
                                setShowModalExpirationProduct(false)
                            }}>Cerrar
                    </button>
                    <button type="button" className="btn btn-primary"
                            data-test-cy="btnAddNewExpirationsProd"
                            onClick={() => {
                                setShowModalExpirationProduct(false);
                                setShowModalAddExpirationProduct(true);
                            }}>Crear nuevo vencimiento
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    function handleDelete(id) {
        Swal.fire({
            title: '¿Estás seguro de eliminar el vencimiento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            confirmButtonColor: 'rgb(246, 139, 127)',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_API_PRODUCTS_EXPIRATIONS_ROUTE + "/" + id, {
                    withCredentials: true
                })
                    .then(response => {
                        Swal.fire({
                            icon: 'success',
                            title: '¡Éxito!',
                            text: 'Eliminado correctamente.',
                            timer: 1500,
                            showConfirmButton: false,
                        });
                        setShowModalExpirationProduct(false);
                        expirationsProduct(productExpiration);
                    })
                    .catch(error => {
                        alertError();
                    });
            }
        });
    }

    useEffect(() => {
        const fetchPriceLists = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_PRICE_LISTS_ROUTE}/v1`,
                    {
                        withCredentials: true,
                        params: {
                            pageNumber: 0,
                            size: 100, // Un número grande para obtener todas las listas
                            filter: ''
                        }
                    }
                );
                // Filtrar para excluir la lista default y obtener solo el contenido de la paginación
                const nonDefaultLists = response.data.content.filter(list => !list.default);
                setPriceLists(nonDefaultLists);
            } catch (error) {
                console.error('Error cargando listas de precios:', error);
                Swal.fire('Error', 'No se pudieron cargar las listas de precios', 'error');
            }
        };

        // Llamar a fetchPriceLists cuando el componente se monta
        fetchPriceLists();
    }, []); // Se ejecuta solo cuando el componente se monta

    return (
        <div className="productsContainer">
            <>
                {pageHeader()}
                {filtersContainer()}

                {expirationsProductFeature && expirationsProductFeature.status &&
                    <div className="linksContainer">
                        <Link to="/expirationsProduct">
                            <Button data-test-cy="btnVerVencimientos">
                                <IoList size={23}/> Ver vencimientos de productos
                            </Button>
                        </Link>
                    </div>
                }

                {(isLoading) ? (
                    loadingTable()
                ) : (
                    <div className="m-3">
                        <Table responsive className="table-striped">
                            <thead className="tableHead">
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th>Categoría</th>
                                <th>Marca</th>
                                <th>Descripción</th>
                                <th>Precio</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                products.map(product => (
                                    <tr key={product.id}>
                                        <td className="text-alig-center fw-bold">{product.id}</td>
                                        <td className="text-alig-center">{product.name}</td>
                                        <td className="text-alig-center">{product.categoryName}</td>
                                        <td className="text-alig-center">{product.brandName}</td>
                                        <td className="text-alig-center">{product.description}</td>
                                        <td className="text-alig-center">${formatPrice(product.price)}</td>
                                        <td className="text-alig-center">{product.status}</td>
                                        <td width="5%">
                                            <div className="colBtnsActionProd">
                                                <button className="btn btnEdit" data-test-cy="btnEditProd"><RequestQuote
                                                    className="btnEdit" size="24"
                                                    onClick={() => {
                                                        editProduct(product);
                                                        setShowModalEditProduct(true);
                                                    }}/></button>
                                                {product.status == "ACTIVO" ? (
                                                    <button className="btn btnActiveInactive"
                                                            onClick={() => disableProd(product)}><View
                                                        className="btnActiveInactive" size="24"/>
                                                    </button>
                                                ) : (
                                                    <button className="btn btnActiveInactive"
                                                            onClick={() => enableProd(product)}><ViewOff
                                                        className="btnActiveInactive" size="24"/>
                                                    </button>
                                                )}
                                                <button className="btn btnDelete" data-test-cy="btnDeleteProd"
                                                        onClick={() => deleteProduct(product)}><TrashCan
                                                    className="btnDelete" size="24"/>
                                                </button>
                                                {expirationsProductFeature && expirationsProductFeature.status &&
                                                    <button className="btn btnExpirations"
                                                            data-test-cy="btnExpirationsProd"
                                                            onClick={() => expirationsProduct(product)}>
                                                        <FaRegCalendarAlt className="btnExpirations" size="22"/>
                                                    </button>
                                                }
                                                {/* Checkbox para seleccionar productos */}
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={selectedProductsToEdit.includes(product.id)}
                                                    onChange={() => handleSelectProduct(product.id)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>

                    </div>
                )}
                {selectedProductsToEdit.length > 0 &&
                    <div className="mt-3" style={{display: 'flex', flexDirection: 'row-reverse', marginRight: '3%'}}>
                        <Button variant="primary" className="me-1" onClick={() => setShowModalEditPrice(true)}>Editar
                            precios de productos</Button>
                    </div>
                }
                {(searching && (productNameFilter != '' || categoryIdFilter != '' || brandIdFilter != '')) &&
                    <div className="mt-3" style={{display: 'flex', flexDirection: 'row-reverse', marginRight: '3%'}}>
                        <Button variant="primary" className="me-1" onClick={() => setShowModalEditPriceFilter(true)}>
                            Editar precios de productos filtrados</Button>
                    </div>
                }
                {editPriceMasiveProductModal()}
                {editPriceMasiveFilterProductModal()}
                {paginationModule()}
                {modalControlButtons()}
                {addProductMassiveModal()}
                {addProductModal()}
                {editProductModal()}
                {expirationProductModal()}
                {addNewExpirationProduct()}

            </>
        </div>
    );
}