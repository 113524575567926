import React from 'react';
import './App.css';
import HomePage from './screens/HomePage';
import NotFound from './screens/NotFound';
import Login from './screens/Login';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import MisProductos from './screens/MisProductos';
import Categories from './screens/Categories';
import SocialMedia from './screens/SocialMedia';
import Carta from './screens/Carta';
import CartaOrderShop from './screens/CartaOrderShop';
import Orders from './screens/Orders';
import Menu from './components/Menu/Menu';
import ShopPage from "./screens/ShopPage";
import ReporteProductos from "./screens/ReporteProductos";
import ReporteCategorias from "./screens/ReporteCategorias";
import ReporteVentas from "./screens/ReporteVentas";
import Stock from "./screens/Stock";
import StockLocations from "./screens/StockLocations";
import StockMovements from "./screens/StockMovements";
import {ConfigProvider} from "./context/ConfigContext";
import StatusOrderView from "./screens/StatusOrderView";
import Configuraciones from "./screens/Configuraciones";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ToastContainer />
      <RoutesWithMenu />
    </Router>
  );
}

function RoutesWithMenu() {

  const location = useLocation();
  const noMenuRoutes = ["/", "/carta", "/estadodelpedido", "/notfound"]; // Poner las rutas que no usan menu completamente en minusculas.
  const showMenu = !noMenuRoutes.includes(location.pathname.toLocaleLowerCase());

  return (
    <ConfigProvider>
      {showMenu && <Menu />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/carta" element={<Carta />} />
        <Route path="/estadoDelPedido" element={<StatusOrderView />} />
        <Route path="/pedidoMostrador" element={<CartaOrderShop />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/misProductos" element={<MisProductos />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/personalizacion" element={<SocialMedia />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/reporteProductos" element={<ReporteProductos />} />
        <Route path="/reporteCategorias" element={<ReporteCategorias />} />
        <Route path="/reporteVentas" element={<ReporteVentas />} />
        <Route path="/config" element={<Configuraciones />} />
      	<Route path="/stock" element={<Stock />} />
        <Route path="/stockLocations" element={<StockLocations />} />
        <Route path="/stockMovements" element={<StockMovements />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
